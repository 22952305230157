import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import PaletteAdminSelect from '@palette/components/utils/PaletteAdminSelect/PaletteAdminSelect';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import styles from './UpdateSupportOwnerModal.less';

const UpdateSupportOwnerModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { companyId, company } = useCompanyInParams();

  const updateSupportOwnerIsPending = useSelector(CompaniesSelectors.updateSupportOwnerIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    supportOwner: company.supportOwner !== null ? company.supportOwner.id : null,
  };

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const handleFinish = useCallback((values) => {
    const { supportOwner } = values;

    dispatch(CompaniesActions.updateSupportOwner({ companyId, supportOwnerId: supportOwner, onSuccessCB: cleanAndClose }));
  }, [companyId, cleanAndClose]);

  const handleUpdateSupportOwner = useCallback(() => form.submit(), [form]);

  return (
    <Modal
      className={styles.modal}
      title={t('updateSupportOwnerModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUpdateSupportOwner}
      okText={t('updateSupportOwnerModal.updateSupportOwner')}
      loading={updateSupportOwnerIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="supportOwner"
          label={t('updateSupportOwnerModal.form.supportOwner.label')}
          required
          rules={[
            { required: true, message: t('updateSupportOwnerModal.form.supportOwner.rules.required') },
          ]}
        >
          <PaletteAdminSelect
            size="big"
            disabled={updateSupportOwnerIsPending}
            allowClear={false}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

UpdateSupportOwnerModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

UpdateSupportOwnerModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default UpdateSupportOwnerModal;
