import { useMemo } from 'react';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getDayjs } from '@palette/helpers/DayjsHelper';
import { getUserStatus } from '@palette/helpers/UserHelper';

import { SEARCHED_USER_QS_KEY, SORT_LAST_NAME_QS_KEY, STATUSES } from '@palette/constants/user';

export const useImpersonateActionIsAvailable = (user) => {
  const dayJS = getDayjs();

  const userStatus = useMemo(() => (
    getUserStatus(user)
  ), [user]);

  return useMemo(() => {
    if (userStatus === STATUSES.NOT_INVITED) return false;

    if (userStatus === STATUSES.OFFBOARDED) {
      const now = dayJS.utc();
      const userLeaveDateMoment = dayJS.utc(user.leaveDate);

      if (userLeaveDateMoment.isSameOrBefore(now)) {
        return false;
      }
    }

    return true;
  }, [userStatus, user]);
};

export const useSearchedUserInSearch = () => useKeyInSearch(SEARCHED_USER_QS_KEY, '');

export const useSortLastNameInSearch = () => (
  useKeyInSearch(SORT_LAST_NAME_QS_KEY, 'asc')
);

export const useCompanyUsersListSortInitialValue = () => {
  const [sortLastName, sortLastNameInSearch] = useSortLastNameInSearch();

  return useMemo(() => {
    if (sortLastNameInSearch) {
      return {
        sortKey: SORT_LAST_NAME_QS_KEY,
        sortKeyValue: sortLastName,
      };
    }

    return {
      sortKey: SORT_LAST_NAME_QS_KEY,
      sortKeyValue: 'asc',
    };
  }, [
    sortLastName,
    sortLastNameInSearch,
  ]);
};
