import { put, all, call, takeLatest } from 'redux-saga/effects';

import { actions as ProfileActions } from '@palette/state/Profile/slice';
import { manageError as manageProfileError } from '@palette/state/Profile/errors';
import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

import { showErrorNotification, showSuccessNotification } from '@palette/helpers/SagasHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { setSessionId } from '@palette/helpers/SessionHelper';

import routePaths from '@palette/config/routePaths';

import * as ProfileModel from '@palette/models/Profile';
import * as ProfileService from '@palette/services/ProfileService';

export function* getProfile({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(ProfileService.getProfile);

  if (callResult.ok) {
    const profile = ProfileModel.transform(callResult.data);
    yield put(ProfileActions.setProfile({ profile }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageProfileError(callResult);
    showErrorNotification(error);
  }

  yield put(ProfileActions.getProfileCompleted());
}

export function* login({ payload = {} }) {
  const { email, password } = payload;

  const callData = { email, password };

  const callResult = yield call(ProfileService.login, callData);

  if (callResult.ok) {
    const { sessionId } = callResult.data;
    setSessionId(sessionId);
    redirectTo({ path: '' });
  } else {
    const error = manageProfileError(callResult);
    showErrorNotification(error);
  }

  yield put(ProfileActions.loginCompleted());
}

export function* forgotPassword({ payload = {} }) {
  const { email } = payload;

  const callData = { email };

  const callResult = yield call(ProfileService.forgotPassword, callData);

  if (callResult.ok) {
    showSuccessNotification({ i18nId: 'notifications.success.forgotPassword' });
    redirectTo({ path: routePaths.login });
  } else {
    const error = manageProfileError(callResult);
    showErrorNotification(error);
  }

  yield put(ProfileActions.forgotPasswordCompleted());
}

export function* resetPassword({ payload = {} }) {
  const { resetPasswordToken, password } = payload;

  const callData = { resetPasswordToken, password };

  const callResult = yield call(ProfileService.resetPassword, callData);

  if (callResult.ok) {
    const { sessionId } = callResult.data;
    setSessionId(sessionId);
    redirectTo({ path: '' });
  } else {
    const error = manageProfileError(callResult);
    showErrorNotification(error);
  }

  yield put(ProfileActions.resetPasswordCompleted());
}

export function* logout() {
  yield call(ProfileService.logout);

  yield put(ExceptionsActions.forceLogout());
}

export function connectAs({ payload = {} }) {
  const { adminSessionId } = payload;

  setSessionId(adminSessionId);
  redirectTo({ path: '' });
}

export function* loop() {
  yield all([
    takeLatest(ProfileActions.getProfile.type, getProfile),
    takeLatest(ProfileActions.login.type, login),
    takeLatest(ProfileActions.forgotPassword.type, forgotPassword),
    takeLatest(ProfileActions.resetPassword.type, resetPassword),
    takeLatest(ProfileActions.logout.type, logout),
    takeLatest(ProfileActions.connectAs.type, connectAs),
  ]);
}

export function* clean() {
  yield put(ProfileActions.resetToInitialState());
}
