import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

import { RIGHTS } from '@palette/constants/role';
import { DEFAULT_SYNC_INTERVAL, SORT_CREATED_DATE_QS_KEY, SORT_NAME_QS_KEY } from '@palette/constants/company';
import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/DayjsHelper';

export function* getCompanies(payload) {
  const {
    searchedCompanyName,
    isLive = null,
    hasStripeSubscription = null,
    hasTrialEndDate = null,
    isArchived = null,
    page,
    limit,
    sort = null,
  } = payload;

  let sortData = {
    name: 1,
  };
  if (sort !== null) {
    if (sort.sortKey === SORT_NAME_QS_KEY) {
      sortData = {
        name: sort.sortKeyValue === 'asc' ? 1 : -1,
      };
    }

    if (sort.sortKey === SORT_CREATED_DATE_QS_KEY) {
      sortData = {
        _id: sort.sortKeyValue === 'asc' ? 1 : -1,
      };
    }
  }

  const data = {
    nameFilter: searchedCompanyName,
    page,
    limit,
    sort: sortData,
    isLive: isLive !== null ? isLive === 'true' : undefined,
    hasStripeSubscription: hasStripeSubscription !== null ? hasStripeSubscription === 'true' : undefined,
    hasTrialEndDate: hasTrialEndDate !== null ? hasTrialEndDate === 'true' : undefined,
    isArchived: isArchived !== null ? isArchived === 'true' : undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.list, data, getConfig()),
    [],
  );
}

export function* createCompany(payload) {
  const {
    logo = null,
    name,
    currency,
    timezone,
    strategyType,
    rollType,
    breakType,
  } = payload;

  const data = {
    logo: logo !== null ? logo : undefined,
    name,
    currency,
    timezone,
    statementStrategy: {
      type: strategyType,
      weekLocale: rollType,
      halfMonthPivot: breakType,
    },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.create, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_CREATE],
  );
}

export function* checkSimilarCompanyName(payload) {
  const {
    name,
  } = payload;

  const data = {
    name,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.checkSimilarCompanyName, data, getConfig()),
    [],
  );
}

export function* getCompanyById(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.getCompanyById, data, getConfig()),
    [],
  );
}

export function* updateCompany(payload) {
  const {
    companyId,
    logo = null,
    name,
    timezone,
  } = payload;

  const data = {
    companyId,
    logo: logo !== null ? logo : undefined,
    name,
    timezone,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.update, data, getConfig()),
    [],
  );
}

export function* updateFeature(payload) {
  const {
    companyId,
    featureKey,
    newFeatureStatus,
  } = payload;

  const data = {
    companyId,
    features: {
      [featureKey]: newFeatureStatus,
    },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.updateFeature, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_FEATURES_UPDATE],
  );
}

export function* updateConnector(payload) {
  const {
    companyId,
    connectorId,
    newAutoSyncStatus,
  } = payload;

  const data = {
    companyId,
    connectorId,
    autoSync: newAutoSyncStatus,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.updateConnector, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_CONNECTORS_UPDATE],
  );
}

export function* deleteConnector(payload) {
  const {
    connectorId,
  } = payload;

  const data = {
    connectorId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.deleteConnector, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_CONNECTORS_UPDATE],
  );
}

export function* updateTrialSettings(payload) {
  const {
    companyId,
    trialEndDate,
    trialPaymentUrl,
  } = payload;

  const data = {
    companyId,
    trialEndDate: trialEndDate !== null ? formatToISOStringWithFractionalSeconds(trialEndDate, true) : null,
    trialPaymentUrl,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.updateTrialSettings, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_TRIAL_UPDATE],
  );
}

export function* updateBillingSettings(payload) {
  const {
    companyId,
    stripeSubscriptionId,
    minSeats,
  } = payload;

  const data = {
    companyId,
    stripeSubscriptionId,
    minSeats,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.updateBillingSettings, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_BILLING_UPDATE],
  );
}

export function* turnCompanyLive(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
    live: true,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.update, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_CREATE],
  );
}

export function* offboardCompany(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.offboardCompany, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_CREATE],
  );
}

export function* addPaletteAdmin(payload) {
  const {
    companyId,
    paletteAdminId,
  } = payload;

  const data = {
    companyId,
    adminId: paletteAdminId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.addPaletteAdmin, data, getConfig()),
    [],
  );
}

export function* removePaletteAdmin(payload) {
  const {
    companyId,
    paletteAdminId,
  } = payload;

  const data = {
    companyId,
    adminId: paletteAdminId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.removePaletteAdmin, data, getConfig()),
    [],
  );
}

export function* updateSupportOwner(payload) {
  const {
    companyId,
    supportOwnerId,
  } = payload;

  const data = {
    companyId,
    supportOwner: supportOwnerId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.update, data, getConfig()),
    [],
  );
}

export function* updateDocumentationUrl(payload) {
  const {
    companyId,
    documentationUrl,
  } = payload;

  const data = {
    companyId,
    documentationUrl,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.update, data, getConfig()),
    [],
  );
}

export function* updateSyncInterval(payload) {
  const {
    companyId,
    syncInterval = DEFAULT_SYNC_INTERVAL,
  } = payload;

  const data = {
    companyId,
    syncInterval,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.update, data, getConfig()),
    [],
  );
}

export function* recomputeTechnicalStats(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companies.recomputeTechnicalStats, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW],
  );
}
