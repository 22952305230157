export const getColumnWidthAsFiniteNumber = (column) => {
  if (Number.isFinite(column.totalWidth)) return column.totalWidth;

  if (Number.isFinite(column.width)) return column.width;

  return column.totalMinWidth;
};

export const getColumnWidth = (column) => {
  if (!Number.isNaN(column.totalWidth)) return column.totalWidth;

  if (!Number.isNaN(column.width)) return column.width;

  return column.totalMinWidth;
};
