import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as RoleModel from '@palette/models/Role';

export const modelName = 'Profile';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  role: RoleModel.propTypes,
  companies: PropTypes.arrayOf(PropTypes.string),
});

const manageCompanies = (companies) => companies.map(
  (company) => {
    const companyObject = manageFreeShapeObjectAttribute({ company }, 'company');

    if (companyObject.mongoId != null) return companyObject.mongoId;

    return null;
  },
).filter(Boolean);

export const transform = (profile) => {
  if (!profile) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(profile, '_id'),
      email: manageStringAttribute(profile, 'email'),
      role: RoleModel.transform(manageFreeShapeObjectAttribute(profile, 'role')),
      companies: manageCompanies(manageArrayAttribute(profile, 'companies')),
    }
  );
};

export const transformList = (profiles) => profiles.map((profile) => transform(profile));
