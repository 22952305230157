import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import CompanyJobsDetails from '@palette/components/companyJobs/CompanyJobsDetails/CompanyJobsDetails';

import * as CompanyModel from '@palette/models/Company';

import { actions as CompanyJobsActions, selectors as CompanyJobsSelectors } from '@palette/state/CompanyJobs';

import styles from './CompanyJobsWrapper.less';

const classNames = bindClassNames.bind(styles);

const CompanyJobsWrapper = ({ className, company }) => {
  const dispatch = useDispatch();

  const getCompanyJobsDetailsIsPending = useSelector(CompanyJobsSelectors.getCompanyJobsDetailsIsPending);
  const jobsDetails = useSelector((state) => CompanyJobsSelectors.getCompanyJobsDetails(state, { companyId: company.id }));

  useEffect(() => {
    dispatch(CompanyJobsActions.getCompanyJobsDetails({ companyId: company.id }));
  }, [company.id]);

  const jobsDetailsNode = useMemo(() => {
    if (jobsDetails === null) return null;

    return (
      <CompanyJobsDetails jobsDetails={jobsDetails} />
    );
  }, [jobsDetails]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={getCompanyJobsDetailsIsPending}>
        <div>
          {jobsDetailsNode}
        </div>
      </Loader>
    </div>
  );
};

CompanyJobsWrapper.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyJobsWrapper.defaultProps = {
  className: '',
};

export default CompanyJobsWrapper;
