import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import Tag from '@palette/components/designSystem/Tag/Tag';

import * as MongoCollectionsStatsModel from '@palette/models/MongoCollectionsStats';

import styles from './CompanyCollectionsStatsTotals.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsStatsTotals = ({ className, mongoCollectionsStats }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tag className={styles.total} type="info">
        {t('companyCollectionsStatsTotals.companyCollections', { storage: prettyBytes(mongoCollectionsStats.totalCompanyCollections) })}
      </Tag>
      <Tag className={styles.total} type="warning">
        {t('companyCollectionsStatsTotals.paletteCollections', { storage: prettyBytes(mongoCollectionsStats.totalPaletteCollections) })}
      </Tag>
      <Tag className={styles.total} type="success">
        {t('companyCollectionsStatsTotals.totalStorage', { storage: prettyBytes(mongoCollectionsStats.totalStorage) })}
      </Tag>
    </div>
  );
};

CompanyCollectionsStatsTotals.propTypes = {
  className: PropTypes.string,
  mongoCollectionsStats: MongoCollectionsStatsModel.propTypes.isRequired,
};

CompanyCollectionsStatsTotals.defaultProps = {
  className: '',
};

export default CompanyCollectionsStatsTotals;
