import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'User';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const getDisplayName = (user) => {
  const displayName = `${user.firstName} ${user.lastName}`.trim();

  return displayName.length === 0 ? user.email : displayName;
};

export const transform = (user) => {
  if (!user) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(user, '_id'),
      email: manageStringAttribute(user, 'email'),
      firstName: manageStringAttribute(user, 'firstName'),
      lastName: manageStringAttribute(user, 'lastName'),
    }
  );
};

export const transformList = (users) => users.map((user) => transform(user));
