import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddPaletteAdminModal from '@palette/components/companyDetails/AddPaletteAdminModal/AddPaletteAdminModal';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import styles from './AddPaletteAdmin.less';

const classNames = bindClassNames.bind(styles);

const AddPaletteAdmin = ({ className }) => {
  const { t } = useTranslation();

  const { canAccessToCompanyDetails } = useCompanyInParams();

  const [addPaletteAdminModalIsVisible, showAddPaletteAdminModal] = useState(false);

  if (!canAccessToCompanyDetails) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        flattenLink
        onClick={() => showAddPaletteAdminModal(true)}
      >
        {t('addPaletteAdmin.label')}
      </Button>
      {
        addPaletteAdminModalIsVisible && (
          <AddPaletteAdminModal visible onClose={() => showAddPaletteAdminModal(false)} />
        )
      }
    </div>
  );
};

AddPaletteAdmin.propTypes = {
  className: PropTypes.string,
};

AddPaletteAdmin.defaultProps = {
  className: '',
};

export default AddPaletteAdmin;
