import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './PageTitle.less';

const classNames = bindClassNames.bind(styles);

const PageTitle = ({ title, subtitle, children, className }) => {
  const titleNode = (
    <h1 className={styles.title}>
      {title}
    </h1>
  );
  let subtitleNode = null;
  if (subtitle !== null) {
    subtitleNode = (
      <div className={styles.subtitle}>
        {subtitle}
      </div>
    );
  }

  let contentNode = (
    <div className={styles.contentWrapper}>
      {titleNode}
      {subtitleNode}
    </div>
  );
  if (children !== null) {
    contentNode = children;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
};

PageTitle.defaultProps = {
  className: '',
  title: null,
  subtitle: null,
  children: null,
};

export default PageTitle;
