import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { extractDateFromMongoId } from '@palette/helpers/CommonHelper';
import { manageJobsStats } from '@palette/helpers/AsyncJobHelper';

import { DEFAULT_SYNC_INTERVAL, FEATURES } from '@palette/constants/company';

import * as CurrencyModel from '@palette/models/Currency';
import * as StatementStrategyModel from '@palette/models/StatementStrategy';
import * as StatementStrategyHistoryModel from '@palette/models/StatementStrategyHistory';
import * as ConnectorModel from '@palette/models/Connector';
import * as PaletteAdminModel from '@palette/models/PaletteAdmin';
import * as RoleModel from '@palette/models/Role';
import * as JobStatsModel from '@palette/models/JobStats';
import * as MongoCollectionsStatsModel from '@palette/models/MongoCollectionsStats';

export const modelName = 'Company';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  createdAt: PropTypes.string,
  live: PropTypes.bool,
  archived: PropTypes.bool,
  logo: PropTypes.string,
  currency: PropTypes.string,
  currencies: PropTypes.arrayOf(CurrencyModel.propTypes),
  trialEndDate: PropTypes.string,
  trialPaymentUrl: PropTypes.string,
  features: PropTypes.shape(Object.values(FEATURES).reduce((res, feature) => ({ ...res, [feature]: PropTypes.bool }), {})),
  timezone: PropTypes.string,
  statementStrategy: StatementStrategyModel.propTypes,
  statementStrategies: PropTypes.arrayOf(StatementStrategyHistoryModel.propTypes),
  minSeats: PropTypes.number,
  stripeSubscriptionId: PropTypes.string,
  usersCount: PropTypes.number,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes),
  connectorsNames: PropTypes.arrayOf(PropTypes.string),
  connectorsCount: PropTypes.number,
  seatsCount: PropTypes.number,
  paletteAdmins: PropTypes.arrayOf(PaletteAdminModel.propTypes),
  documentationUrl: PropTypes.string,
  syncInterval: PropTypes.number,
  supportOwner: PaletteAdminModel.propTypes,
  roles: PropTypes.arrayOf(RoleModel.propTypes),
  jobsStats: PropTypes.arrayOf(JobStatsModel.propTypes),
  mongoCollectionsStats: MongoCollectionsStatsModel.propTypes,
});

export const manageCompanyFeatures = (companyFeatures = {}) => Object.values(FEATURES).reduce((res, feature) => ({ ...res, [feature]: companyFeatures[feature] || false }), {});

export const manageStatementStrategy = (company) => {
  const activeStatementStrategy = manageFreeShapeObjectAttribute(company, 'activeStatementStrategy');
  return StatementStrategyModel.transform(manageFreeShapeObjectAttribute(activeStatementStrategy, 'strategy'));
};

export const manageStatementStrategies = (company) => StatementStrategyHistoryModel.transformList(manageArrayAttribute(company, 'statementPeriodStrategies'));

export const transform = (company) => {
  if (!company) {
    return null;
  }

  const id = manageStringAttribute(company, '_id');

  const createdAt = extractDateFromMongoId(id).toISOString();

  const currencies = CurrencyModel.transformList(manageArrayAttribute(company, 'currencies'));
  let currency = null;
  if (currencies.length > 0) {
    const defaultCurrency = currencies.find((curr) => (curr.isDefault));

    if (defaultCurrency) {
      currency = defaultCurrency.code;
    }
  }

  let connectors = [];
  let connectorsNames = [];
  const connectorsObj = manageFreeShapeObjectAttribute(company, 'connectors');
  let connectorsCount = manageNumberAttribute(connectorsObj, 'total', null);
  if (connectorsCount === null) {
    connectors = ConnectorModel.transformList(manageArrayAttribute(company, 'connectors'));
    connectorsCount = connectors.length;
    connectorsNames = connectors.map((connector) => (connector.displayName));
  } else {
    connectorsNames = manageArrayAttribute(connectorsObj, 'connectors');
  }

  const supportOwnerObj = manageFreeShapeObjectAttribute(company, 'supportOwner', null);
  const supportOwner = supportOwnerObj !== null ? PaletteAdminModel.transform(supportOwnerObj) : null;

  const rawJobs = manageFreeShapeObjectAttribute(company, 'jobs');

  return (
    {
      id,
      name: manageStringAttribute(company, 'name'),
      createdAt: manageDateAttribute({ createdAt }, 'createdAt'),
      live: manageBooleanAttribute(company, 'live'),
      archived: manageBooleanAttribute(company, 'archived'),
      logo: manageStringAttribute(company, 'logo'),
      currency,
      currencies: CurrencyModel.transformList(manageArrayAttribute(company, 'currencies')),
      trialEndDate: manageDateAttribute(company, 'trialEndDate', null),
      trialPaymentUrl: manageStringAttribute(company, 'trialPaymentUrl', null),
      features: manageCompanyFeatures(company.features),
      timezone: manageStringAttribute(company, 'timezone', null),
      statementStrategy: manageStatementStrategy(company),
      statementStrategies: manageStatementStrategies(company),
      minSeats: manageNumberAttribute(company, 'minSeats', null),
      stripeSubscriptionId: manageStringAttribute(company, 'stripeSubscriptionId', null),
      usersCount: manageNumberAttribute(company, 'users'),
      connectors,
      connectorsNames,
      connectorsCount,
      seatsCount: manageNumberAttribute(company, 'seats'),
      plansCount: manageNumberAttribute(company, 'plans'),
      quotasCount: manageNumberAttribute(company, 'quotas'),
      challengesCount: manageNumberAttribute(company, 'challenges'),
      reconciliationsCount: manageNumberAttribute(company, 'reconciliations'),
      paletteAdmins: PaletteAdminModel.transformList(manageArrayAttribute(company, 'paletteAdmins')),
      documentationUrl: manageStringAttribute(company, 'documentationUrl', null),
      syncInterval: manageNumberAttribute(company, 'syncInterval', DEFAULT_SYNC_INTERVAL),
      supportOwner,
      roles: RoleModel.transformList(manageArrayAttribute(company, 'roles')),
      jobsStats: manageJobsStats(rawJobs),
      mongoCollectionsStats: MongoCollectionsStatsModel.transform(manageFreeShapeObjectAttribute(company, 'collections')),
    }
  );
};

export const transformList = (companies) => companies.map((company) => transform(company));

export const merge = (company1, company2) => {
  if (!company2) return company1;
  if (!company1) return company2;

  return company2;
};
