import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Image from '@palette/components/designSystem/Image/Image';
import ForgotPasswordForm from '@palette/components/forgotPassword/ForgotPasswordForm/ForgotPasswordForm';

import styles from './ForgotPasswordPageContent.less';

const classNames = bindClassNames.bind(styles);

const ForgotPasswordPageContent = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Image src="/img/Logo_Palette.png" height={60} />
      <h1>
        {t('forgotPasswordPageContent.title')}
      </h1>
      <h2>
        {t('forgotPasswordPageContent.subtitle')}
      </h2>
      <ForgotPasswordForm className={styles.forgotPasswordForm} />
    </div>
  );
};

ForgotPasswordPageContent.propTypes = {
  className: PropTypes.string,
};

ForgotPasswordPageContent.defaultProps = {
  className: '',
};

export default ForgotPasswordPageContent;
