export const ASYNC_JOB_TYPES = {
  COMPUTE: 'COMPUTE',
  RECONCILE: 'RECONCILE',
  SYNC_ALL_CONNECTORS: 'SYNC_ALL_CONNECTORS',
  SYNC_CONNECTOR: 'SYNC_CONNECTOR',
  ESTIMATE_COMMISSIONS: 'ESTIMATE_COMMISSIONS',
};

export const ASYNC_JOB_TYPE_TAG_TYPES = {
  [ASYNC_JOB_TYPES.COMPUTE]: 'warning',
  [ASYNC_JOB_TYPES.SYNC_ALL_CONNECTORS]: 'skyblue',
  [ASYNC_JOB_TYPES.SYNC_CONNECTOR]: 'info',
  [ASYNC_JOB_TYPES.ESTIMATE_COMMISSIONS]: 'success',
  [ASYNC_JOB_TYPES.RECONCILE]: 'danger',
};

export const ASYNC_JOB_STATUSES = {
  DONE: 'DONE',
  ERRORED: 'ERRORED',
  IN_PROGRESS: 'IN_PROGRESS',
  QUEUED: 'QUEUED',
};

export const ASYNC_JOB_STATUS_TAG_TYPES = {
  [ASYNC_JOB_STATUSES.QUEUED]: 'info',
  [ASYNC_JOB_STATUSES.IN_PROGRESS]: 'warning',
  [ASYNC_JOB_STATUSES.DONE]: 'success',
  [ASYNC_JOB_STATUSES.ERRORED]: 'danger',
};
