import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CompanyJobsWrapper from '@palette/components/companyJobs/CompanyJobsWrapper/CompanyJobsWrapper';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsJobs.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsJobs = ({ className, company }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('companyDetailsJobs.title')}
      </div>
      <CompanyJobsWrapper className={styles.companyJobs} company={company} />
    </div>
  );
};

CompanyDetailsJobs.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsJobs.defaultProps = {
  className: '',
};

export default CompanyDetailsJobs;
