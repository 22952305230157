import { RIGHTS } from '@palette/constants/role';

export const hasRight = (profile, right) => (
  profile?.role.rights[RIGHTS.ADMIN_ALL]
  || profile?.role.rights[right]
);

export const hasAtLeastOneRight = (profile, rights) => (
  rights.some((right) => hasRight(profile, right))
);

export const hasAllRights = (profile, rights) => (
  rights.every((right) => hasRight(profile, right))
);
