import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';

import Tag from '@palette/components/designSystem/Tag/Tag';

import { formatDuration } from '@palette/helpers/DayjsHelper';

import { ASYNC_JOB_TYPE_TAG_TYPES, ASYNC_JOB_TYPES } from '@palette/constants/asyncJobs';

import * as JobStatsModel from '@palette/models/JobStats';

import styles from './CompanyJobStatsList.less';

const classNames = bindClassNames.bind(styles);

const CompanyJobStatsList = ({ className, statsList }) => {
  const { t } = useTranslation();

  const jobStatsNodes = useMemo(() => {
    const statsByJobType = _keyBy(statsList, 'type');
    const finalNodes = [];
    Object.values(ASYNC_JOB_TYPES).forEach((asyncJobType) => {
      const jobStats = statsByJobType[asyncJobType];
      if (jobStats != null) {
        finalNodes.push((
          <Tag
            key={jobStats.type}
            className={styles.jobStats}
            type={ASYNC_JOB_TYPE_TAG_TYPES[jobStats.type]}
          >
            <div className={styles.jobStatsDescription}>
              <div className={styles.jobType}>
                {`${jobStats.type} (${jobStats.count})`}
              </div>
              <div>
                {t('jobStats.avgDuration', { avgDuration: formatDuration(jobStats.avgDuration) })}
              </div>
              <div>
                {t('jobStats.totalDuration', { totalDuration: formatDuration(jobStats.totalDuration) })}
              </div>
            </div>
          </Tag>
        ));
      }
    });

    return finalNodes;
  }, [statsList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {jobStatsNodes}
    </div>
  );
};

CompanyJobStatsList.propTypes = {
  className: PropTypes.string,
  statsList: PropTypes.arrayOf(JobStatsModel.propTypes).isRequired,
};

CompanyJobStatsList.defaultProps = {
  className: '',
};

export default CompanyJobStatsList;
