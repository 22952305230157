import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

export function* getPaletteAdmins() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.paletteAdmins.list, {}, getConfig()),
    [],
  );
}
