// Full list here: https://www.iban.com/currency-codes
export const SUPPORTED_CURRENCIES = [
  {
    country: 'AFGHANISTAN',
    name: 'Afghani',
    code: 'AFN',
    number: '971',
  },
  {
    country: 'ALBANIA',
    name: 'Lek',
    code: 'ALL',
    number: '008',
  },
  {
    country: 'ALGERIA',
    name: 'Algerian Dinar',
    code: 'DZD',
    number: '012',
  },
  {
    country: 'AMERICAN SAMOA',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'ANDORRA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'ANGOLA',
    name: 'Kwanza',
    code: 'AOA',
    number: '973',
  },
  {
    country: 'ANGUILLA',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'ANTARCTICA',
    name: 'No universal currency',
    code: '',
    number: '',
  },
  {
    country: 'ANTIGUA AND BARBUDA',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'ARGENTINA',
    name: 'Argentine Peso',
    code: 'ARS',
    number: '032',
  },
  {
    country: 'ARMENIA',
    name: 'Armenian Dram',
    code: 'AMD',
    number: '051',
  },
  {
    country: 'ARUBA',
    name: 'Aruban Florin',
    code: 'AWG',
    number: '533',
  },
  {
    country: 'AUSTRALIA',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'AUSTRIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'AZERBAIJAN',
    name: 'Azerbaijanian Manat',
    code: 'AZN',
    number: '944',
  },
  {
    country: 'BAHAMAS (THE)',
    name: 'Bahamian Dollar',
    code: 'BSD',
    number: '044',
  },
  {
    country: 'BAHRAIN',
    name: 'Bahraini Dinar',
    code: 'BHD',
    number: '048',
  },
  {
    country: 'BANGLADESH',
    name: 'Taka',
    code: 'BDT',
    number: '050',
  },
  {
    country: 'BARBADOS',
    name: 'Barbados Dollar',
    code: 'BBD',
    number: '052',
  },
  {
    country: 'BELARUS',
    name: 'Belarussian Ruble',
    code: 'BYN',
    number: '933',
  },
  {
    country: 'BELGIUM',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'BELIZE',
    name: 'Belize Dollar',
    code: 'BZD',
    number: '084',
  },
  {
    country: 'BENIN',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'BERMUDA',
    name: 'Bermudian Dollar',
    code: 'BMD',
    number: '060',
  },
  {
    country: 'BHUTAN',
    name: 'Ngultrum',
    code: 'BTN',
    number: '064',
  },
  {
    country: 'BHUTAN',
    name: 'Indian Rupee',
    code: 'INR',
    number: '356',
  },
  {
    country: 'BOLIVIA (PLURINATIONAL STATE OF)',
    name: 'Boliviano',
    code: 'BOB',
    number: '068',
  },
  {
    country: 'BOLIVIA (PLURINATIONAL STATE OF)',
    name: 'Mvdol',
    code: 'BOV',
    number: '984',
  },
  {
    country: 'BONAIRE, SINT EUSTATIUS AND SABA',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'BOSNIA AND HERZEGOVINA',
    name: 'Convertible Mark',
    code: 'BAM',
    number: '977',
  },
  {
    country: 'BOTSWANA',
    name: 'Pula',
    code: 'BWP',
    number: '072',
  },
  {
    country: 'BOUVET ISLAND',
    name: 'Norwegian Krone',
    code: 'NOK',
    number: '578',
  },
  {
    country: 'BRAZIL',
    name: 'Brazilian Real',
    code: 'BRL',
    number: '986',
  },
  {
    country: 'BRITISH INDIAN OCEAN TERRITORY (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'BRUNEI DARUSSALAM',
    name: 'Brunei Dollar',
    code: 'BND',
    number: '096',
  },
  {
    country: 'BULGARIA',
    name: 'Bulgarian Lev',
    code: 'BGN',
    number: '975',
  },
  {
    country: 'BURKINA FASO',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'BURUNDI',
    name: 'Burundi Franc',
    code: 'BIF',
    number: '108',
  },
  {
    country: 'CABO VERDE',
    name: 'Cabo Verde Escudo',
    code: 'CVE',
    number: '132',
  },
  {
    country: 'CAMBODIA',
    name: 'Riel',
    code: 'KHR',
    number: '116',
  },
  {
    country: 'CAMEROON',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'CANADA',
    name: 'Canadian Dollar',
    code: 'CAD',
    number: '124',
  },
  {
    country: 'CAYMAN ISLANDS (THE)',
    name: 'Cayman Islands Dollar',
    code: 'KYD',
    number: '136',
  },
  {
    country: 'CENTRAL AFRICAN REPUBLIC (THE)',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'CHAD',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'CHILE',
    name: 'Unidad de Fomento',
    code: 'CLF',
    number: '990',
  },
  {
    country: 'CHILE',
    name: 'Chilean Peso',
    code: 'CLP',
    number: '152',
  },
  {
    country: 'CHINA',
    name: 'Yuan Renminbi',
    code: 'CNY',
    number: '156',
  },
  {
    country: 'CHRISTMAS ISLAND',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'COCOS (KEELING) ISLANDS (THE)',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'COLOMBIA',
    name: 'Colombian Peso',
    code: 'COP',
    number: '170',
  },
  {
    country: 'COLOMBIA',
    name: 'Unidad de Valor Real',
    code: 'COU',
    number: '970',
  },
  {
    country: 'COMOROS (THE)',
    name: 'Comoro Franc',
    code: 'KMF',
    number: '174',
  },
  {
    country: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)',
    name: 'Congolese Franc',
    code: 'CDF',
    number: '976',
  },
  {
    country: 'CONGO (THE)',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'COOK ISLANDS (THE)',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    country: 'COSTA RICA',
    name: 'Costa Rican Colon',
    code: 'CRC',
    number: '188',
  },
  {
    country: 'CROATIA',
    name: 'Kuna',
    code: 'HRK',
    number: '191',
  },
  {
    country: 'CUBA',
    name: 'Peso Convertible',
    code: 'CUC',
    number: '931',
  },
  {
    country: 'CUBA',
    name: 'Cuban Peso',
    code: 'CUP',
    number: '192',
  },
  {
    country: 'CURAÇAO',
    name: 'Netherlands Antillean Guilder',
    code: 'ANG',
    number: '532',
  },
  {
    country: 'CYPRUS',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'CZECH REPUBLIC (THE)',
    name: 'Czech Koruna',
    code: 'CZK',
    number: '203',
  },
  {
    country: "CÔTE D'IVOIRE",
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'DENMARK',
    name: 'Danish Krone',
    code: 'DKK',
    number: '208',
  },
  {
    country: 'DJIBOUTI',
    name: 'Djibouti Franc',
    code: 'DJF',
    number: '262',
  },
  {
    country: 'DOMINICA',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'DOMINICAN REPUBLIC (THE)',
    name: 'Dominican Peso',
    code: 'DOP',
    number: '214',
  },
  {
    country: 'ECUADOR',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'EGYPT',
    name: 'Egyptian Pound',
    code: 'EGP',
    number: '818',
  },
  {
    country: 'EL SALVADOR',
    name: 'El Salvador Colon',
    code: 'SVC',
    number: '222',
  },
  {
    country: 'EL SALVADOR',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'EQUATORIAL GUINEA',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'ERITREA',
    name: 'Nakfa',
    code: 'ERN',
    number: '232',
  },
  {
    country: 'ESTONIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'ETHIOPIA',
    name: 'Ethiopian Birr',
    code: 'ETB',
    number: '230',
  },
  {
    country: 'EUROPEAN UNION',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'FALKLAND ISLANDS (THE) [MALVINAS]',
    name: 'Falkland Islands Pound',
    code: 'FKP',
    number: '238',
  },
  {
    country: 'FAROE ISLANDS (THE)',
    name: 'Danish Krone',
    code: 'DKK',
    number: '208',
  },
  {
    country: 'FIJI',
    name: 'Fiji Dollar',
    code: 'FJD',
    number: '242',
  },
  {
    country: 'FINLAND',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'FRANCE',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'FRENCH GUIANA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'FRENCH POLYNESIA',
    name: 'CFP Franc',
    code: 'XPF',
    number: '953',
  },
  {
    country: 'FRENCH SOUTHERN TERRITORIES (THE)',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'GABON',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    country: 'GAMBIA (THE)',
    name: 'Dalasi',
    code: 'GMD',
    number: '270',
  },
  {
    country: 'GEORGIA',
    name: 'Lari',
    code: 'GEL',
    number: '981',
  },
  {
    country: 'GERMANY',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'GHANA',
    name: 'Ghana Cedi',
    code: 'GHS',
    number: '936',
  },
  {
    country: 'GIBRALTAR',
    name: 'Gibraltar Pound',
    code: 'GIP',
    number: '292',
  },
  {
    country: 'GREECE',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'GREENLAND',
    name: 'Danish Krone',
    code: 'DKK',
    number: '208',
  },
  {
    country: 'GRENADA',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'GUADELOUPE',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'GUAM',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'GUATEMALA',
    name: 'Quetzal',
    code: 'GTQ',
    number: '320',
  },
  {
    country: 'GUERNSEY',
    name: 'Pound Sterling',
    code: 'GBP',
    number: '826',
  },
  {
    country: 'GUINEA',
    name: 'Guinea Franc',
    code: 'GNF',
    number: '324',
  },
  {
    country: 'GUINEA-BISSAU',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'GUYANA',
    name: 'Guyana Dollar',
    code: 'GYD',
    number: '328',
  },
  {
    country: 'HAITI',
    name: 'Gourde',
    code: 'HTG',
    number: '332',
  },
  {
    country: 'HAITI',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'HEARD ISLAND AND McDONALD ISLANDS',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'HOLY SEE (THE)',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'HONDURAS',
    name: 'Lempira',
    code: 'HNL',
    number: '340',
  },
  {
    country: 'HONG KONG',
    name: 'Hong Kong Dollar',
    code: 'HKD',
    number: '344',
  },
  {
    country: 'HUNGARY',
    name: 'Forint',
    code: 'HUF',
    number: '348',
  },
  {
    country: 'ICELAND',
    name: 'Iceland Krona',
    code: 'ISK',
    number: '352',
  },
  {
    country: 'INDIA',
    name: 'Indian Rupee',
    code: 'INR',
    number: '356',
  },
  {
    country: 'INDONESIA',
    name: 'Rupiah',
    code: 'IDR',
    number: '360',
  },
  {
    country: 'INTERNATIONAL MONETARY FUND (IMF)&nbsp;',
    name: 'SDR (Special Drawing Right)',
    code: 'XDR',
    number: '960',
  },
  {
    country: 'IRAN (ISLAMIC REPUBLIC OF)',
    name: 'Iranian Rial',
    code: 'IRR',
    number: '364',
  },
  {
    country: 'IRAQ',
    name: 'Iraqi Dinar',
    code: 'IQD',
    number: '368',
  },
  {
    country: 'IRELAND',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'ISLE OF MAN',
    name: 'Pound Sterling',
    code: 'GBP',
    number: '826',
  },
  {
    country: 'ISRAEL',
    name: 'New Israeli Sheqel',
    code: 'ILS',
    number: '376',
  },
  {
    country: 'ITALY',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'JAMAICA',
    name: 'Jamaican Dollar',
    code: 'JMD',
    number: '388',
  },
  {
    country: 'JAPAN',
    name: 'Yen',
    code: 'JPY',
    number: '392',
  },
  {
    country: 'JERSEY',
    name: 'Pound Sterling',
    code: 'GBP',
    number: '826',
  },
  {
    country: 'JORDAN',
    name: 'Jordanian Dinar',
    code: 'JOD',
    number: '400',
  },
  {
    country: 'KAZAKHSTAN',
    name: 'Tenge',
    code: 'KZT',
    number: '398',
  },
  {
    country: 'KENYA',
    name: 'Kenyan Shilling',
    code: 'KES',
    number: '404',
  },
  {
    country: 'KIRIBATI',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)',
    name: 'North Korean Won',
    code: 'KPW',
    number: '408',
  },
  {
    country: 'KOREA (THE REPUBLIC OF)',
    name: 'Won',
    code: 'KRW',
    number: '410',
  },
  {
    country: 'KUWAIT',
    name: 'Kuwaiti Dinar',
    code: 'KWD',
    number: '414',
  },
  {
    country: 'KYRGYZSTAN',
    name: 'Som',
    code: 'KGS',
    number: '417',
  },
  {
    country: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)',
    name: 'Kip',
    code: 'LAK',
    number: '418',
  },
  {
    country: 'LATVIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'LEBANON',
    name: 'Lebanese Pound',
    code: 'LBP',
    number: '422',
  },
  {
    country: 'LESOTHO',
    name: 'Loti',
    code: 'LSL',
    number: '426',
  },
  {
    country: 'LESOTHO',
    name: 'Rand',
    code: 'ZAR',
    number: '710',
  },
  {
    country: 'LIBERIA',
    name: 'Liberian Dollar',
    code: 'LRD',
    number: '430',
  },
  {
    country: 'LIBYA',
    name: 'Libyan Dinar',
    code: 'LYD',
    number: '434',
  },
  {
    country: 'LIECHTENSTEIN',
    name: 'Swiss Franc',
    code: 'CHF',
    number: '756',
  },
  {
    country: 'LITHUANIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'LUXEMBOURG',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MACAO',
    name: 'Pataca',
    code: 'MOP',
    number: '446',
  },
  {
    country: 'MADAGASCAR',
    name: 'Malagasy Ariary',
    code: 'MGA',
    number: '969',
  },
  {
    country: 'MALAWI',
    name: 'Kwacha',
    code: 'MWK',
    number: '454',
  },
  {
    country: 'MALAYSIA',
    name: 'Malaysian Ringgit',
    code: 'MYR',
    number: '458',
  },
  {
    country: 'MALDIVES',
    name: 'Rufiyaa',
    code: 'MVR',
    number: '462',
  },
  {
    country: 'MALI',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'MALTA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MARSHALL ISLANDS (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'MARTINIQUE',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MAURITANIA',
    name: 'Ouguiya',
    code: 'MRU',
    number: '929',
  },
  {
    country: 'MAURITIUS',
    name: 'Mauritius Rupee',
    code: 'MUR',
    number: '480',
  },
  {
    country: 'MAYOTTE',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP',
    name: 'ADB Unit of Account',
    code: 'XUA',
    number: '965',
  },
  {
    country: 'MEXICO',
    name: 'Mexican Peso',
    code: 'MXN',
    number: '484',
  },
  {
    country: 'MEXICO',
    name: 'Mexican Unidad de Inversion (UDI)',
    code: 'MXV',
    number: '979',
  },
  {
    country: 'MICRONESIA (FEDERATED STATES OF)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'MOLDOVA (THE REPUBLIC OF)',
    name: 'Moldovan Leu',
    code: 'MDL',
    number: '498',
  },
  {
    country: 'MONACO',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MONGOLIA',
    name: 'Tugrik',
    code: 'MNT',
    number: '496',
  },
  {
    country: 'MONTENEGRO',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'MONTSERRAT',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'MOROCCO',
    name: 'Moroccan Dirham',
    code: 'MAD',
    number: '504',
  },
  {
    country: 'MOZAMBIQUE',
    name: 'Mozambique Metical',
    code: 'MZN',
    number: '943',
  },
  {
    country: 'MYANMAR',
    name: 'Kyat',
    code: 'MMK',
    number: '104',
  },
  {
    country: 'NAMIBIA',
    name: 'Namibia Dollar',
    code: 'NAD',
    number: '516',
  },
  {
    country: 'NAMIBIA',
    name: 'Rand',
    code: 'ZAR',
    number: '710',
  },
  {
    country: 'NAURU',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'NEPAL',
    name: 'Nepalese Rupee',
    code: 'NPR',
    number: '524',
  },
  {
    country: 'NETHERLANDS (THE)',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'NEW CALEDONIA',
    name: 'CFP Franc',
    code: 'XPF',
    number: '953',
  },
  {
    country: 'NEW ZEALAND',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    country: 'NICARAGUA',
    name: 'Cordoba Oro',
    code: 'NIO',
    number: '558',
  },
  {
    country: 'NIGER (THE)',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'NIGERIA',
    name: 'Naira',
    code: 'NGN',
    number: '566',
  },
  {
    country: 'NIUE',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    country: 'NORFOLK ISLAND',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'NORTHERN MARIANA ISLANDS (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'NORWAY',
    name: 'Norwegian Krone',
    code: 'NOK',
    number: '578',
  },
  {
    country: 'OMAN',
    name: 'Rial Omani',
    code: 'OMR',
    number: '512',
  },
  {
    country: 'PAKISTAN',
    name: 'Pakistan Rupee',
    code: 'PKR',
    number: '586',
  },
  {
    country: 'PALAU',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'PALESTINE, STATE OF',
    name: 'No universal currency',
    code: '',
    number: '',
  },
  {
    country: 'PANAMA',
    name: 'Balboa',
    code: 'PAB',
    number: '590',
  },
  {
    country: 'PANAMA',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'PAPUA NEW GUINEA',
    name: 'Kina',
    code: 'PGK',
    number: '598',
  },
  {
    country: 'PARAGUAY',
    name: 'Guarani',
    code: 'PYG',
    number: '600',
  },
  {
    country: 'PERU',
    name: 'Nuevo Sol',
    code: 'PEN',
    number: '604',
  },
  {
    country: 'PHILIPPINES (THE)',
    name: 'Philippine Peso',
    code: 'PHP',
    number: '608',
  },
  {
    country: 'PITCAIRN',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    country: 'POLAND',
    name: 'Zloty',
    code: 'PLN',
    number: '985',
  },
  {
    country: 'PORTUGAL',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'PUERTO RICO',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'QATAR',
    name: 'Qatari Rial',
    code: 'QAR',
    number: '634',
  },
  {
    country: 'REPUBLIC OF NORTH MACEDONIA',
    name: 'Denar',
    code: 'MKD',
    number: '807',
  },
  {
    country: 'ROMANIA',
    name: 'Romanian Leu',
    code: 'RON',
    number: '946',
  },
  {
    country: 'RUSSIAN FEDERATION (THE)',
    name: 'Russian Ruble',
    code: 'RUB',
    number: '643',
  },
  {
    country: 'RWANDA',
    name: 'Rwanda Franc',
    code: 'RWF',
    number: '646',
  },
  {
    country: 'RÉUNION',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SAINT BARTHÉLEMY',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    name: 'Saint Helena Pound',
    code: 'SHP',
    number: '654',
  },
  {
    country: 'SAINT KITTS AND NEVIS',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'SAINT LUCIA',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'SAINT MARTIN (FRENCH PART)',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SAINT PIERRE AND MIQUELON',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SAINT VINCENT AND THE GRENADINES',
    name: 'East Caribbean Dollar',
    code: 'XCD',
    number: '951',
  },
  {
    country: 'SAMOA',
    name: 'Tala',
    code: 'WST',
    number: '882',
  },
  {
    country: 'SAN MARINO',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SAO TOME AND PRINCIPE',
    name: 'Dobra',
    code: 'STN',
    number: '930',
  },
  {
    country: 'SAUDI ARABIA',
    name: 'Saudi Riyal',
    code: 'SAR',
    number: '682',
  },
  {
    country: 'SENEGAL',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'SERBIA',
    name: 'Serbian Dinar',
    code: 'RSD',
    number: '941',
  },
  {
    country: 'SEYCHELLES',
    name: 'Seychelles Rupee',
    code: 'SCR',
    number: '690',
  },
  {
    country: 'SIERRA LEONE',
    name: 'Leone',
    code: 'SLL',
    number: '694',
  },
  {
    country: 'SINGAPORE',
    name: 'Singapore Dollar',
    code: 'SGD',
    number: '702',
  },
  {
    country: 'SINT MAARTEN (DUTCH PART)',
    name: 'Netherlands Antillean Guilder',
    code: 'ANG',
    number: '532',
  },
  {
    country: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    name: 'Sucre',
    code: 'XSU',
    number: '994',
  },
  {
    country: 'SLOVAKIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SLOVENIA',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SOLOMON ISLANDS',
    name: 'Solomon Islands Dollar',
    code: 'SBD',
    number: '090',
  },
  {
    country: 'SOMALIA',
    name: 'Somali Shilling',
    code: 'SOS',
    number: '706',
  },
  {
    country: 'SOUTH AFRICA',
    name: 'Rand',
    code: 'ZAR',
    number: '710',
  },
  {
    country: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    name: 'No universal currency',
    code: '',
    number: '',
  },
  {
    country: 'SOUTH SUDAN',
    name: 'South Sudanese Pound',
    code: 'SSP',
    number: '728',
  },
  {
    country: 'SPAIN',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    country: 'SRI LANKA',
    name: 'Sri Lanka Rupee',
    code: 'LKR',
    number: '144',
  },
  {
    country: 'SUDAN (THE)',
    name: 'Sudanese Pound',
    code: 'SDG',
    number: '938',
  },
  {
    country: 'SURINAME',
    name: 'Surinam Dollar',
    code: 'SRD',
    number: '968',
  },
  {
    country: 'SVALBARD AND JAN MAYEN',
    name: 'Norwegian Krone',
    code: 'NOK',
    number: '578',
  },
  {
    country: 'SWAZILAND',
    name: 'Lilangeni',
    code: 'SZL',
    number: '748',
  },
  {
    country: 'SWEDEN',
    name: 'Swedish Krona',
    code: 'SEK',
    number: '752',
  },
  {
    country: 'SWITZERLAND',
    name: 'WIR Euro',
    code: 'CHE',
    number: '947',
  },
  {
    country: 'SWITZERLAND',
    name: 'Swiss Franc',
    code: 'CHF',
    number: '756',
  },
  {
    country: 'SWITZERLAND',
    name: 'WIR Franc',
    code: 'CHW',
    number: '948',
  },
  {
    country: 'SYRIAN ARAB REPUBLIC',
    name: 'Syrian Pound',
    code: 'SYP',
    number: '760',
  },
  {
    country: 'TAIWAN (PROVINCE OF CHINA)',
    name: 'New Taiwan Dollar',
    code: 'TWD',
    number: '901',
  },
  {
    country: 'TAJIKISTAN',
    name: 'Somoni',
    code: 'TJS',
    number: '972',
  },
  {
    country: 'TANZANIA, UNITED REPUBLIC OF',
    name: 'Tanzanian Shilling',
    code: 'TZS',
    number: '834',
  },
  {
    country: 'THAILAND',
    name: 'Baht',
    code: 'THB',
    number: '764',
  },
  {
    country: 'TIMOR-LESTE',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'TOGO',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    country: 'TOKELAU',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    country: 'TONGA',
    name: 'Pa’anga',
    code: 'TOP',
    number: '776',
  },
  {
    country: 'TRINIDAD AND TOBAGO',
    name: 'Trinidad and Tobago Dollar',
    code: 'TTD',
    number: '780',
  },
  {
    country: 'TUNISIA',
    name: 'Tunisian Dinar',
    code: 'TND',
    number: '788',
  },
  {
    country: 'TURKEY',
    name: 'Turkish Lira',
    code: 'TRY',
    number: '949',
  },
  {
    country: 'TURKMENISTAN',
    name: 'Turkmenistan New Manat',
    code: 'TMT',
    number: '934',
  },
  {
    country: 'TURKS AND CAICOS ISLANDS (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'TUVALU',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    country: 'UGANDA',
    name: 'Uganda Shilling',
    code: 'UGX',
    number: '800',
  },
  {
    country: 'UKRAINE',
    name: 'Hryvnia',
    code: 'UAH',
    number: '980',
  },
  {
    country: 'UNITED ARAB EMIRATES (THE)',
    name: 'UAE Dirham',
    code: 'AED',
    number: '784',
  },
  {
    country: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)',
    name: 'Pound Sterling',
    code: 'GBP',
    number: '826',
  },
  {
    country: 'UNITED STATES MINOR OUTLYING ISLANDS (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'UNITED STATES OF AMERICA (THE)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'UNITED STATES OF AMERICA (THE)',
    name: 'US Dollar (Next day)',
    code: 'USN',
    number: '997',
  },
  {
    country: 'URUGUAY',
    name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    code: 'UYI',
    number: '940',
  },
  {
    country: 'URUGUAY',
    name: 'Peso Uruguayo',
    code: 'UYU',
    number: '858',
  },
  {
    country: 'UZBEKISTAN',
    name: 'Uzbekistan Sum',
    code: 'UZS',
    number: '860',
  },
  {
    country: 'VANUATU',
    name: 'Vatu',
    code: 'VUV',
    number: '548',
  },
  {
    country: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
    name: 'Bolivar',
    code: 'VEF',
    number: '937',
  },
  {
    country: 'VIET NAM',
    name: 'Dong',
    code: 'VND',
    number: '704',
  },
  {
    country: 'VIRGIN ISLANDS (BRITISH)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'VIRGIN ISLANDS (U.S.)',
    name: 'US Dollar',
    code: 'USD',
    number: '840',
  },
  {
    country: 'WALLIS AND FUTUNA',
    name: 'CFP Franc',
    code: 'XPF',
    number: '953',
  },
  {
    country: 'WESTERN SAHARA',
    name: 'Moroccan Dirham',
    code: 'MAD',
    number: '504',
  },
  {
    country: 'YEMEN',
    name: 'Yemeni Rial',
    code: 'YER',
    number: '886',
  },
  {
    country: 'ZAMBIA',
    name: 'Zambian Kwacha',
    code: 'ZMW',
    number: '967',
  },
  {
    country: 'ZIMBABWE',
    name: 'Zimbabwe Dollar',
    code: 'ZWL',
    number: '932',
  },
  {
    country: 'ÅLAND ISLANDS',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
];

export const SUPPORTED_CURRENCIES_BY_CODE = SUPPORTED_CURRENCIES.reduce((res, currency) => {
  if (currency.code === '') return res;

  if (res[currency.code] == null) {
    res[currency.code] = [];
  }

  res[currency.code].push(currency);

  return res;
}, {});

export const SUPPORTED_CURRENCY_CODES = Object.keys(SUPPORTED_CURRENCIES_BY_CODE).sort();
