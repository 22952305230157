const en = {
  translation: {
    'addNewUser.addNew': 'Add New User',

    'addNewUserModal.addNewUser': 'Add',
    'addNewUserModal.form.currency.label': 'Currency',
    'addNewUserModal.form.email.label': 'Email',
    'addNewUserModal.form.email.rules.email': 'Please provide a valid email address.',
    'addNewUserModal.form.email.rules.required': 'Please fill an email.',
    'addNewUserModal.form.firstName.label': 'First Name',
    'addNewUserModal.form.firstName.rules.required': 'Please fill a first name.',
    'addNewUserModal.form.lastName.label': 'Last Name',
    'addNewUserModal.form.lastName.rules.required': 'Please fill a last name.',
    'addNewUserModal.form.role.label': 'Role',
    'addNewUserModal.form.role.required': 'Please select a role for this new user',
    'addNewUserModal.title': 'Add new user',

    'addPaletteAdmin.label': 'Add Palette Admin',

    'addPaletteAdminModal.addPaletteAdmin': 'Add',
    'addPaletteAdminModal.form.paletteAdmin.label': 'Select a Palette Admin to add',
    'addPaletteAdminModal.form.paletteAdmin.rules.required': 'Please select a Palette Admin',
    'addPaletteAdminModal.title': 'Add Palette Admin',

    'apiLogsList.columns.byPaletteAdmin.header': 'By a Palette Admin?',
    'apiLogsList.columns.company.header': 'Company',
    'apiLogsList.columns.date.header': 'Date (UTC+0)',
    'apiLogsList.columns.status.header': 'Status',
    'apiLogsList.columns.time.header': 'Time (ms)',
    'apiLogsList.columns.url.header': 'URL',
    'apiLogsList.columns.user.header': 'User',
    'apiLogsList.empty.description': 'No logs corresponding to your search',

    'apiLogsPageContent.title': 'API Logs',

    'app.metas.defaultTitle': 'Admin | Palette',

    'asyncJobs.errors.processing': 'An error occured during execution. {{message}}',

    'common.global.cancel': 'Cancel',
    'common.global.edit': 'Edit',
    'common.global.no': 'No',
    'common.global.remove': 'Remove',
    'common.global.upload': 'Upload',
    'common.global.yes': 'Yes',
    'common.global.minutes': 'Minutes',
    'common.global.hours': 'Hours',
    'common.global.minutes.short': 'Min',
    'common.global.hours.short': 'H',

    'companiesList.empty.description': 'No companies corresponding to your search',

    'companiesListItem.canAccessToCompanyDetails': 'You have access to this company',
    'companiesListItem.canNotAccessToCompanyDetails': 'You don\'t have access to this company.',
    'companiesListItem.challengesCount_zero': 'No challenges',
    'companiesListItem.challengesCount_one': '{{count}} challenge',
    'companiesListItem.challengesCount_other': '{{count}} challenges',
    'companiesListItem.connectors.types': 'Connectors\' types',
    'companiesListItem.contactSupportOwner': 'Contact company\'s support owner: {{supportOwner}}',
    'companiesListItem.currencies': 'Currencies',
    'companiesListItem.currencies_one': '{{default}} ({{count}} currency)',
    'companiesListItem.currencies_other': '{{default}} ({{count}} currencies)',
    'companiesListItem.currency.isDefault': 'Default',
    'companiesListItem.hasStripeSubscription': 'Has a Stripe subscription defined',
    'companiesListItem.isArchived': 'This company is archived',
    'companiesListItem.isLive': 'This company is live',
    'companiesListItem.isNotArchived': 'This company is not archived',
    'companiesListItem.isNotLive': 'This company is not live',
    'companiesListItem.jobs.statsPerJobType': 'Stats per job type',
    'companiesListItem.minSeats_zero': 'No min seats',
    'companiesListItem.minSeats_one': 'Min Seats: {{count}}',
    'companiesListItem.minSeats_other': 'Min Seats: {{count}}',
    'companiesListItem.mongoCollectionsStats.statsDetails': 'Mongo Storage Details',
    'companiesListItem.plansCount_zero': 'No plans',
    'companiesListItem.plansCount_one': '{{count}} plan',
    'companiesListItem.plansCount_other': '{{count}} plans',
    'companiesListItem.quotasCount_zero': 'No quotas',
    'companiesListItem.quotasCount_one': '{{count}} quota',
    'companiesListItem.quotasCount_other': '{{count}} quotas',
    'companiesListItem.reconciliationsCount_zero': 'No reconciliations',
    'companiesListItem.reconciliationsCount_one': '{{count}} reconciliation',
    'companiesListItem.reconciliationsCount_other': '{{count}} reconciliations',
    'companiesListItem.seatsCount_zero': 'No seats',
    'companiesListItem.seatsCount_one': '{{count}} seat',
    'companiesListItem.seatsCount_other': '{{count}} seats',
    'companiesListItem.statementStrategy': 'Statement Strategy: {{strategy}}',
    'companiesListItem.timezone.default_value': 'Timezone {{timezone}}',
    'companiesListItem.timezone.no_default_value': 'No default timezone set',
    'companiesListItem.timezone.timeOffsetLabel': 'UTC{{timeOffsetLabel}}',
    'companiesListItem.trialEndDate': 'Trial End Date: {{date}}',
    'companiesListItem.usersCount_zero': 'No users',
    'companiesListItem.usersCount_one': '{{count}} user',
    'companiesListItem.usersCount_other': '{{count}} users',

    'companiesListWrapper.filters.companySearch.placeholder': 'Search for company name',
    'companiesListWrapper.filters.reset.label': 'Reset filters',
    'companiesListWrapper.filters.tags.closeLabel': 'Close to update',
    'companiesListWrapper.filters.tags.label': 'Filters',
    'companiesListWrapper.filters.tags.labels.hasNotStripeSubscription': 'Has Not Stripe Subscription',
    'companiesListWrapper.filters.tags.labels.hasNotTrialEndDate': 'Has Not a Trial End Date',
    'companiesListWrapper.filters.tags.labels.hasStripeSubscription': 'Has Stripe Subscription',
    'companiesListWrapper.filters.tags.labels.hasTrialEndDate': 'Has a Trial End Date',
    'companiesListWrapper.filters.tags.labels.isArchived': 'Archived',
    'companiesListWrapper.filters.tags.labels.isLive': 'Live',
    'companiesListWrapper.filters.tags.labels.isNotArchived': 'Not Archived',
    'companiesListWrapper.filters.tags.labels.isNotLive': 'Not Live',
    'companiesListWrapper.sort.options.labels.nameAsc': 'A-Z',
    'companiesListWrapper.sort.options.labels.nameDesc': 'Z-A',
    'companiesListWrapper.sort.options.labels.createdDateDesc': 'Newest first',
    'companiesListWrapper.sort.options.labels.createdDateAsc': 'Oldest first',

    'companiesPageContent.title': 'Companies',

    'companyCollectionsDetails.statsTotalsTitle': 'Storage Totals',

    'companyCollectionsDetailsTabs.tabs.company': 'Connectors\' Resources',
    'companyCollectionsDetailsTabs.tabs.palette': 'Generated By Palette',

    'companyCollectionsList.empty.description': 'No collections.',

    'companyCollectionsListItem.avgObjSize': 'Avg Document size: {{size}}',
    'companyCollectionsListItem.memorySize': 'Uncompressed size in memory: {{size}}',
    'companyCollectionsListItem.nbDocuments_zero': 'No documents',
    'companyCollectionsListItem.nbDocuments_one': '{{count}} document',
    'companyCollectionsListItem.nbDocuments_other': '{{displayedCount}} documents',
    'companyCollectionsListItem.storageSize': 'Storage size: {{size}}',

    'companyCollectionsStatsTotals.companyCollections': 'Connectors\' Resources: {{storage}}',
    'companyCollectionsStatsTotals.paletteCollections': 'Generated By Palette: {{storage}}',
    'companyCollectionsStatsTotals.totalStorage': 'Total Storage: {{storage}}',

    'companyDetailsCollections.title': 'Collections',

    'companyDetailsJobs.title': 'Jobs',

    'companyDetailsPageContent.accessNotAuthorized': 'You don\'t have access to this company. Contact company\'s support owner.',

    'companyDetailsSettingsBilling.editBillingSettings': 'Edit subscription settings',
    'companyDetailsSettingsBilling.form.minSeats.label': 'Min Seats',
    'companyDetailsSettingsBilling.form.minSeats.placeholder': 'Min Seats',
    'companyDetailsSettingsBilling.form.stripeSubscriptionId.label': 'Stripe Subscription Id',
    'companyDetailsSettingsBilling.form.stripeSubscriptionId.placeholder': 'Stripe Subscription Id',
    'companyDetailsSettingsBilling.noSubscriptionIdDefined': 'No Stripe Subscription Id defined',
    'companyDetailsSettingsBilling.title': 'Subscription',

    'companyDetailsSettingsConnectors.empty.description': 'No connectors',
    'companyDetailsSettingsConnectors.title': 'Connectors',
    'companyDetailsSettingsConnectors.deleteConnector.popconfirmTitle': 'Do you confirm that you want to delete this connector and all of its resources?',

    'companyDetailsSettingsFeatures.title': 'Features',

    'companyDetailsSettingsFreeTrial.deleteTrialSettings.buttonLabel': 'Unset free trial',
    'companyDetailsSettingsFreeTrial.deleteTrialSettings.popconfirmTitle': 'Do you confirm you want to unset free trial for this company?',
    'companyDetailsSettingsFreeTrial.editTrialSettings': 'Edit free trial settings',
    'companyDetailsSettingsFreeTrial.form.trialEndDate.label': 'Trial end date',
    'companyDetailsSettingsFreeTrial.form.trialPaymentUrl.label': 'Trial payment Url',
    'companyDetailsSettingsFreeTrial.form.trialPaymentUrl.placeholder': 'Trial payment Url',
    'companyDetailsSettingsFreeTrial.form.trialPaymentUrl.rules.urlType': 'Please provide a valid Url',
    'companyDetailsSettingsFreeTrial.noTrialPaymentUrlSet': 'No trial payment Url set',
    'companyDetailsSettingsFreeTrial.noTrialSet': 'No free trial set',
    'companyDetailsSettingsFreeTrial.title': 'Free Trial',
    'companyDetailsSettingsFreeTrial.trialPaymentUrlLabel': 'Trial payment Url',

    'companyDetailsSettingsGlobal.title': 'Company Management',

    'companyDetailsSettingsGlobalDocumentationUrl.noDocumentationUrlSet': 'No documentation url set',
    'companyDetailsSettingsGlobalDocumentationUrl.companyDocumentation': 'Company documentation',
    'companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.label': 'Documentation url',
    'companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.rules.urlType': 'Please provide a valid Url',
    'companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.placeholder': 'Documentation url',

    'companyDetailsSettingsGlobalSyncInterval.companySyncInterval': 'Sync. interval: {{interval}}',
    'companyDetailsSettingsGlobalSyncInterval.form.syncInterval.label': 'Synchronisation interval',

    'companyDetailsSettingsGlobalLiveStatus.isLive': 'This company is Live!',
    'companyDetailsSettingsGlobalLiveStatus.isNotLive': 'This company is not live.',
    'companyDetailsSettingsGlobalLiveStatus.offboardIt.label': 'Offboard it',
    'companyDetailsSettingsGlobalLiveStatus.offboardIt.popconfirmTitle': 'Do you confirm you want to offboard this company?',
    'companyDetailsSettingsGlobalLiveStatus.turnItLive.label': 'Turn it live',
    'companyDetailsSettingsGlobalLiveStatus.turnItLive.popconfirmTitle': 'Do you confirm you want to turn this company live?',

    'companyDetailsSettingsGlobalPaletteAdminItem.removePaletteAdmin.label': 'Remove',
    'companyDetailsSettingsGlobalPaletteAdminItem.removePaletteAdmin.popconfirmTitle': 'Do you confirm you want to remove this Palette Admin from this company?',

    'companyDetailsSettingsGlobalPaletteAdmins.paletteAdmins_zero': 'No Palette Admins',
    'companyDetailsSettingsGlobalPaletteAdmins.paletteAdmins_one': '{{count}} Palette Admin',
    'companyDetailsSettingsGlobalPaletteAdmins.paletteAdmins_other': '{{count}} Palette Admins',

    'companyDetailsSettingsGlobalSupportOwner.undefined': 'No support owner defined',
    'companyDetailsSettingsGlobalSupportOwner.supportOwner': 'Support Owner',

    'companyDetailsTabs.tabs.collections': 'Collections',
    'companyDetailsTabs.tabs.jobs': 'Jobs',
    'companyDetailsTabs.tabs.users': 'Users',

    'companyDetailsUsers.title': 'Users',

    'companyFeatures.challenge': 'Challenge',
    'companyFeatures.compensation': 'Compensation',
    'companyFeatures.statementValidation': 'Statement Validation',
    'companyFeatures.statementValidationNotifications': 'Statement Validation Notifications',
    'companyFeatures.digestNotifications': 'Digest Notifications',
    'companyFeatures.notebooks': 'Notebooks',
    'companyFeatures.planV2': 'Plans V2 (Master Plans)',
    'companyFeatures.planV3': 'Plans V3',

    'companyJobsDetails.statsTitle': '1 month rolling: stats per job type',

    'companyUsersList.admins': 'Admins',
    'companyUsersList.others': 'Other users',

    'companyUsersListRoleFilter.allRoles': 'All Roles',

    'companyUsersListStatusFilter.allStatuses': 'All Statuses',

    'companyUsersListWrapper.filters.userSearch.placeholder': 'Search for user name',
    'companyUsersListWrapper.sort.options.labels.lastNameAsc': 'A-Z (Last Name)',
    'companyUsersListWrapper.sort.options.labels.lastNameDesc': 'Z-A (Last Name)',

    'createCompany.createNewOne': 'Create a New Company',

    'createCompanyModal.createCompany': 'Create company',
    'createCompanyModal.form.breakType.label': 'Break semi-monthly period on',
    'createCompanyModal.form.breakType.placeholder': 'Break semi-monthly period on',
    'createCompanyModal.form.breakType.rules.required': 'Please select a semi-monthly pivot.',
    'createCompanyModal.form.currency.label': 'Default currency',
    'createCompanyModal.form.logo.placeholder': 'Upload logo',
    'createCompanyModal.form.name.label': 'Name',
    'createCompanyModal.form.name.placeholder': 'Name',
    'createCompanyModal.form.name.rules.required': 'Please name your Company.',
    'createCompanyModal.form.rollType.label': 'Start week on',
    'createCompanyModal.form.rollType.placeholder': 'Start week on',
    'createCompanyModal.form.rollType.rules.required': 'Please select a roll type.',
    'createCompanyModal.form.strategyType.label': 'Type of statement cycle',
    'createCompanyModal.form.strategyType.placeholder': 'Select type of statement cycle',
    'createCompanyModal.form.strategyType.rules.required': 'Please select type of statement cycle.',
    'createCompanyModal.form.timezone.label': 'Timezone',
    'createCompanyModal.similarCompanies.label': 'Existing companies with close names: {{similarCompaniesNames}}',
    'createCompanyModal.title': 'Create a New Company',

    'editCompany.edit': 'Edit Company',

    'editCompanyModal.title': 'Edit {{companyName}}',
    'editCompanyModal.editCompany': 'Edit company',

    'emailPasswordForm.form.email.label': 'Email',
    'emailPasswordForm.form.email.rules.required': 'Please enter your email.',
    'emailPasswordForm.form.email.placeholder': 'Your email',
    'emailPasswordForm.form.password.label': 'Password',
    'emailPasswordForm.form.password.rules.required': 'Please enter your password.',
    'emailPasswordForm.form.password.placeholder': 'Your password',
    'emailPasswordForm.form.submit': 'Login',

    'errorPage.title': 'Oops! Sorry, an unexpected error has occurred.',
    'errorPage.notFoundIllu.alt': 'not found error illustration',

    'forgotPasswordForm.form.email.label': 'Email',
    'forgotPasswordForm.form.email.rules.required': 'Please enter your email.',
    'forgotPasswordForm.form.email.placeholder': 'Your email',
    'forgotPasswordForm.form.submit': 'I forgot my password',

    'forgotPasswordPageContent.subtitle': 'Forgot Password?',
    'forgotPasswordPageContent.title': 'Admin',

    'global.error.unknown': 'An error occurred. {{message}}',
    'global.error.bad.request': 'The server cannot process the request. {{message}}',
    'global.error.constraint.violation': 'One or multiple constraint violations occurred. {{message}}',
    'global.error.unauthorized': 'You are not authorized to perform this action. {{message}}',
    'global.error.invalid.session': 'Your session is invalid. {{message}}',
    'global.error.not.found': 'The resource was not found. {{message}}',
    'global.error.method.not.allowed': 'This request method is not allowed. {{message}}',
    'global.error.server.error': 'A server error occurred. {{message}}',
    'global.error.maintenance.enabled': 'A maintenance is ongoing. We will be back soon! {{message}}',

    'googleAuthButton.error': 'An error occurred during authentication with Google',
    'googleAuthButton.signIn': 'Sign in with Google',

    'jobsList.empty.description': 'No jobs.',

    'jobsListItem.autoSync': 'Sync Status',
    'jobsListItem.copyJobIdToClipboard': 'Copy Job\'s Id',
    'jobsListItem.duration': 'Duration',
    'jobsListItem.lastActivityAt': 'Last Activity',
    'jobsListItem.launchDate': 'Launch Date',
    'jobsListItem.modalTitles.error': 'Job\'s Error',
    'jobsListItem.modalTitles.params': 'Job\'s Params',
    'jobsListItem.modalTitles.result': 'Job\'s Result',
    'jobsListItem.showError': 'Show Job\'s Error',
    'jobsListItem.showParams': 'Show Job\'s Params',
    'jobsListItem.showResult': 'Show Job\'s Result',
    'jobsListItem.tooOldToAutoSync': 'Too Old',
    'jobsListItem.userJob.autoSync': 'Auto run',
    'jobsListItem.userJob.manualSync': 'Manual run',
    'jobsListItem.writeLock.isSaving': 'Saving in progress (write locked)',
    'jobsListItem.writeLock.notLocked': 'Write not locked',

    'jobListItemJsonModal.title': 'JSON explorer',

    'jobStats.avgDuration': 'Avg ~ {{avgDuration}}',
    'jobStats.totalDuration': 'Total ~ {{totalDuration}}',

    'loader.loading': 'Loading...',
    'loginPageContent.forgotPassword': 'Forgot password?',
    'loginPageContent.intermediaryTitle': 'Or sign in with email',
    'loginPageContent.subtitle': 'Login',
    'loginPageContent.title': 'Admin',

    'maintenance.description.subtitle': 'We\'ll be back in a few hours',
    'maintenance.description.title': 'Palette is under maintenance',

    'menu.companies': 'Companies',
    'menu.apiLogs': 'Api Logs',

    'modal.cancel': 'Cancel',

    'notifications.success.addNewUser': 'Successfully added user to company',
    'notifications.success.addPaletteAdmin': 'Successfully added Palette Admin',
    'notifications.success.autoSyncSuccess': 'Job "{{jobId}}" finished successfully',
    'notifications.success.copyEmailToClipboard': '"{{email}}" successfully copied',
    'notifications.success.copyInvitationLinkToClipboard': 'Invitation link for {{displayName}} is successfully copied',
    'notifications.success.copyJobIdToClipboard': 'Job\'s Id "{{jobId}}" successfully copied',
    'notifications.success.createCompany': 'Successfully created company',
    'notifications.success.forgotPassword': 'You will receive an email soon with instructions to reset your password.',
    'notifications.success.recomputeTechnicalStats': 'Successfully recomputed technical stats for this company',
    'notifications.success.removePaletteAdmin': 'Successfully removed Palette Admin',
    'notifications.success.updateBillingSettings': 'Successfully updated company free trial settings',
    'notifications.success.updateCompany': 'Successfully updated company',
    'notifications.success.updateConnector': 'Successfully updated connector',
    'notifications.success.deleteConnector': 'Successfully deleted connector and its resources',
    'notifications.success.updateDocumentationUrl': 'Successfully updated company documentation url',
    'notifications.success.updateSyncInterval': 'Successfully updated company synchronisation interval',
    'notifications.success.updateFeature': 'Successfully updated company feature setting',
    'notifications.success.updateSupportOwner': 'Successfully updated company support owner',
    'notifications.success.updateTrialSettings': 'Successfully updated company free trial settings',

    'notifications.error.copyEmailToClipboard': 'An error occurred during copy of email.',
    'notifications.error.copyInvitationLinkToClipboard': 'An error occurred during copy of invitation link.',
    'notifications.error.copyJobIdToClipboard': 'An error occurred during copy of job\'s Id.',

    'optimizationsPageContent.title': 'Date Optimizations',

    'paletteAdminSelect.empty.description': 'No Palette Admin available',
    'paletteAdminSelect.emptyFiltered.description': 'There is no more Palette Admin available',

    'pagination.results_zero': '{{count}} result',
    'pagination.results_one': '{{count}} result',
    'pagination.results_other': '{{count}} results',
    'pagination.goToPage': 'Go to page',
    'pagination.perPage': '{{limit}} per page',

    'passwordRules.rule.hasLowercaseLetters_zero': 'At least {{count}} lowercases',
    'passwordRules.rule.hasLowercaseLetters_one': 'At least {{count}} lowercase',
    'passwordRules.rule.hasLowercaseLetters_other': 'At least {{count}} lowercases',
    'passwordRules.rule.hasMinLength_zero': 'Minimum {{count}} characters',
    'passwordRules.rule.hasMinLength_one': 'Minimum {{count}} character',
    'passwordRules.rule.hasMinLength_other': 'Minimum {{count}} characters',
    'passwordRules.rule.hasNumbers_zero': 'At least {{count}} numbers',
    'passwordRules.rule.hasNumbers_one': 'At least {{count}} number',
    'passwordRules.rule.hasNumbers_other': 'At least {{count}} numbers',
    'passwordRules.rule.hasUppercaseLetters_zero': 'At least {{count}} uppercases',
    'passwordRules.rule.hasUppercaseLetters_one': 'At least {{count}} uppercase',
    'passwordRules.rule.hasUppercaseLetters_other': 'At least {{count}} uppercases',
    'passwordRules.rule.hasSymbols_zero': 'At least {{count}} symbols',
    'passwordRules.rule.hasSymbols_one': 'At least {{count}} symbol',
    'passwordRules.rule.hasSymbols_other': 'At least {{count}} symbols',
    'passwordRules.title': 'Password rules:',

    'pictureUploadInput.error.format': 'You can only upload JPG/PNG file.',
    'pictureUploadInput.error.size': 'Image must be smaller than 0.5MB.',
    'pictureUploadInput.error.uploading': 'An error occured while uploading, please try again.',

    'recomputeTechnicalStats.label': 'Recompute Technical Stats',

    'resetPasswordForm.form.password.label': 'Password',
    'resetPasswordForm.form.password.placeholder': 'Your password',
    'resetPasswordForm.form.password.rules.isNotValid': 'Please enter a valid password.',
    'resetPasswordForm.form.password.rules.required': 'Please enter your password.',
    'resetPasswordForm.form.passwordConfirm.label': 'Password confirmation',
    'resetPasswordForm.form.passwordConfirm.placeholder': 'Write again your password',
    'resetPasswordForm.form.passwordConfirm.rules.isDifferent': 'You need enter the same password as above.',
    'resetPasswordForm.form.passwordConfirm.rules.required': 'Please confirm your password.',
    'resetPasswordForm.form.submit': 'Update my password',

    'resetPasswordPageContent.default.subtitle': 'Reset your password',
    'resetPasswordPageContent.default.title': 'Admin',
    'resetPasswordPageContent.isNewUser.subtitle': 'Set your password',
    'resetPasswordPageContent.isNewUser.title': 'Welcome at Palette!',

    'restrictedPageWrapper.header.logout': 'Logout',
    'restrictedPageWrapper.header.title': 'Admin',

    'select.addToOptions': 'Add to options',
    'select.searchNotFound': 'No option matches',

    'statementStrategy.monthBreaks': 'Breaks on the {{break}} of the month',
    'statementStrategy.monthBreaks.types': 'The {{break}} of the month',
    'statementStrategy.monthBreaks.FIFTEENTH': '15th',
    'statementStrategy.monthBreaks.SIXTEENTH': '16th',
    'statementStrategy.propertyTypes.BI_WEEK': 'Biweekly',
    'statementStrategy.propertyTypes.HALF_MONTH': 'Semi-Monthly',
    'statementStrategy.propertyTypes.MONTH': 'Monthly',
    'statementStrategy.propertyTypes.WEEK': 'Weekly',
    'statementStrategy.weekBegins': 'Week begins on {{day}}',
    'statementStrategy.weekBegins.EU': 'Monday',
    'statementStrategy.weekBegins.US': 'Sunday',

    'timezoneSelect.options.timeOffsetLabel': 'UTC{{timeOffsetLabel}}',
    'timezoneSelect.timezones': 'Timezones',
    'timezoneSelect.timeOffsets': 'Time offsets',

    'updateSupportOwnerModal.title': 'Update Support Owner',
    'updateSupportOwnerModal.updateSupportOwner': 'Update',
    'updateSupportOwnerModal.form.supportOwner.label': 'Palette Admin',
    'updateSupportOwnerModal.form.supportOwner.rules.required': 'Please select a Palette Admin as Company Support Owner',

    'usersList.empty.description': 'No users corresponding to your search',

    'usersListItem.copyEmailToClipboard': 'Copy email',
    'usersListItem.noAccount': 'No Account',
    'usersListItem.userStatus.connected': 'Connected',
    'usersListItem.userStatus.offboarded': 'Offboarded',
    'usersListItem.userStatus.pending': 'Pending',
    'usersListItem.userStatus.pending.hint': 'Invitation is pending. Click to copy the invitation link.',
    'usersListItem.userStatus.wasDeactivated': 'User was deactivated on {{date}}',
    'usersListItem.userStatus.willBeDeactivated': 'User will be deactivated on {{date}}',

    'usersListItemActions.impersonate': 'Impersonate',

    'usersListItemActionsMoreMenu.impersonateDevMode': 'Impersonate (Dev Mode)',
  },
};

export default en;
