import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import CompanyBreadcrumb from '@palette/components/companyDetails/CompanyBreadcrumb/CompanyBreadcrumb';
import CompanyDetailsPageHeader from '@palette/components/companyDetails/CompanyDetailsPageHeader/CompanyDetailsPageHeader';
import CompanyInANutshell from '@palette/components/companyDetails/CompanyInANutshell/CompanyInANutshell';
import CompanyDetailsSettings from '@palette/components/companyDetails/CompanyDetailsSettings/CompanyDetailsSettings';
import CompanyDetailsTabs from '@palette/components/companyDetails/CompanyDetailsTabs/CompanyDetailsTabs';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './CompanyDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();

  const {
    company,
    isPending,
    canAccessToCompanyDetails,
  } = useCompanyInParams(true);

  const detailsContentNode = useMemo(() => {
    if (company === null) return null;

    return (
      <div className={styles.details}>
        <CompanyInANutshell company={company} />
        <div className={styles.settingsUsersWrapper}>
          <CompanyDetailsSettings className={styles.settingsWrapper} company={company} />
          <CompanyDetailsTabs className={styles.tabsWrapper} company={company} />
        </div>
      </div>
    );
  }, [company]);

  if (!canAccessToCompanyDetails) {
    return (
      <Alert
        className={styles.accessNotAuthorizedAlert}
        type={ALERT_TYPES.ERROR}
        message={t('companyDetailsPageContent.accessNotAuthorized')}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <CompanyBreadcrumb company={company} />
      {
        company !== null && (
          <CompanyDetailsPageHeader className={styles.header} company={company} />
        )
      }
      <Loader className={styles.details} spinning={isPending}>
        {detailsContentNode}
      </Loader>
    </div>
  );
};

CompanyDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

CompanyDetailsPageContent.defaultProps = {
  className: '',
};

export default CompanyDetailsPageContent;
