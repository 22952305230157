import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import CompanyLink from '@palette/components/companies/CompanyLink/CompanyLink';

import { getDayjs } from '@palette/helpers/DayjsHelper';

import * as ApiLogModel from '@palette/models/ApiLog';
import * as UserModel from '@palette/models/User';

import styles from './ApiLogsList.less';

const classNames = bindClassNames.bind(styles);

const ApiLogsList = ({ className, apiLogs }) => {
  const { t } = useTranslation();
  const dayJS = getDayjs();

  const columns = useMemo(() => {
    const cols = [];

    const dateColumn = {
      id: 'date',
      Header: t('apiLogsList.columns.date.header'),
      accessor: (apiLog) => dayJS.utc(apiLog.createdAt).format('LLL'),
      minWidth: 230,
    };
    cols.push(dateColumn);

    const urlColumn = {
      id: 'url',
      Header: t('apiLogsList.columns.url.header'),
      accessor: 'url',
      minWidth: 300,
    };
    cols.push(urlColumn);

    const userColumn = {
      id: 'user',
      Header: t('apiLogsList.columns.user.header'),
      accessor: (apiLog) => (UserModel.getDisplayName(apiLog.user)),
      minWidth: 200,
    };
    cols.push(userColumn);

    const companyColumn = {
      id: 'company',
      Header: t('apiLogsList.columns.company.header'),
      accessor: 'company',
      minWidth: 200,
      /* eslint-disable-next-line react/prop-types */
      Cell: ({ value }) => (<CompanyLink company={value} />),
    };
    cols.push(companyColumn);

    const statusColumn = {
      id: 'status',
      Header: t('apiLogsList.columns.status.header'),
      accessor: 'status',
      minWidth: 70,
      width: 70,
    };
    cols.push(statusColumn);

    const timeColumn = {
      id: 'time',
      Header: t('apiLogsList.columns.time.header'),
      accessor: 'time',
      minWidth: 70,
      width: 70,
    };
    cols.push(timeColumn);

    const byPaletteAdminColumn = {
      id: 'byPaletteAdmin',
      Header: t('apiLogsList.columns.byPaletteAdmin.header'),
      accessor: 'byPaletteAdmin',
      minWidth: 70,
      width: 70,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        if (value) {
          return (
            <CheckFilled
              className={styles.byPaletteAdmin}
              width={20}
              height={20}
            />
          );
        }

        return (
          <ClosePopupFilled
            className={styles.notByPaletteAdmin}
            width={20}
            height={20}
          />
        );
      },
      /* eslint-enable react/prop-types */
    };
    cols.push(byPaletteAdminColumn);

    return cols;
  }, []);

  const contentNode = useMemo(() => {
    if (apiLogs === null) return null;

    if (apiLogs.length === 0) {
      return (
        <DefaultEmptyState description={t('apiLogsList.empty.description')} />
      );
    }

    return (
      <Table
        columns={columns}
        data={apiLogs}
        stickyHeader
        highlightRowOnHover
        fitInContainer
        centerInContainer
      />
    );
  }, [
    columns,
    apiLogs,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ApiLogsList.propTypes = {
  className: PropTypes.string,
  apiLogs: PropTypes.arrayOf(ApiLogModel.propTypes),
};

ApiLogsList.defaultProps = {
  className: '',
  apiLogs: null,
};

export default ApiLogsList;
