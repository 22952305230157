import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Upload as AntDUpload } from 'antd';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import PlusLine from '@palette/components/utils/Icons/PlusLine';
import Alert from '@palette/components/designSystem/Alert/Alert';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import appConfig from '@palette/config/app';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './PictureUploadInput.less';

const classNames = bindClassNames.bind(styles);

const PictureUploadInput = ({ className, placeholder, disabled, onChange, value }) => {
  const { t } = useTranslation();

  const [pictureUrl, setPictureUrl] = useState(value);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setPictureUrl(value);
  }, [value]);

  const handleBeforeUpload = useCallback((file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt1M = file.size / 1024 / 1024 < 0.5;
    const finalErrors = [];

    if (!isJpgOrPng) {
      finalErrors.push(t('pictureUploadInput.error.format'));
    }
    if (!isLt1M) {
      finalErrors.push(t('pictureUploadInput.error.size'));
    }

    const fileHasNoErrors = !finalErrors.length;

    setErrors(finalErrors);

    return fileHasNoErrors;
  }, [setErrors]);

  const changePictureUrl = useCallback((newPictureUrl) => {
    setPictureUrl(newPictureUrl);

    if (onChange !== null) {
      onChange(newPictureUrl);
    }
  }, [setPictureUrl, onChange]);

  const handleChange = useCallback((info) => {
    const { file } = info;

    switch (file.status) {
      case 'uploading':
        setErrors([]);
        setUploading(true);
        changePictureUrl(null);
        break;
      case 'done':
        setErrors([]);
        setUploading(false);
        changePictureUrl(file.response.secure_url);
        break;
      case 'error':
        setErrors([t('pictureUploadInput.error.uploading')]);
        setUploading(false);
        changePictureUrl(null);
        break;
      default:
    }
  }, [setErrors, setUploading, changePictureUrl]);

  const currentImageNode = useMemo(() => {
    if (pictureUrl) {
      return (
        <img
          className={styles.uploadedPictureImg}
          src={pictureUrl}
          alt=""
        />
      );
    }

    return (
      <div className={styles.uploadWrapper}>
        {uploading && (
          <LoadingLine
            className={styles.iconUpload}
            width={18}
            height={18}
            spin
          />
        )}
        {!uploading && (
          <PlusLine
            className={styles.iconUpload}
            width={14}
            height={14}
          />
        )}
        <div className={styles.labelUpload}>
          {placeholder ?? t('common.global.upload')}
        </div>
      </div>
    );
  }, [pictureUrl, uploading, placeholder]);

  const uploaderNode = useMemo(() => (
    <AntDUpload
      name="file"
      listType="picture-card"
      showUploadList={false}
      action={`${appConfig.CLOUDINARY_API}/${appConfig.CLOUDINARY_NAME}/upload`}
      beforeUpload={handleBeforeUpload}
      onChange={handleChange}
      data={{ upload_preset: appConfig.CLOUDINARY_PRESET }}
      disabled={disabled}
    >
      {currentImageNode}
    </AntDUpload>
  ), [
    disabled,
    handleBeforeUpload,
    handleChange,
    currentImageNode,
  ]);

  const deleteNode = useMemo(() => {
    if (pictureUrl === null) return null;

    return (
      <Tooltip title={t('common.global.remove')}>
        <Button
          className={styles.deleteBtn}
          type="linkDestroy"
          icon={<TrashFilled width={16} height={16} />}
          onClick={() => changePictureUrl(null)}
        />
      </Tooltip>
    );
  }, [pictureUrl, changePictureUrl]);

  const alertNode = useMemo(() => {
    if (!errors.length) return null;

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.ERROR}
        message={errors.map((error, index) => (<span key={index}>{error}</span>))}
      />
    );
  }, [errors]);

  if (
    appConfig.CLOUDINARY_API === null
    || appConfig.CLOUDINARY_NAME === null
    || appConfig.CLOUDINARY_PRESET === null
  ) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.uploaderDeleteWrapper}>
        {uploaderNode}
        {deleteNode}
      </div>
      {alertNode}
    </div>
  );
};

PictureUploadInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

PictureUploadInput.defaultProps = {
  className: '',
  placeholder: null,
  disabled: false,
  onChange: null,
  value: null,
};

export default PictureUploadInput;
