import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import CompanyCollectionsDetails from '@palette/components/companyCollections/CompanyCollectionsDetails/CompanyCollectionsDetails';

import * as CompanyModel from '@palette/models/Company';

import { actions as CompanyCollectionsActions, selectors as CompanyCollectionsSelectors } from '@palette/state/CompanyCollections';

import styles from './CompanyCollectionsWrapper.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsWrapper = ({ className, company }) => {
  const dispatch = useDispatch();

  const getCompanyCollectionsDetailsIsPending = useSelector(CompanyCollectionsSelectors.getCompanyCollectionsDetailsIsPending);
  const collectionsDetails = useSelector((state) => CompanyCollectionsSelectors.getCompanyCollectionsDetails(state, { companyId: company.id }));

  useEffect(() => {
    dispatch(CompanyCollectionsActions.getCompanyCollectionsDetails({ companyId: company.id }));
  }, [company.id]);

  const collectionsDetailsNode = useMemo(() => {
    if (collectionsDetails === null) return null;

    return (
      <CompanyCollectionsDetails mongoCollectionsStats={collectionsDetails} />
    );
  }, [collectionsDetails]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={getCompanyCollectionsDetailsIsPending}>
        <div>
          {collectionsDetailsNode}
        </div>
      </Loader>
    </div>
  );
};

CompanyCollectionsWrapper.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyCollectionsWrapper.defaultProps = {
  className: '',
};

export default CompanyCollectionsWrapper;
