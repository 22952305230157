import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import TimezoneSelect from '@palette/components/utils/TimezoneSelect/TimezoneSelect';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import PictureUploadInput from '@palette/components/utils/PictureUploadInput/PictureUploadInput';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getLocalTimezone } from '@palette/helpers/DayjsHelper';

import routePaths from '@palette/config/routePaths';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './EditCompanyModal.less';

const EditCompanyModal = ({ visible, onClose, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateCompanyIsPending = useSelector(CompaniesSelectors.updateCompanyIsPending);
  const checkSimilarCompanyNameIsPending = useSelector(CompaniesSelectors.checkSimilarCompanyNameIsPending);
  const similarCompanies = useSelector(CompaniesSelectors.getSimilarCompanies);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    logo: company.logo !== '' ? company.logo : null,
    name: company.name,
    timezone: company.timezone ?? getLocalTimezone(),
  }), [company]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const checkSimilarCompanyName = useCallback(
    _debounce((newName) => {
      dispatch(CompaniesActions.checkSimilarCompanyName({ name: newName }));
    }, 700),
    [],
  );

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.name !== undefined) {
      checkSimilarCompanyName(changedValues.name);
    }
  }, [checkSimilarCompanyName]);

  const onCompanyEditionSuccess = useCallback((companyId) => {
    redirectTo({ path: routePaths.companyDetails, params: { companyId } });
    cleanAndClose();
  }, [company, cleanAndClose]);

  const handleFinish = useCallback((values) => {
    dispatch(CompaniesActions.updateCompany({ companyId: company.id, company: values, onSuccessCB: onCompanyEditionSuccess }));
  }, [company, onCompanyEditionSuccess]);

  const handleEditCompany = () => form.submit();

  const similarCompaniesNode = useMemo(() => {
    let similarCompaniesContentNode = null;

    if (checkSimilarCompanyNameIsPending) {
      similarCompaniesContentNode = (
        <LoadingLine className={styles.similarCompaniesLoaderIcon} width={16} height={16} spin />
      );
    } else if (similarCompanies.length !== 0) {
      const similarCompaniesNames = similarCompanies.map((similarCompany) => similarCompany.name).join(', ');

      similarCompaniesContentNode = t('createCompanyModal.similarCompanies.label', { similarCompaniesNames });
    }

    return (
      <div className={styles.similarCompaniesWrapper}>
        {similarCompaniesContentNode}
      </div>
    );
  }, [checkSimilarCompanyNameIsPending, similarCompanies]);

  return (
    <Modal
      className={styles.modal}
      title={t('editCompanyModal.title', { companyName: company.name })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleEditCompany}
      okText={t('editCompanyModal.editCompany')}
      loading={updateCompanyIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          className={styles.logoFormItem}
          name="logo"
        >
          <PictureUploadInput placeholder={t('createCompanyModal.form.logo.placeholder')} disabled={updateCompanyIsPending} />
        </FormItem>
        <div className={styles.nameFieldWrapper}>
          <FormItem
            className={styles.nameFormItem}
            name="name"
            label={t('createCompanyModal.form.name.label')}
            required
            rules={[
              { required: true, message: t('createCompanyModal.form.name.rules.required') },
            ]}
          >
            <Input size="big" placeholder={t('createCompanyModal.form.name.placeholder')} disabled={updateCompanyIsPending} />
          </FormItem>
          {similarCompaniesNode}
        </div>
        <FormItem
          name="timezone"
          label={t('createCompanyModal.form.timezone.label')}
          required
        >
          <TimezoneSelect size="big" disabled={updateCompanyIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditCompanyModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  company: CompanyModel.propTypes.isRequired,
};

EditCompanyModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditCompanyModal;
