import { put, all, call, takeLatest } from 'redux-saga/effects';

import { actions as ApiLogsActions } from '@palette/state/ApiLogs/slice';
import { manageError as manageApiLogsError } from '@palette/state/ApiLogs/errors';

import { showErrorNotification } from '@palette/helpers/SagasHelper';

import * as ApiLogModel from '@palette/models/ApiLog';
import * as PaginationModel from '@palette/models/Pagination';

import * as ApiLogsService from '@palette/services/ApiLogsService';

export function* getApiLogs({ payload = {} }) {
  const {
    page,
    limit,
  } = payload;

  const callData = {
    page,
    limit,
  };

  const callResult = yield call(ApiLogsService.getApiLogs, callData);

  if (callResult.ok) {
    const apiLogs = ApiLogModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);
    yield put(ApiLogsActions.setApiLogs({ apiLogs, pagination }));
  } else {
    const error = manageApiLogsError(callResult);
    showErrorNotification(error);
  }

  yield put(ApiLogsActions.getApiLogsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(ApiLogsActions.getApiLogs.type, getApiLogs),
  ]);
}

export function* clean() {
  yield put(ApiLogsActions.resetToInitialState());
}
