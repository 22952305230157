import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import Button from '@palette/components/designSystem/Button/Button';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Select from '@palette/components/designSystem/Select/Select';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import {
  DEFAULT_SYNC_INTERVAL,
  SYNC_INTERVAL_MINUTES_OPTIONS,
  SYNC_INTERVAL_HOURS_OPTIONS,
} from '@palette/constants/company';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import styles from './CompanyDetailsSettingsGlobalSyncInterval.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalSyncInterval = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateCompany = useAccessToCompanyDetails(company.id);

  const updateSyncIntervalIsPending = useSelector(CompaniesSelectors.updateSyncIntervalIsPending);

  const [form] = AntDForm.useForm();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const onUpdateSyncIntervalSuccess = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form]);

  const updateSyncInterval = useCallback(() => {
    if (!canUpdateCompany) return;

    const { syncInterval } = form.getFieldsValue(true);

    dispatch(CompaniesActions.updateSyncInterval({
      companyId: company.id,
      syncInterval,
      onSuccessCB: onUpdateSyncIntervalSuccess,
    }));
  }, [canUpdateCompany, company, form, onUpdateSyncIntervalSuccess]);

  const syncIntervalOptions = useMemo(() => {
    const minutesOptions = SYNC_INTERVAL_MINUTES_OPTIONS.map((minuteOption) => ({
      label: `${minuteOption}${t('common.global.minutes.short').toLowerCase()}`,
      value: minuteOption,
    }));

    const hoursOptions = SYNC_INTERVAL_HOURS_OPTIONS.map((hourOption) => ({
      label: `${hourOption.label}${t('common.global.hours.short').toLowerCase()}`,
      value: hourOption.value,
    }));

    return {
      [t('common.global.minutes')]: minutesOptions,
      [t('common.global.hours')]: hoursOptions,
    };
  }, []);

  const syncIntervalNode = useMemo(() => {
    const { syncInterval } = company;

    let interval = `${syncInterval}${t('common.global.minutes.short').toLowerCase()}`;

    if (syncInterval >= 60) {
      const { label } = SYNC_INTERVAL_HOURS_OPTIONS.find((hourOption) => hourOption.value === syncInterval);
      interval = `${label}${t('common.global.hours.short').toLowerCase()}`;
    }

    return (
      <div className={styles.syncIntervalWrapper}>
        <RefreshLine className={styles.syncIntervalIcon} />
        {t('companyDetailsSettingsGlobalSyncInterval.companySyncInterval', { interval })}
      </div>
    );
  }, [company]);

  const displayModeNode = useMemo(() => {
    if (!canUpdateCompany) {
      return (
        <div className={styles.displayModeWrapper}>
          {syncIntervalNode}
        </div>
      );
    }

    return (
      <div className={styles.displayModeWrapper}>
        {syncIntervalNode}
        <Button
          type="link"
          flattenLink
          icon={<PenFilled width={18} height={18} />}
          onClick={() => setEditModeEnabled(true)}
          disabled={updateSyncIntervalIsPending}
        />
      </div>
    );
  }, [
    canUpdateCompany,
    syncIntervalNode,
    setEditModeEnabled,
    updateSyncIntervalIsPending,
  ]);

  const initialValues = useMemo(() => ({
    syncInterval: company.syncInterval || DEFAULT_SYNC_INTERVAL,
  }), [company]);

  useEffect(() => {
    if (editModeEnabled) {
      form.resetFields();
    }
  }, [initialValues, editModeEnabled]);

  const disableEditMode = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form, setEditModeEnabled]);

  const editModeNode = useMemo(() => (
    <div className={styles.editModeWrapper}>
      <Form initialValues={initialValues} form={form}>
        <FormItem
          name="syncInterval"
          label={t('companyDetailsSettingsGlobalSyncInterval.form.syncInterval.label')}
        >
          <Select
            options={syncIntervalOptions}
            disabled={updateSyncIntervalIsPending}
            allowClear={false}
          />
        </FormItem>
        <div className={styles.actions}>
          <Button className={styles.updateBtn} onClick={updateSyncInterval} disabled={updateSyncIntervalIsPending}>
            {t('common.global.edit')}
          </Button>
          <Button
            type="linkSecondary"
            onClick={disableEditMode}
            disabled={updateSyncIntervalIsPending}
          >
            {t('common.global.cancel')}
          </Button>
        </div>
      </Form>
    </div>
  ), [
    form,
    initialValues,
    syncIntervalOptions,
    updateSyncInterval,
    updateSyncIntervalIsPending,
    disableEditMode,
  ]);

  const contentNode = useMemo(() => {
    if (editModeEnabled) {
      return editModeNode;
    }

    return displayModeNode;
  }, [
    editModeEnabled,
    displayModeNode,
    editModeNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalSyncInterval.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalSyncInterval.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalSyncInterval;
