import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CompanyCollectionsStatsTotals from '@palette/components/companyCollections/CompanyCollectionsStatsTotals/CompanyCollectionsStatsTotals';
import CompanyCollectionsDetailsTabs from '@palette/components/companyCollections/CompanyCollectionsDetailsTabs/CompanyCollectionsDetailsTabs';

import * as MongoCollectionsStatsModel from '@palette/models/MongoCollectionsStats';

import styles from './CompanyCollectionsDetails.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsDetails = ({ className, mongoCollectionsStats }) => {
  const { t } = useTranslation();

  const statsTotalsNode = useMemo(() => (
    <CompanyCollectionsStatsTotals mongoCollectionsStats={mongoCollectionsStats} />
  ), [mongoCollectionsStats]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.statsWrapper}>
        <div className={styles.statsTitle}>
          {t('companyCollectionsDetails.statsTotalsTitle')}
        </div>
        {statsTotalsNode}
      </div>
      <CompanyCollectionsDetailsTabs className={styles.tabs} mongoCollectionsStats={mongoCollectionsStats} />
    </div>
  );
};

CompanyCollectionsDetails.propTypes = {
  className: PropTypes.string,
  mongoCollectionsStats: MongoCollectionsStatsModel.propTypes.isRequired,
};

CompanyCollectionsDetails.defaultProps = {
  className: '',
};

export default CompanyCollectionsDetails;
