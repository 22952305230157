import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import ApiLogsList from '@palette/components/apiLogs/ApiLogsList/ApiLogsList';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';

import {
  LIMIT_QS_KEY,
  PAGE_QS_KEY,
} from '@palette/constants/navigation';

import { actions as ApiLogsActions, selectors as ApiLogsSelectors } from '@palette/state/ApiLogs';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './ApiLogsListWrapper.less';

const classNames = bindClassNames.bind(styles);

const ApiLogsListWrapper = ({ className }) => {
  const dispatch = useDispatch();

  const getApiLogsIsPending = useSelector(ApiLogsSelectors.getApiLogsIsPending);
  const apiLogsList = useSelector(ApiLogsSelectors.getApiLogsList);
  const apiLogsListPagination = useSelector(ApiLogsSelectors.getPagination);

  const [getApiLogsLimit] = useLimitInSearch();
  const [getApiLogsPage] = usePageInSearch();

  const payloadCallData = useMemo(() => ({
    page: getApiLogsPage,
    limit: getApiLogsLimit,
  }), [
    getApiLogsPage,
    getApiLogsLimit,
  ]);

  useEffect(() => {
    dispatch(ApiLogsActions.getApiLogs(payloadCallData));
  }, [
    payloadCallData,
  ]);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const listNode = useMemo(() => {
    let paginationNode = null;
    if (apiLogsList !== null && apiLogsList.length > 0) {
      paginationNode = (
        <Pagination
          className={styles.pagination}
          pagination={apiLogsListPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      );
    }

    return (
      <Loader className={styles.list} spinning={getApiLogsIsPending}>
        <ApiLogsList className={styles.list} apiLogs={apiLogsList} />
        {paginationNode}
      </Loader>
    );
  }, [
    getApiLogsIsPending,
    apiLogsList,
    apiLogsListPagination,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listNode}
    </div>
  );
};

ApiLogsListWrapper.propTypes = {
  className: PropTypes.string,
};

ApiLogsListWrapper.defaultProps = {
  className: '',
};

export default ApiLogsListWrapper;
