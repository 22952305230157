import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Select from '@palette/components/designSystem/Select/Select';

import { SUPPORTED_CURRENCY_CODES } from '@palette/constants/currency';

import styles from './CurrencySelect.less';

const classNames = bindClassNames.bind(styles);

const CurrencySelect = ({ className, ...otherProps }) => {
  const currenciesOptions = useMemo(() => (
    SUPPORTED_CURRENCY_CODES.map((currencyCode) => ({
      label: currencyCode,
      value: currencyCode,
    }))
  ), []);

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={currenciesOptions}
      showSearch
      {...otherProps}
    />
  );
};

CurrencySelect.propTypes = {
  className: PropTypes.string,
};

CurrencySelect.defaultProps = {
  className: '',
};

export default CurrencySelect;
