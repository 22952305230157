import { getEnvValue } from '@palette/helpers/ConfigHelper';

const api = {
  BASE_URL: getEnvValue(process.env.API_URL, 'http://localhost:5854'),
  TIMEOUT: getEnvValue(process.env.API_TIMEOUT, 10000),

  ASYNC_JOB_MAX_TIME: parseInt(getEnvValue(process.env.ASYNC_JOB_MAX_TIME, '3600'), 10),

  PROBLEM_BASE_URI: getEnvValue(process.env.PROBLEM_BASE_URI, 'palette:error:'),

  ENDPOINTS: {
    apiLogs: {
      list: '/admins/api-logs/list',
    },
    companies: {
      checkSimilarCompanyName: '/admins/companies/check-similar-names',
      create: '/admins/companies/create',
      getCompanyById: '/admins/companies/get-by-id',
      list: '/admins/companies/list',
      update: '/admins/companies/update',
      updateFeature: '/admins/companies/update-features',
      updateConnector: '/admins/companies/update-connector',
      deleteConnector: '/admins/companies/delete-connector',
      updateTrialSettings: '/admins/companies/update-trial',
      updateBillingSettings: '/admins/companies/update-billing',
      offboardCompany: '/admins/companies/offboard',
      addPaletteAdmin: '/admins/companies/add-admin',
      removePaletteAdmin: '/admins/companies/remove-admin',
      recomputeTechnicalStats: '/admins/companies/recompute-technical-stats',
    },
    companyCollections: {
      getCollectionsDetails: '/admins/companies/get-collections-details-by-company',
    },
    companyJobs: {
      getJobsDetails: '/admins/companies/get-jobs-details-by-company',
      getAsyncJobById: '/admins/companies/get-async-job-by-id',
    },
    companyUsers: {
      listUsers: '/admins/companies/list-users',
      addNewUser: '/admins/companies/add-user-in-company',
      impersonate: '/admins/connect-as-user',
    },
    paletteAdmins: {
      list: '/admins/list-admins',
    },
    profile: {
      forgotPassword: '/admins/forget-password',
      getProfile: '/admins/get-profile',
      login: '/admins/login',
      resetPassword: '/admins/reset-password',
      logout: '/admins/logout',
    },
  },
};

export default api;
