import { createBrowserRouter } from 'react-router-dom';

import { setRouter } from '@palette/helpers/NavigationHelper';

import allRoutes from '@palette/config/routes/allRoutes';

const router = createBrowserRouter(allRoutes);

setRouter(router);

export default router;
