import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TableHeaderCell from '@palette/components/designSystem/TableHeaderCell/TableHeaderCell';

import { getColumnWidth, getColumnWidthAsFiniteNumber } from '@palette/helpers/components/TableHelper';

import styles from './TableHeader.less';

const classNames = bindClassNames.bind(styles);

const TableHeader = ({
  className,
  type,
  headerGroups,
  HeaderCellComponent,
  nbOfFixedColumns,
  fixedColumnsPosition,
  isSticky,
  stretch,
  horizontalScrollingOnLeft,
  horizontalScrollingOnRight,
}, ref = {}) => {
  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    refProp = {
      ref,
    };
  }

  return (
    <div
      {...refProp}
      className={classNames({
        header: true,
        stretch,
        borderless: type === 'borderless',
        [className]: className !== '',
        stickyHeader: isSticky,
      })}
    >
      {
        headerGroups.map((headerGroup) => {
          const { headers } = headerGroup;
          if (nbOfFixedColumns > 0) {
            let totalRight = 0;
            for (let i = headers.length - 1; i >= 0; i -= 1) {
              headers[i].totalRight = totalRight;
              totalRight += getColumnWidthAsFiniteNumber(headers[i]);
            }
          }
          return (
            <div className={styles.headerRow} {...headerGroup.getHeaderGroupProps()}>
              {
                headers.map((column, columnIndex, columns) => {
                  let columnWidth = getColumnWidthAsFiniteNumber(column);
                  if (stretch) {
                    columnWidth = getColumnWidth(column);
                  }

                  let cellContentNode = (
                    <HeaderCellComponent type={type}>
                      {
                        column.render('Header')
                      }
                    </HeaderCellComponent>
                  );
                  if (column.disableHeaderCellComponent === true) {
                    cellContentNode = column.render('Header');
                  }

                  const columnStyleLeft = !Number.isFinite(column.totalLeft) ? 0 : column.totalLeft;
                  const columnStyle = {
                    width: columnWidth,
                    minWidth: column.totalMinWidth,
                    maxWidth: column.totalMaxWidth,
                    left: columnStyleLeft,
                  };
                  if (nbOfFixedColumns > 0
                    && (
                      (fixedColumnsPosition === 'fromRight' && columnIndex !== 0)
                      || (fixedColumnsPosition === 'fromLeft' && columnIndex === columns.length - 1)
                    )
                  ) {
                    columnStyle.left = 'auto';
                    columnStyle.right = column.totalRight;
                  }

                  return (
                    <div
                      className={classNames({
                        headerCell: true,
                        stickyCol: (
                          (fixedColumnsPosition === 'fromLeft' && columnIndex < nbOfFixedColumns)
                          || (fixedColumnsPosition === 'fromRight' && columnIndex > (columns.length - 1 - nbOfFixedColumns))
                          || (
                            nbOfFixedColumns > 0
                            && (
                              columnIndex === 0
                              || columnIndex === columns.length - 1
                            )
                          )
                        ),
                        highlight: !!column.highlight,
                        withShadow: (
                          nbOfFixedColumns > 0
                          && (
                            (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnLeft && columnIndex === nbOfFixedColumns - 1)
                            || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnRight && columnIndex === columns.length - nbOfFixedColumns)
                          )
                        ),
                        withOppositeShadow: (
                          nbOfFixedColumns > 0
                          && (
                            (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnRight && columnIndex === columns.length - 1)
                            || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnLeft && columnIndex === 0)
                          )
                        ),
                        fixedColumnsFromRight: nbOfFixedColumns > 0 && fixedColumnsPosition === 'fromRight',
                        hidden: nbOfFixedColumns > 0 && (columnIndex === 0 || columnIndex === columns.length - 1),
                        lastNotHiddenColumn: (
                          (nbOfFixedColumns > 0 && columnIndex === columns.length - 2)
                          || (nbOfFixedColumns === 0 && columnIndex === columns.length - 1)
                        ),
                      })}
                      {...column.getHeaderProps()}
                      style={columnStyle}
                    >
                      {cellContentNode}
                    </div>
                  );
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  HeaderCellComponent: PropTypes.func,
  nbOfFixedColumns: PropTypes.number,
  fixedColumnsPosition: PropTypes.oneOf(['fromLeft', 'fromRight']),
  isSticky: PropTypes.bool,
  stretch: PropTypes.bool,
  horizontalScrollingOnLeft: PropTypes.bool,
  horizontalScrollingOnRight: PropTypes.bool,
};

const defaultPropsShape = {
  className: '',
  type: 'default',
  HeaderCellComponent: TableHeaderCell,
  nbOfFixedColumns: 0,
  fixedColumnsPosition: 'fromLeft',
  isSticky: true,
  stretch: false,
  horizontalScrollingOnLeft: false,
  horizontalScrollingOnRight: false,
};

TableHeader.propTypes = propTypesShape;
TableHeader.defaultProps = defaultPropsShape;

export const TableHeaderRefForwarded = forwardRef((props, ref) => TableHeader(props, ref));
TableHeaderRefForwarded.propTypes = propTypesShape;
TableHeaderRefForwarded.defaultProps = defaultPropsShape;

export default TableHeader;
