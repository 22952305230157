import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
} from '@palette/helpers/ModelHelper';
import { manageJobsStats } from '@palette/helpers/AsyncJobHelper';

import * as CompanyModel from '@palette/models/Company';
import * as JobStatsModel from '@palette/models/JobStats';
import * as AsyncJobModel from '@palette/models/AsyncJob';

export const modelName = 'JobsDetails';

export const propTypes = PropTypes.shape({
  company: CompanyModel.propTypes,
  stats: PropTypes.arrayOf(JobStatsModel.propTypes),
  jobs: PropTypes.arrayOf(AsyncJobModel.propTypes),
});

export const transform = (jobsDetails) => {
  if (!jobsDetails) {
    return null;
  }

  const rawStats = manageFreeShapeObjectAttribute(jobsDetails, 'stats');

  return (
    {
      company: CompanyModel.transform(manageFreeShapeObjectAttribute(jobsDetails, 'company')),
      stats: manageJobsStats(rawStats),
      jobs: AsyncJobModel.transformList(manageArrayAttribute(jobsDetails, 'jobs')),
    }
  );
};

export const transformList = (jobsDetailsList) => jobsDetailsList.map((jobsDetails) => transform(jobsDetails));
