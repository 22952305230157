import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import CreateCompany from '@palette/components/companies/CreateCompany/CreateCompany';
import CompaniesListWrapper from '@palette/components/companies/CompaniesListWrapper/CompaniesListWrapper';

import styles from './CompaniesPageContent.less';

const classNames = bindClassNames.bind(styles);

const CompaniesPageContent = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <PageTitle title={t('companiesPageContent.title')} />
        <CreateCompany />
      </div>
      <CompaniesListWrapper className={styles.companiesList} />
    </div>
  );
};

CompaniesPageContent.propTypes = {
  className: PropTypes.string,
};

CompaniesPageContent.defaultProps = {
  className: '',
};

export default CompaniesPageContent;
