import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Select as AntDSelect } from 'antd';

import Button from '@palette/components/designSystem/Button/Button';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import { deduplicateFilterFn } from '@palette/helpers/CommonHelper';

import styles from './Select.less';

const classNames = bindClassNames.bind(styles);

const Select = ({
  className,
  dropdownClassName,
  options,
  defaultSelectedValue,
  placeholder,
  disabled,
  showSearch,
  enableFilterOptions,
  filterOptionProp,
  searchNotFoundLabel,
  addSearchToOptions,
  addSearchToOptionsLabel,
  defaultOpen,
  onSelect,
  onChange,
  onSearch,
  onDropdownVisibleChange,
  size,
  open,
  bordered,
  mode,
  value,
  pendingOptions,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(value ?? defaultSelectedValue);
  const [searchValue, setSearchValue] = useState('');
  const [addedOptions, setAddedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(defaultOpen);

  useEffect(() => {
    setSelectedValue(value ?? defaultSelectedValue);
  }, [value, defaultSelectedValue]);

  const finalOptions = useMemo(() => {
    if (Array.isArray(options)) {
      return addedOptions.concat(options);
    }

    return {
      ...options,
      addedOptions,
    };
  }, [options, addedOptions]);

  const optionsNodes = useMemo(() => {
    if (Array.isArray(options)) {
      return finalOptions.map(({ label, value: val, ...otherOptionProps }) => (
        <AntDSelect.Option key={val} value={val} {...otherOptionProps}>
          {label}
        </AntDSelect.Option>
      ));
    }

    return Object.keys(options).map((optionGroup) => {
      const optNodes = options[optionGroup].map(({ label, value: val, ...otherOptionProps }) => (
        <AntDSelect.Option key={val} value={val} {...otherOptionProps}>
          {label}
        </AntDSelect.Option>
      ));

      return (
        <AntDSelect.OptGroup key={optionGroup} label={optionGroup}>
          {optNodes}
        </AntDSelect.OptGroup>
      );
    });
  }, [finalOptions]);

  const handleDropdownVisibleChange = (val) => {
    setDropdownOpen(val);
    onDropdownVisibleChange(val);
  };

  let managedProps = {
    onSearch,
    onSelect,
    onChange,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    open: open !== null ? open : dropdownOpen,
    bordered,
    mode,
    allowClear: { clearIcon: (<ClosePopupFilled onClick={() => onChange(null)} />) },
    value: selectedValue,
  };
  if (showSearch) {
    const handleOnSearch = (val) => {
      if (onSearch) {
        onSearch(val);
      }
      setSearchValue(val);
    };
    const handleOnSelect = (val, opt) => {
      setSelectedValue(val);
      onSelect(val, opt);
      onChange(val, opt);
    };
    const handleFilterOption = (inputValue, option) => {
      if (inputValue !== '') {
        if (option.options !== undefined) {
          return option.options.some((catOption) => (catOption[filterOptionProp].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1));
        }

        return option[filterOptionProp].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      }

      return true;
    };

    managedProps = {
      showSearch,
      onSearch: handleOnSearch,
      onSelect: handleOnSelect,
      filterOption: enableFilterOptions ? handleFilterOption : true,
      optionFilterProp: filterOptionProp,
      value: selectedValue,
      onDropdownVisibleChange: handleDropdownVisibleChange,
      open: open !== null ? open : dropdownOpen,
      bordered,
      mode,
      allowClear: { clearIcon: (<ClosePopupFilled onClick={() => handleOnSelect(null)} />) },
    };
  }

  let notFoundContentNode = (
    <div className={styles.not_found_content_description}>
      {searchNotFoundLabel || t('select.searchNotFound')}
    </div>
  );
  if (showSearch && addSearchToOptions) {
    const handleAddToOptions = () => {
      const newOpt = { label: searchValue, value: searchValue };
      setAddedOptions((addedOpts) => {
        const allOpts = [newOpt, ...addedOpts];
        const allOptsValues = allOpts.map((opt) => opt.value);
        return allOpts.filter((elem, index) => deduplicateFilterFn(elem.value, index, allOptsValues));
      });
      managedProps.onSelect(searchValue, newOpt);
      setDropdownOpen(false);
    };

    notFoundContentNode = (
      <div className={styles.not_found_content_wrapper}>
        {notFoundContentNode}
        <div className={styles.add_to_options_wrapper}>
          <div className={styles.search_value}>
            {searchValue}
          </div>
          <Button type="secondary" onClick={handleAddToOptions}>
            {addSearchToOptionsLabel || t('select.addToOptions')}
          </Button>
        </div>
      </div>
    );
  }

  managedProps = {
    ...managedProps,
    notFoundContent: notFoundContentNode,
  };

  if (pendingOptions) {
    return (
      <div
        className={classNames({
          loaderWrapper: true,
          big: size === 'big',
          borderLess: !bordered,
          [className]: className !== '',
        })}
      >
        <div className={styles.loaderIconWrapper}>
          <LoadingLine className={styles.loaderIcon} spin width={20} height={20} />
        </div>
      </div>
    );
  }

  return (
    <AntDSelect
      className={classNames({
        wrapper: true,
        big: size === 'big',
        borderLess: !bordered,
        tags: mode === 'tags',
        [className]: className !== '',
      })}
      popupClassName={classNames({
        dropdown: true,
        dropdownBorderLess: !bordered,
        dropdownBorderLessOneOption: !bordered && optionsNodes.length < 2,
        dropdownTags: mode === 'tags',
        [dropdownClassName]: dropdownClassName !== '',
      })}
      suffixIcon={<ChevronDownLine width={24} height={24} onClick={() => setDropdownOpen(!dropdownOpen)} />}
      placeholder={placeholder}
      disabled={disabled}
      {...managedProps}
      {...otherProps}
    >
      {optionsNodes}
    </AntDSelect>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })),
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    }))),
  ]).isRequired,
  value: PropTypes.any,
  defaultSelectedValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showSearch: PropTypes.bool,
  enableFilterOptions: PropTypes.bool,
  filterOptionProp: PropTypes.string,
  addSearchToOptions: PropTypes.bool,
  addSearchToOptionsLabel: PropTypes.string,
  searchNotFoundLabel: PropTypes.string,
  defaultOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  size: PropTypes.oneOf(['default', 'big']),
  open: PropTypes.bool,
  bordered: PropTypes.bool,
  mode: PropTypes.string,
  pendingOptions: PropTypes.bool,
};

Select.defaultProps = {
  className: '',
  dropdownClassName: '',
  value: null,
  defaultSelectedValue: null,
  placeholder: '',
  disabled: false,
  showSearch: false,
  enableFilterOptions: false,
  filterOptionProp: 'children',
  addSearchToOptions: false,
  addSearchToOptionsLabel: null,
  searchNotFoundLabel: null,
  defaultOpen: false,
  onSelect: () => {},
  onChange: () => {},
  onSearch: undefined,
  onDropdownVisibleChange: () => {},
  size: 'default',
  open: null,
  bordered: true,
  mode: null,
  pendingOptions: false,
};

export default Select;
