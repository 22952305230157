import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { Tabs as AntDTabs } from 'antd';

import { useLocation, useTabKeyInSearch } from '@palette/hooks/NavigationHooks';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './Tabs.less';

const classNames = bindClassNames.bind(styles);

const TAB_KEY_PREFIX = 'tab_';

const Tabs = ({
  className,
  tabs,
  stretched,
  disableSeeMoreTabsBtn,
  onChange,
  qsTabKey,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const [qsActiveKey] = useTabKeyInSearch(qsTabKey);

  const [defaultActiveKey, setDefaultActiveKey] = useState(`${TAB_KEY_PREFIX}0`);
  const [activeKey, setActiveKey] = useState(undefined);

  const location = useLocation();

  const handleTabChange = useCallback((newActiveTabKey) => {
    if (activeKey === newActiveTabKey) return;

    const tabIndex = parseInt(newActiveTabKey.split(TAB_KEY_PREFIX)[1], 10);
    if (onChange !== null) onChange(tabIndex);
    if (qsTabKey !== null) {
      let beforeToUpdateLocation = location;

      if (activeKey) {
        const activeTabIndex = parseInt(activeKey.split(TAB_KEY_PREFIX)[1], 10);

        if (tabs[activeTabIndex]?.onLeaveTab) {
          tabs[activeTabIndex].onLeaveTab();
        }

        if (tabs[activeTabIndex]?.beforeUpdateLocation) {
          beforeToUpdateLocation = tabs[activeTabIndex].beforeUpdateLocation(beforeToUpdateLocation);
        }
      }

      const finalTabKey = tabs[tabIndex].tabKey || `${TAB_KEY_PREFIX}${tabIndex}`;
      dispatch(NavigationActions.addQSToLocation({ forcedLocation: beforeToUpdateLocation, qsObject: { [qsTabKey]: finalTabKey } }));
    }
  }, [tabs, qsTabKey, activeKey, location]);

  const tabsItems = useMemo(() => (
    tabs.map((
      {
        title,
        titleCount = 0,
        content,
        defaultActive = false,
        isActive = null,
        tabKey = null,
        ...otherTabProps
      },
      index,
    ) => {
      const tabPaneKey = `${TAB_KEY_PREFIX}${index}`;

      if (defaultActive) {
        setDefaultActiveKey(tabPaneKey);
      }

      if (isActive === true) {
        setActiveKey(tabPaneKey);
      } else if (
        isActive === null
        && qsTabKey !== null
      ) {
        const finalTabKey = tabKey || tabPaneKey;
        if (qsActiveKey === finalTabKey) {
          setActiveKey(tabPaneKey);
        }
      }

      let titleNode = title;
      if (titleCount !== 0) {
        titleNode = (
          <div className={styles.titleWithCount}>
            {titleNode}
            <div className={styles.count}>
              {` · ${titleCount}`}
            </div>
          </div>
        );
      }
      return {
        key: tabPaneKey,
        label: titleNode,
        children: content,
        ...otherTabProps,
      };
    })
  ), [
    tabs,
    qsActiveKey,
    setActiveKey,
    setDefaultActiveKey,
  ]);

  return (
    <AntDTabs
      className={classNames({
        wrapper: true,
        stretched,
        disableSeeMoreTabsBtn,
        [className]: className !== '',
      })}
      items={tabsItems}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={handleTabChange}
      {...otherProps}
    />
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    titleCount: PropTypes.number,
    content: PropTypes.any,
    defaultActive: PropTypes.bool,
    isActive: PropTypes.bool,
    tabKey: PropTypes.string,
    onLeaveTab: PropTypes.func,
    beforeUpdateLocation: PropTypes.func,
  })).isRequired,
  stretched: PropTypes.bool,
  disableSeeMoreTabsBtn: PropTypes.bool,
  onChange: PropTypes.func,
  qsTabKey: PropTypes.string,
};

Tabs.defaultProps = {
  className: '',
  stretched: false,
  disableSeeMoreTabsBtn: true,
  onChange: null,
  qsTabKey: null,
};

export default Tabs;
