import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { STATUS_FILTER_QS_KEY, STATUSES } from '@palette/constants/user';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CompanyUsersListStatusFilter.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsersListStatusFilter = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [statusFilterValue] = useKeyInSearch(STATUS_FILTER_QS_KEY);

  const statusesOptions = useMemo(() => ([
    {
      label: t('usersListItem.userStatus.connected'),
      value: STATUSES.CONNECTED.toLowerCase(),
    },
    {
      label: t('usersListItem.userStatus.pending'),
      value: STATUSES.PENDING.toLowerCase(),
    },
    {
      label: t('usersListItem.userStatus.offboarded'),
      value: STATUSES.OFFBOARDED.toLowerCase(),
    },
    {
      label: t('usersListItem.noAccount'),
      value: STATUSES.NOT_INVITED.toLowerCase(),
    },
  ]), []);

  const handleStatusFilterChange = useCallback((newStatusFilterValue = null) => {
    const QSToAdd = {};
    const keysToDelete = [];

    if (newStatusFilterValue === null) {
      keysToDelete.push(STATUS_FILTER_QS_KEY);
    } else {
      QSToAdd[STATUS_FILTER_QS_KEY] = newStatusFilterValue;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={statusesOptions}
      placeholder={t('companyUsersListStatusFilter.allStatuses')}
      value={statusFilterValue}
      onChange={handleStatusFilterChange}
      popupMatchSelectWidth={false}
    />
  );
};

CompanyUsersListStatusFilter.propTypes = {
  className: PropTypes.string,
};

CompanyUsersListStatusFilter.defaultProps = {
  className: '',
};

export default CompanyUsersListStatusFilter;
