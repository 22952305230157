/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';

/*
 * Initial State
 */
const initialState = {
  getApiLogsIsPending: false,

  byId: {},
  list: null,
  pagination: null,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'apiLogs',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Api Logs */
    getApiLogs: (state) => {
      state.getApiLogsIsPending = true;
    },
    setApiLogs: (state, { payload }) => {
      const { apiLogs, pagination } = payload;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(apiLogs, 'id'),
      );

      state.list = _map(apiLogs, 'id');

      state.pagination = pagination;
    },
    getApiLogsCompleted: (state) => {
      state.getApiLogsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const rawById = (state) => root(state).byId;
const rawList = (state) => root(state).list;
const rawPagination = (state) => root(state).pagination;

const getApiLogsIsPending = (state) => root(state).getApiLogsIsPending;

const getApiLogsList = createSelector(
  [rawList, rawById],
  (list, byId) => {
    if (list === null) return null;

    return list.map((apiLogId) => byId[apiLogId] || null).filter(Boolean);
  },
);

const getPagination = rawPagination;

export const selectors = {
  getApiLogsIsPending,

  getApiLogsList,
  getPagination,
};
