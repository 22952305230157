import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import PaletteAdminSelect from '@palette/components/utils/PaletteAdminSelect/PaletteAdminSelect';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import styles from './AddPaletteAdminModal.less';

const AddPaletteAdminModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { companyId } = useCompanyInParams();

  const addPaletteAdminIsPending = useSelector(CompaniesSelectors.addPaletteAdminIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    paletteAdmin: null,
  };

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const handleFinish = useCallback((values) => {
    const { paletteAdmin } = values;

    dispatch(CompaniesActions.addPaletteAdmin({ companyId, paletteAdminId: paletteAdmin, onSuccessCB: cleanAndClose }));
  }, [companyId, cleanAndClose]);

  const handleAddPaletteAdmin = useCallback(() => form.submit(), [form]);

  return (
    <Modal
      className={styles.modal}
      title={t('addPaletteAdminModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddPaletteAdmin}
      okText={t('addPaletteAdminModal.addPaletteAdmin')}
      loading={addPaletteAdminIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="paletteAdmin"
          label={t('addPaletteAdminModal.form.paletteAdmin.label')}
          required
          rules={[
            { required: true, message: t('addPaletteAdminModal.form.paletteAdmin.rules.required') },
          ]}
        >
          <PaletteAdminSelect
            size="big"
            filterIfAlreadyInCompany
            disabled={addPaletteAdminIsPending}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

AddPaletteAdminModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddPaletteAdminModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddPaletteAdminModal;
