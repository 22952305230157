import { put, all, call, take, takeLatest } from 'redux-saga/effects';

import { actions as CompanyUsersActions } from '@palette/state/CompanyUsers/slice';
import { manageError as manageCompanyUsersError } from '@palette/state/CompanyUsers/errors';
import { actions as CompaniesActions } from '@palette/state/Companies/slice';

import { showErrorNotification, showSuccessNotification } from '@palette/helpers/SagasHelper';

import { IMPERSONATE_MODES } from '@palette/constants/user';

import appConfig from '@palette/config/app';

import * as MetaUserModel from '@palette/models/MetaUser';

import * as CompanyUsersService from '@palette/services/CompanyUsersService';

export function* addNewUser({ payload = {} }) {
  const {
    companyId,
    firstName,
    lastName,
    email,
    currency,
    role,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    firstName,
    lastName,
    email,
    currency,
    role,
  };

  const callResult = yield call(CompanyUsersService.addNewUser, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    // Refresh Company Users
    yield put(CompanyUsersActions.getCompanyUsers({ companyId }));
    yield take(CompanyUsersActions.getCompanyUsersCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.addNewUser' });
  } else {
    const error = manageCompanyUsersError(callResult);
    showErrorNotification(error);
  }

  yield put(CompanyUsersActions.addNewUserCompleted());
}

export function* getCompanyUsers({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompanyUsersService.listUsers, callData);

  if (callResult.ok) {
    const users = MetaUserModel.transformList(callResult.data);

    yield put(CompanyUsersActions.setCompanyUsers({ companyId, users }));
  } else {
    const error = manageCompanyUsersError(callResult);
    showErrorNotification(error);
  }

  yield put(CompanyUsersActions.getCompanyUsersCompleted());
}

export function* copyEmailToClipboard({ payload = {} }) {
  const {
    user,
  } = payload;

  try {
    yield call(() => (navigator.clipboard.writeText(user.email)));
    showSuccessNotification({ i18nId: 'notifications.success.copyEmailToClipboard', params: { email: user.email } });
  } catch (e) {
    showErrorNotification({
      i18nId: 'notifications.error.copyEmailToClipboard',
      message: '',
    });
  }
}

export function* copyInvitationLinkToClipboard({ payload = {} }) {
  const {
    user,
  } = payload;

  if (user.account?.invitationLink != null) {
    try {
      yield call(() => (navigator.clipboard.writeText(user.account.invitationLink)));
      showSuccessNotification({ i18nId: 'notifications.success.copyInvitationLinkToClipboard', params: { displayName: user.displayName } });
    } catch (e) {
      showErrorNotification({
        i18nId: 'notifications.error.copyInvitationLinkToClipboard',
        message: '',
      });
    }
  }
}

export function* impersonate({ payload = {} }) {
  const {
    userId,
    mode = IMPERSONATE_MODES.PRODUCTION,
  } = payload;

  const callData = {
    userId,
  };

  const callResult = yield call(CompanyUsersService.impersonate, callData);

  if (callResult.ok) {
    let appBaseUrl;

    switch (mode) {
      case IMPERSONATE_MODES.LOCALHOST: {
        appBaseUrl = appConfig.APP_LOCAL_URL;
        break;
      }
      default: {
        appBaseUrl = appConfig.APP_PROD_URL;
      }
    }

    window.open(`${appBaseUrl}/#/connect-as/${callResult.data.sessionId}`, '_blank');
  } else {
    const error = manageCompanyUsersError(callResult);
    showErrorNotification(error);
  }

  yield put(CompanyUsersActions.impersonateCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CompanyUsersActions.addNewUser.type, addNewUser),
    takeLatest(CompanyUsersActions.getCompanyUsers.type, getCompanyUsers),
    takeLatest(CompanyUsersActions.copyEmailToClipboard.type, copyEmailToClipboard),
    takeLatest(CompanyUsersActions.copyInvitationLinkToClipboard.type, copyInvitationLinkToClipboard),
    takeLatest(CompanyUsersActions.impersonate.type, impersonate),
  ]);
}

export function* clean() {
  yield put(CompanyUsersActions.resetToInitialState());
}
