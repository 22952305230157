import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'SimilarCompany';

export const propTypes = PropTypes.shape({
  name: PropTypes.string,
  score: PropTypes.number,
});

export const transform = (similarCompany) => {
  if (!similarCompany) {
    return null;
  }

  return (
    {
      name: manageStringAttribute(similarCompany, 'name'),
      score: manageNumberAttribute(similarCompany, 'score'),
    }
  );
};

export const transformList = (similarCompanies) => similarCompanies.map((similarCompany) => transform(similarCompany));
