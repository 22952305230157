import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

export const getProfile = () => (
  ApiService.post(apiConfig.ENDPOINTS.profile.getProfile, undefined, getConfig())
);

export const login = (payload) => {
  const { email, password } = payload;

  const data = {
    email,
    password,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.login, data);
};

export const forgotPassword = (payload) => {
  const { email } = payload;

  const data = {
    email,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.forgotPassword, data);
};

export const resetPassword = (payload) => {
  const { resetPasswordToken, password } = payload;

  const data = {
    resetPasswordToken,
    password,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.resetPassword, data);
};

export function* logout() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.profile.logout, {}, getConfig()),
    [],
  );
}
