import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CalendarFilled from '@palette/components/utils/Icons/CalendarFilled';

import { getDayjs } from '@palette/helpers/DayjsHelper';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyInfosCreatedAt.less';

const classNames = bindClassNames.bind(styles);

const CompanyInfosCreatedAt = ({ className, company }) => {
  const dayJS = getDayjs();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <CalendarFilled />
      <div className={styles.createdAt}>
        {dayJS.utc(company.createdAt).format('ll')}
      </div>
    </div>
  );
};

CompanyInfosCreatedAt.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyInfosCreatedAt.defaultProps = {
  className: '',
};

export default CompanyInfosCreatedAt;
