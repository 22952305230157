import { put, all, call, takeLatest } from 'redux-saga/effects';

import { actions as PaletteAdminsActions } from '@palette/state/PaletteAdmins/slice';
import { manageError as managePaletteAdminsError } from '@palette/state/PaletteAdmins/errors';

import { showErrorNotification } from '@palette/helpers/SagasHelper';

import * as PaletteAdminModel from '@palette/models/PaletteAdmin';

import * as PaletteAdminsService from '@palette/services/PaletteAdminsService';

export function* getPaletteAdmins() {
  const callResult = yield call(PaletteAdminsService.getPaletteAdmins);

  if (callResult.ok) {
    const paletteAdmins = PaletteAdminModel.transformList(callResult.data);
    yield put(PaletteAdminsActions.setPaletteAdmins({ paletteAdmins }));
  } else {
    const error = managePaletteAdminsError(callResult);
    showErrorNotification(error);
  }

  yield put(PaletteAdminsActions.getPaletteAdminsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(PaletteAdminsActions.getPaletteAdmins.type, getPaletteAdmins),
  ]);
}

export function* clean() {
  yield put(PaletteAdminsActions.resetToInitialState());
}
