import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useProfile, useProfileRights } from '@palette/hooks/ProfileHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { RIGHTS } from '@palette/constants/role';
import {
  COMPANIES_LIST_FILTERS_KEYS,
  HAS_STRIPE_SUBSCRIPTION_QS_KEY,
  HAS_TRIAL_END_DATE_QS_KEY,
  IS_ARCHIVED_QS_KEY,
  IS_LIVE_QS_KEY,
  SEARCHED_COMPANY_QS_KEY,
  SORT_CREATED_DATE_QS_KEY,
  SORT_NAME_QS_KEY,
} from '@palette/constants/company';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

export const useAccessToCompanyDetails = (companyId) => {
  const profile = useProfile();

  const hasAdminAllRight = useProfileRights([RIGHTS.ADMIN_ALL]);
  if (hasAdminAllRight) return true;

  return profile.companies.includes(companyId);
};

export const useSearchedCompanyInSearch = () => useKeyInSearch(SEARCHED_COMPANY_QS_KEY, '');

export const useIsLiveInSearch = () => {
  const [isLive, isLiveInSearch] = useKeyInSearch(IS_LIVE_QS_KEY, null);

  return [isLive === 'true', isLiveInSearch, isLive];
};

export const useHasStripeSubscriptionInSearch = () => {
  const [hasStripeSubscription, hasStripeSubscriptionInSearch] = useKeyInSearch(HAS_STRIPE_SUBSCRIPTION_QS_KEY, null);

  return [hasStripeSubscription === 'true', hasStripeSubscriptionInSearch, hasStripeSubscription];
};

export const useHasTrialEndDateInSearch = () => {
  const [hasTrialEndDate, hasTrialEndDateInSearch] = useKeyInSearch(HAS_TRIAL_END_DATE_QS_KEY, null);

  return [hasTrialEndDate === 'true', hasTrialEndDateInSearch, hasTrialEndDate];
};

export const useIsArchivedInSearch = () => {
  const [isArchived, isArchivedInSearch] = useKeyInSearch(IS_ARCHIVED_QS_KEY, null);

  return [isArchived === 'true', isArchivedInSearch, isArchived];
};

export const useCompaniesListFiltersInitialValue = () => {
  const [isLiveFromSearch, isLiveInSearch] = useIsLiveInSearch();
  const [hasStripeSubscriptionFromSearch, hasStripeSubscriptionInSearch] = useHasStripeSubscriptionInSearch();
  const [hasTrialEndDateFromSearch, hasTrialEndDateInSearch] = useHasTrialEndDateInSearch();
  const [isArchivedFromSearch, isArchivedInSearch] = useIsArchivedInSearch();

  return useMemo(() => {
    let selectedFilters = [COMPANIES_LIST_FILTERS_KEYS.IS_LIVE];

    if (isLiveInSearch || hasStripeSubscriptionInSearch || hasTrialEndDateInSearch || isArchivedInSearch) {
      selectedFilters = [];

      if (isLiveInSearch) {
        if (isLiveFromSearch) {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.IS_LIVE);
        } else {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.IS_NOT_LIVE);
        }
      }

      if (hasStripeSubscriptionInSearch) {
        if (hasStripeSubscriptionFromSearch) {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.HAS_STRIPE_SUBSCRIPTION);
        } else {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_STRIPE_SUBSCRIPTION);
        }
      }

      if (hasTrialEndDateInSearch) {
        if (hasTrialEndDateFromSearch) {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.HAS_TRIAL_END_DATE);
        } else {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_TRIAL_END_DATE);
        }
      }

      if (isArchivedInSearch) {
        if (isArchivedFromSearch) {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.IS_ARCHIVED);
        } else {
          selectedFilters.push(COMPANIES_LIST_FILTERS_KEYS.IS_NOT_ARCHIVED);
        }
      }
    }

    return selectedFilters;
  }, [
    isLiveFromSearch,
    isLiveInSearch,
    hasStripeSubscriptionFromSearch,
    hasStripeSubscriptionInSearch,
    hasTrialEndDateFromSearch,
    hasTrialEndDateInSearch,
    isArchivedFromSearch,
    isArchivedInSearch,
  ]);
};

export const useSortNameInSearch = () => (
  useKeyInSearch(SORT_NAME_QS_KEY, 'asc')
);

export const useSortCreatedDateInSearch = () => (
  useKeyInSearch(SORT_CREATED_DATE_QS_KEY, 'desc')
);

export const useCompaniesListSortInitialValue = () => {
  const [sortName, sortNameInSearch] = useSortNameInSearch();
  const [sortCreatedDate, sortCreatedDateInSearch] = useSortCreatedDateInSearch();

  return useMemo(() => {
    if (sortNameInSearch) {
      return {
        sortKey: SORT_NAME_QS_KEY,
        sortKeyValue: sortName,
      };
    }

    if (sortCreatedDateInSearch) {
      return {
        sortKey: SORT_CREATED_DATE_QS_KEY,
        sortKeyValue: sortCreatedDate,
      };
    }

    return {
      sortKey: SORT_CREATED_DATE_QS_KEY,
      sortKeyValue: 'desc',
    };
  }, [
    sortName,
    sortNameInSearch,
    sortCreatedDate,
    sortCreatedDateInSearch,
  ]);
};

export const useCompaniesLastUsedFiltersPathQS = () => {
  const companiesLastUsedFilters = useSelector(CompaniesSelectors.getLastUsedFilters);

  return useMemo(() => {
    const pathQS = {};

    if (companiesLastUsedFilters.isLive != null) {
      pathQS[IS_LIVE_QS_KEY] = companiesLastUsedFilters.isLive;
    }

    if (companiesLastUsedFilters.hasStripeSubscription != null) {
      pathQS[HAS_STRIPE_SUBSCRIPTION_QS_KEY] = companiesLastUsedFilters.hasStripeSubscription;
    }

    if (companiesLastUsedFilters.hasTrialEndDate != null) {
      pathQS[HAS_TRIAL_END_DATE_QS_KEY] = companiesLastUsedFilters.hasTrialEndDate;
    }

    if (companiesLastUsedFilters.isArchived != null) {
      pathQS[IS_ARCHIVED_QS_KEY] = companiesLastUsedFilters.isArchived;
    }

    if (companiesLastUsedFilters.searchedCompany != null && companiesLastUsedFilters.searchedCompany !== '') {
      pathQS[SEARCHED_COMPANY_QS_KEY] = companiesLastUsedFilters.searchedCompany;
    }

    if (companiesLastUsedFilters.sort != null) {
      pathQS[companiesLastUsedFilters.sort.sortKey] = companiesLastUsedFilters.sort.sortKeyValue;
    }

    return pathQS;
  }, [companiesLastUsedFilters]);
};

export const useCompanyInParams = (forceRefresh = false) => {
  const dispatch = useDispatch();

  const { companyId } = useParams();

  const company = useSelector((state) => CompaniesSelectors.getCompanyById(state, { companyId }));
  const isPending = useSelector((state) => CompaniesSelectors.getCompanyByIdIsPending(state));

  const canAccessToCompanyDetails = useAccessToCompanyDetails(companyId);

  useEffect(() => {
    if ((company === null || forceRefresh) && canAccessToCompanyDetails) {
      dispatch(CompaniesActions.getCompanyById({ companyId }));
    }
  }, [companyId, canAccessToCompanyDetails]);

  return useMemo(() => ({
    companyId,
    company,
    isPending,
    canAccessToCompanyDetails,
  }), [
    companyId,
    company,
    isPending,
    canAccessToCompanyDetails,
  ]);
};
