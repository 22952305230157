import React from 'react';

import ErrorPage from '@palette/pages/public/ErrorPage/ErrorPage';
import PageWrapper from '@palette/pages/shared/PageWrapper/PageWrapper';

import routePaths from '@palette/config/routePaths';
import publicRoutes from '@palette/config/routes/publicRoutes';
import restrictedRoutes from '@palette/config/routes/restrictedRoutes';

export const allRoutes = [
  {
    path: routePaths.basePath,
    element: (
      <PageWrapper />
    ),
    errorElement: (
      <ErrorPage />
    ),
    children: [
      ...publicRoutes,
      ...restrictedRoutes,
    ],
  },
];

export default allRoutes;
