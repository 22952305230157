import React from 'react';
import { createRoot } from 'react-dom/client';
import { message } from 'antd';

import '@palette/styles/global.less';

import App from './App';

message.config({
  top: 64,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
