import ApiLogs from '@palette/state/ApiLogs';
import Companies from '@palette/state/Companies';
import CompanyCollections from '@palette/state/CompanyCollections';
import CompanyJobs from '@palette/state/CompanyJobs';
import CompanyUsers from '@palette/state/CompanyUsers';
import Navigation from '@palette/state/Navigation';
import PaletteAdmins from '@palette/state/PaletteAdmins';
import Profile from '@palette/state/Profile';

export const entities = [
  ApiLogs,
  Companies,
  CompanyCollections,
  CompanyJobs,
  CompanyUsers,
  Navigation,
  PaletteAdmins,
  Profile,
];
