import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation as useRouterLocation } from 'react-router-dom';

import { getSearch } from '@palette/helpers/NavigationHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';

export const useLocation = () => {
  const routerLocation = useRouterLocation();
  const currentLocation = useSelector(NavigationSelectors.getCurrentLocation);
  if (currentLocation !== '') return currentLocation;

  return routerLocation;
};

export const useKeyInSearch = (qsKey, defaultValue = null) => {
  const location = useLocation();

  return useMemo(() => {
    const qsObj = getSearch(location);

    return [qsObj[qsKey] || defaultValue, qsKey in qsObj];
  }, [location]);
};

export const useTabKeyInSearch = (tabKey, defaultValue = null) => {
  if (tabKey === null) return [defaultValue, false];

  return useKeyInSearch(tabKey, defaultValue);
};

export const usePageInSearch = (defaultValue = DEFAULT_PAGE_QS_VALUE) => {
  const [page, pageInSearch] = useKeyInSearch(PAGE_QS_KEY, defaultValue);

  return [parseInt(page, 10), pageInSearch];
};

export const useLimitInSearch = (defaultValue = DEFAULT_LIMIT_QS_VALUE) => {
  const [limit, limitInSearch] = useKeyInSearch(LIMIT_QS_KEY, defaultValue);

  return [parseInt(limit, 10), limitInSearch];
};
