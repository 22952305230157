import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CalendarFilled from '@palette/components/utils/Icons/CalendarFilled';
import HourglassFilled from '@palette/components/utils/Icons/HourglassFilled';
import TimerFilled from '@palette/components/utils/Icons/TimerFilled';
import TouchFilled from '@palette/components/utils/Icons/TouchFilled';
import BotFilled from '@palette/components/utils/Icons/BotFilled';
import SaveAsFilled from '@palette/components/utils/Icons/SaveAsFilled';
import Button from '@palette/components/designSystem/Button/Button';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import JobListItemJsonModal from '@palette/components/companyJobs/JobListItemJsonModal/JobListItemJsonModal';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { formatDuration, getDayjs } from '@palette/helpers/DayjsHelper';
import { isTooOld, jobInProgress } from '@palette/helpers/AsyncJobHelper';

import { ASYNC_JOB_STATUS_TAG_TYPES, ASYNC_JOB_STATUSES, ASYNC_JOB_TYPE_TAG_TYPES } from '@palette/constants/asyncJobs';
import { RIGHTS } from '@palette/constants/role';

import { actions as CompanyJobsActions, selectors as CompanyJobsSelectors } from '@palette/state/CompanyJobs';

import * as AsyncJobModel from '@palette/models/AsyncJob';

import styles from './JobsListItem.less';

const classNames = bindClassNames.bind(styles);

const JobsListItem = ({ className, job }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dayJS = getDayjs();

  const canAutoSyncJob = useProfileRights([RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW]);

  const autoSyncIsPending = useSelector((state) => CompanyJobsSelectors.autoSyncIsPending(state, { jobId: job.id }));

  const [jsonString, setJsonString] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const jobTypeNode = useMemo(() => (
    <Tag
      className={styles.infosBlockItem}
      type={ASYNC_JOB_TYPE_TAG_TYPES[job.type]}
    >
      {job.type}
    </Tag>
  ), [job]);

  const launchDateNode = useMemo(() => (
    <Tooltip title={t('jobsListItem.launchDate')}>
      <div
        className={classNames({
          infosBlockItem: true,
          launchDateWrapper: true,
        })}
      >
        <CalendarFilled />
        <div className={styles.launchDate}>
          {job.launchDate !== null ? dayJS.utc(job.launchDate).format('lll') : '-'}
        </div>
      </div>
    </Tooltip>
  ), [job]);

  const lastActivityAtNode = useMemo(() => (
    <Tooltip title={t('jobsListItem.lastActivityAt')}>
      <div
        className={classNames({
          infosBlockItem: true,
          lastActivityAtWrapper: true,
        })}
      >
        <HourglassFilled className={styles.lastActivityAtIcon} spin={autoSyncIsPending} />
        <div className={styles.lastActivityAt}>
          {job.lastActivityAt !== null ? dayJS.utc(job.lastActivityAt).format('lll ss[s]') : '-'}
        </div>
      </div>
    </Tooltip>
  ), [job, autoSyncIsPending]);

  const statusNode = useMemo(() => (
    <Tag
      className={styles.infosBlockItem}
      type={ASYNC_JOB_STATUS_TAG_TYPES[job.status]}
    >
      {job.status}
    </Tag>
  ), [job]);

  const durationNode = useMemo(() => {
    if (job.duration === null) return null;

    return (
      <Tooltip title={t('jobsListItem.duration')}>
        <div
          className={classNames({
            infosBlockItem: true,
            durationWrapper: true,
          })}
        >
          <TimerFilled className={styles.durationIcon} />
          <div className={styles.duration}>
            {formatDuration(job.duration)}
          </div>
        </div>
      </Tooltip>
    );
  }, [job]);

  const userJobNode = useMemo(() => {
    let userJobIconNode = (
      <BotFilled className={styles.botIcon} />
    );
    let userJobDescription = t('jobsListItem.userJob.autoSync');
    if (job.userJob) {
      userJobIconNode = (
        <TouchFilled className={styles.touchIcon} />
      );
      userJobDescription = t('jobsListItem.userJob.manualSync');
    }

    return (
      <div
        className={classNames({
          infosBlockItem: true,
          userJobWrapper: true,
        })}
      >
        {userJobIconNode}
        <div className={styles.userJobDescription}>
          {userJobDescription}
        </div>
      </div>
    );
  }, [job]);

  const writeLockNode = useMemo(() => {
    let writeLockDescription = t('jobsListItem.writeLock.notLocked');
    if (job.writeLock) {
      writeLockDescription = t('jobsListItem.writeLock.isSaving');
    }

    return (
      <div
        className={classNames({
          infosBlockItem: true,
          writeLockWrapper: true,
          isSaving: job.writeLock,
        })}
      >
        <SaveAsFilled className={styles.writeLockIcon} />
        <div className={styles.writeLockDescription}>
          {writeLockDescription}
        </div>
      </div>
    );
  }, [job]);

  const handleCopyJobIdToClipboard = useCallback(() => {
    dispatch(CompanyJobsActions.copyJobIdToClipboard({ job }));
  }, [job]);

  const copyJobIdToClipboardNode = useMemo(() => (
    <Button
      className={styles.infosBlockItem}
      type="link"
      flattenLink
      icon={(<DuplicateFilled width={14} height={14} />)}
      onClick={handleCopyJobIdToClipboard}
    >
      {t('jobsListItem.copyJobIdToClipboard')}
    </Button>
  ), [handleCopyJobIdToClipboard]);

  const handleAutoSync = useCallback(() => {
    dispatch(CompanyJobsActions.autoSync({ job }));
  }, [job]);

  const autoSyncNode = useMemo(() => {
    if (
      job.status === ASYNC_JOB_STATUSES.DONE
      || job.status === ASYNC_JOB_STATUSES.ERRORED
    ) return null;

    if (isTooOld(job)) {
      return (
        <div
          className={classNames({
            infosBlockItem: true,
            tooOldToAutoSync: true,
          })}
        >
          <InfoCircleFilled className={styles.tooOldToAutoSyncIcon} />
          <div className={styles.tooOldToAutoSyncDescription}>
            {t('jobsListItem.tooOldToAutoSync')}
          </div>
        </div>
      );
    }

    if (!jobInProgress(job) || !canAutoSyncJob) return null;

    return (
      <Button
        className={classNames({
          infosBlockItem: true,
          autoBtnSyncIsPending: autoSyncIsPending,
        })}
        type="linkSecondary"
        flattenLink
        icon={(<RefreshLine spin={autoSyncIsPending} />)}
        onClick={handleAutoSync}
        disabled={autoSyncIsPending}
      >
        {t('jobsListItem.autoSync')}
      </Button>
    );
  }, [job, handleAutoSync, autoSyncIsPending, canAutoSyncJob]);

  const handleCloseJsonModal = useCallback(() => {
    setJsonString(null);
    setModalTitle(null);
  }, []);

  const handleShowJsonModal = useCallback((jsonStr, title) => {
    setJsonString(jsonStr);
    setModalTitle(title);
  }, []);

  const showParamsNode = useMemo(() => {
    if (job.params === null) return null;

    return (
      <div className={styles.infosBlock}>
        <Button
          className={styles.infosBlockItem}
          type="primaryBlue"
          thin
          icon={(<EyesLine />)}
          onClick={() => handleShowJsonModal(job.params, t('jobsListItem.modalTitles.params'))}
        >
          {t('jobsListItem.showParams')}
        </Button>
      </div>
    );
  }, [job, handleShowJsonModal]);

  const showResultNode = useMemo(() => {
    if (job.result === null) return null;

    return (
      <div className={styles.infosBlock}>
        <Button
          className={styles.infosBlockItem}
          type="primary"
          thin
          icon={(<EyesLine />)}
          onClick={() => handleShowJsonModal(job.result, t('jobsListItem.modalTitles.result'))}
        >
          {t('jobsListItem.showResult')}
        </Button>
      </div>
    );
  }, [job, handleShowJsonModal]);

  const showErrorNode = useMemo(() => {
    if (job.error === null) return null;

    return (
      <div className={styles.infosBlock}>
        <Button
          className={styles.infosBlockItem}
          type="danger"
          thin
          icon={(<EyesLine />)}
          onClick={() => handleShowJsonModal(job.error, t('jobsListItem.modalTitles.error'))}
        >
          {t('jobsListItem.showError')}
        </Button>
      </div>
    );
  }, [job, handleShowJsonModal]);

  return (
    <div
      className={classNames({
        wrapper: true,
        autoSyncIsPending,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.infosBlock}>
          {jobTypeNode}
          {launchDateNode}
        </div>
        <div className={styles.infosBlock}>
          {lastActivityAtNode}
          {statusNode}
        </div>
      </div>
      <div className={styles.infosWrapper}>
        <div className={styles.infosBlock}>
          {userJobNode}
          {writeLockNode}
          {durationNode}
        </div>
        <div className={styles.infosBlock}>
          {copyJobIdToClipboardNode}
          {autoSyncNode}
        </div>
      </div>
      <div className={styles.infosWrapper}>
        {showParamsNode}
        {showResultNode}
        {showErrorNode}
      </div>
      {
        jsonString !== null && (
          <JobListItemJsonModal
            visible
            jsonString={jsonString}
            modalTitle={modalTitle}
            onClose={handleCloseJsonModal}
          />
        )
      }
    </div>
  );
};

JobsListItem.propTypes = {
  className: PropTypes.string,
  job: AsyncJobModel.propTypes.isRequired,
};

JobsListItem.defaultProps = {
  className: '',
};

export default JobsListItem;
