import dayjs from 'dayjs';

// import locales
import 'dayjs/locale/fr';

// import plugins
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';

const dayjsInstance = dayjs;

// Set default locale
dayjsInstance.locale('en');

// Extend Dayjs instance with plugins
dayjsInstance.extend(advancedFormat);
dayjsInstance.extend(isBetween);
dayjsInstance.extend(isSameOrAfter);
dayjsInstance.extend(isSameOrBefore);
dayjsInstance.extend(isToday);
dayjsInstance.extend(isoWeek);
dayjsInstance.extend(localizedFormat);
dayjsInstance.extend(localeData);
dayjsInstance.extend(pluralGetSet);
dayjsInstance.extend(quarterOfYear);
dayjsInstance.extend(relativeTime);
dayjsInstance.extend(utc);
dayjsInstance.extend(timezone);
dayjsInstance.extend(duration);

export const getDayjs = () => (dayjsInstance);

export const setDayjsLocale = (locale) => {
  dayjsInstance.locale(locale);
};

export const getLocalTimezone = () => getDayjs().tz.guess();

export const shortFormatWithShortYear = (date, inUTC = false) => {
  let dayjsDate = dayjsInstance(date);
  if (inUTC) {
    dayjsDate = dayjsInstance.utc(date);
  }

  const formattedDate = dayjsDate.format('ll');

  // Eg: "Nov 23, 22"
  return formattedDate.slice(0, formattedDate.length - 4) + formattedDate.slice(-2);
};

export const formatToISOStringWithFractionalSeconds = (date, endOfSecond = false) => {
  if (endOfSecond) {
    return dayjsInstance.utc(date).endOf('second').toISOString();
  }

  return dayjsInstance.utc(date).toISOString();
};

export const formatDuration = (time, unit = 'milliseconds') => {
  const dayJSDuration = dayjsInstance.duration(time, unit);

  if (dayJSDuration.years() > 0) return dayJSDuration.format('Y[y] M[m] D[d] H[h] m[min] s[s]');
  if (dayJSDuration.months() > 0) return dayJSDuration.format('M[m] D[d] H[h] m[min] s[s]');
  if (dayJSDuration.days() > 0) return dayJSDuration.format('D[d] H[h] m[min] s[s]');
  if (dayJSDuration.hours() > 0) return dayJSDuration.format('H[h] m[min] s[s]');
  if (dayJSDuration.minutes() > 0) return dayJSDuration.format('m[min] s[s]');
  if (dayJSDuration.seconds() > 0) return dayJSDuration.format('s[s]');

  return dayJSDuration.humanize();
};
