import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import MinSeatsFilled from '@palette/components/utils/Icons/MinSeatsFilled';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyInfosMinSeats.less';

const classNames = bindClassNames.bind(styles);

const CompanyInfosMinSeats = ({ className, company }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('companiesListItem.minSeats', { count: company.minSeats || 0 })}>
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <MinSeatsFilled className={styles.minSeatsIcon} width={20} height={20} />
        <div className={styles.minSeats}>
          {company.minSeats || 0}
        </div>
      </div>
    </Tooltip>
  );
};

CompanyInfosMinSeats.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyInfosMinSeats.defaultProps = {
  className: '',
};

export default CompanyInfosMinSeats;
