import { getDayjs } from '@palette/helpers/DayjsHelper';

import { ASYNC_JOB_STATUSES } from '@palette/constants/asyncJobs';

import appConfig from '@palette/config/app';
import apiConfig from '@palette/config/api';

import * as JobStatsModel from '@palette/models/JobStats';

export const manageJobsStats = (rawStats) => {
  const statsList = Object.entries(rawStats).map(([jobType, jobStats]) => ({
    type: jobType,
    ...jobStats,
  }));
  return JobStatsModel.transformList(statsList);
};

export const jobInProgress = (job) => {
  const dayJS = getDayjs();
  return (
    job.status !== ASYNC_JOB_STATUSES.DONE
    && job.status !== ASYNC_JOB_STATUSES.ERRORED
    && (
      dayJS.utc().diff(dayJS.utc(job.lastActivityAt), 'seconds') < appConfig.ASYNC_JOB_PROGRESS_DELAY
    )
  );
};

export const isTooOld = (job) => {
  const dayJS = getDayjs();

  return dayJS.utc().diff(dayJS.utc(job.launchDate), 'seconds') > apiConfig.ASYNC_JOB_MAX_TIME;
};
