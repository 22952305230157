export const STATEMENT_STRATEGY_TYPES = {
  MONTH: 'MONTH',
  HALF_MONTH: 'HALF_MONTH',
  BI_WEEK: 'BI_WEEK',
  WEEK: 'WEEK',
};

export const WEEK_ROLL_TYPES = {
  US: 'US',
  EU: 'EU',
};

export const HALF_MONTH_PIVOTS = {
  FIFTEENTH: 'FIFTEENTH',
  SIXTEENTH: 'SIXTEENTH',
};
