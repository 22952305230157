import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '@palette/components/designSystem/Switch/Switch';
import CompanyDetailsSettingsBlock from '@palette/components/companyDetails/CompanyDetailsSettingsBlock/CompanyDetailsSettingsBlock';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsConnectors.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsConnectors = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateConnectors = useProfileRights([RIGHTS.ADMIN_COMPANIES_CONNECTORS_UPDATE]);

  const updateConnectorIsPending = useSelector(CompaniesSelectors.updateConnectorIsPending);
  const deleteConnectorIsPending = useSelector(CompaniesSelectors.deleteConnectorIsPending);

  const updateConnector = useCallback((connectorId, newAutoSyncStatus) => {
    if (!canUpdateConnectors) return;

    dispatch(CompaniesActions.updateConnector({ companyId: company.id, connectorId, newAutoSyncStatus }));
  }, [canUpdateConnectors, company]);

  const deleteConnector = useCallback((connectorId) => {
    if (!canUpdateConnectors) return;

    dispatch(CompaniesActions.deleteConnector({ companyId: company.id, connectorId }));
  }, [canUpdateConnectors, company]);

  const contentNode = useMemo(() => {
    if (company.connectors.length === 0) {
      return (
        <DefaultEmptyState description={t('companyDetailsSettingsConnectors.empty.description')} />
      );
    }

    return company.connectors.map((connector) => (
      <div className={styles.connector} key={connector.id}>
        <Switch
          className={styles.connectorSwitch}
          value={connector.autoSync}
          disabled={(!canUpdateConnectors || updateConnectorIsPending)}
          onChange={(newAutoSyncStatus) => updateConnector(connector.id, newAutoSyncStatus)}
        />
        <div className={styles.connectorLabel}>
          {`${connector.type}${connector.name !== null ? ` (${connector.name})` : ''}`}
        </div>
        <Popconfirm
          title={t('companyDetailsSettingsConnectors.deleteConnector.popconfirmTitle')}
          onConfirm={() => deleteConnector(connector.id)}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
          disabled={(!canUpdateConnectors || deleteConnectorIsPending)}
        >
          <Button
            className={styles.connectorTrash}
            type="linkDestroy"
            flattenLink
            icon={(<TrashFilled width={16} height={16} />)}
          />
        </Popconfirm>
      </div>
    ));
  }, [
    company,
    canUpdateConnectors,
    updateConnectorIsPending,
    updateConnector,
    deleteConnectorIsPending,
    deleteConnector,
  ]);

  return (
    <CompanyDetailsSettingsBlock
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyDetailsSettingsConnectors.title')}
      content={contentNode}
    />
  );
};

CompanyDetailsSettingsConnectors.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsConnectors.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsConnectors;
