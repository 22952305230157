import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { ASYNC_JOB_TYPES } from '@palette/constants/asyncJobs';

export const modelName = 'JobStats';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(ASYNC_JOB_TYPES)),
  avgDuration: PropTypes.number,
  totalDuration: PropTypes.number,
  count: PropTypes.number,
});

export const transform = (jobStats) => {
  if (!jobStats) {
    return null;
  }

  const type = (ASYNC_JOB_TYPES[manageStringAttribute(jobStats, 'type')] || ASYNC_JOB_TYPES.COMPUTE);

  return (
    {
      type,
      avgDuration: managePureFloatAttribute(jobStats, 'avgDuration'),
      totalDuration: managePureFloatAttribute(jobStats, 'totalDuration'),
      count: manageNumberAttribute(jobStats, 'count'),
    }
  );
};

export const transformList = (jobStatsList) => jobStatsList.map((jobStats) => transform(jobStats));
