import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import UsersListItemActionsMoreMenu from '@palette/components/companyUsers/UsersListItemActionsMoreMenu/UsersListItemActionsMoreMenu';

import { useImpersonateActionIsAvailable } from '@palette/hooks/UserHooks';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as CompanyUsersActions, selectors as CompanyUsersSelectors } from '@palette/state/CompanyUsers';

import styles from './UsersListItemActions.less';

const classNames = bindClassNames.bind(styles);

const UsersListItemActions = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const impersonateIsPending = useSelector(CompanyUsersSelectors.impersonateIsPending);

  const impersonateActionIsAvailable = useImpersonateActionIsAvailable(user);

  const handleImpersonate = useCallback(() => {
    dispatch(CompanyUsersActions.impersonate({ userId: user.id }));
  }, [user]);

  const impersonateBtnNode = useMemo(() => {
    if (!impersonateActionIsAvailable) return null;

    return (
      <Button
        type="primaryBlue"
        thin
        icon={(<EyesLine />)}
        onClick={handleImpersonate}
        loading={impersonateIsPending}
      >
        {t('usersListItemActions.impersonate')}
      </Button>
    );
  }, [
    impersonateActionIsAvailable,
    handleImpersonate,
    impersonateIsPending,
  ]);

  const moreMenuNode = useMemo(() => (
    <UsersListItemActionsMoreMenu className={styles.moreMenu} user={user} />
  ), [
    user,
  ]);

  if (!impersonateActionIsAvailable) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {impersonateBtnNode}
      {moreMenuNode}
    </div>
  );
};

UsersListItemActions.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UsersListItemActions.defaultProps = {
  className: '',
};

export default UsersListItemActions;
