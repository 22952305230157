import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Avatar as AntDAvatar } from 'antd';

import stringToColor from 'string-to-color';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserAvatar.less';

const classNames = bindClassNames.bind(styles);

const UserAvatar = ({ className, size, user, style }) => {
  const userProfilePictureUrl = user.profilePictureUrl;

  let contentNode = null;

  if (userProfilePictureUrl === null) {
    contentNode = user.displayName.substring(0, 1).toUpperCase();
  }

  return (
    <AntDAvatar
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      draggable={false}
      shape="circle"
      size={size}
      src={userProfilePictureUrl}
      style={{
        backgroundColor: userProfilePictureUrl ? 'transparent' : stringToColor(user.id),
        lineHeight: '2.2rem',
        ...style,
      }}
    >
      {contentNode}
    </AntDAvatar>
  );
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: MetaUserModel.propTypes.isRequired,
};

UserAvatar.defaultProps = {
  className: '',
  style: {},
  size: 'small',
};

export default UserAvatar;
