import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';

import * as MongoCollectionsDetailsModel from '@palette/models/MongoCollectionsDetails';

import styles from './CompanyCollectionsListItem.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsListItem = ({ className, collectionsDetails }) => {
  const { t } = useTranslation();

  const collectionNameNode = useMemo(() => (
    <div
      className={classNames({
        infosBlockItem: true,
        collectionNameWrapper: true,
      })}
    >
      {collectionsDetails.collectionName}
    </div>
  ), [collectionsDetails]);

  const nbDocumentsNode = useMemo(() => (
    <div
      className={classNames({
        infosBlockItem: true,
        nbDocumentsWrapper: true,
      })}
    >
      {t('companyCollectionsListItem.nbDocuments', { count: collectionsDetails.count, displayedCount: comaSeparatorFormatter(collectionsDetails.count) })}
    </div>
  ), [collectionsDetails]);

  const memorySizeNode = useMemo(() => (
    <div
      className={classNames({
        infosBlockItem: true,
        memorySizeWrapper: true,
      })}
    >
      {t('companyCollectionsListItem.memorySize', { size: prettyBytes(collectionsDetails.size) })}
    </div>
  ), [collectionsDetails]);

  const storageSizeNode = useMemo(() => (
    <div
      className={classNames({
        infosBlockItem: true,
        storageSizeWrapper: true,
      })}
    >
      {t('companyCollectionsListItem.storageSize', { size: prettyBytes(collectionsDetails.storageSize) })}
    </div>
  ), [collectionsDetails]);

  const avgObjSizeNode = useMemo(() => (
    <div
      className={classNames({
        infosBlockItem: true,
        avgObjSizeWrapper: true,
      })}
    >
      {t('companyCollectionsListItem.avgObjSize', { size: prettyBytes(collectionsDetails.avgObjSize) })}
    </div>
  ), [collectionsDetails]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.infosBlock}>
          {collectionNameNode}
        </div>
        <div className={styles.infosBlock}>
          {nbDocumentsNode}
        </div>
      </div>
      <div className={styles.infosWrapper}>
        <div className={styles.infosBlock}>
          {memorySizeNode}
        </div>
        <div className={styles.infosBlock}>
          {storageSizeNode}
        </div>
        <div className={styles.infosBlock}>
          {avgObjSizeNode}
        </div>
      </div>
    </div>
  );
};

CompanyCollectionsListItem.propTypes = {
  className: PropTypes.string,
  collectionsDetails: MongoCollectionsDetailsModel.propTypes.isRequired,
};

CompanyCollectionsListItem.defaultProps = {
  className: '',
};

export default CompanyCollectionsListItem;
