/* Tabs in company details page */
export const COMPANY_DETAILS_TABS_QS_KEY = 'companyDetailsTab';
export const COMPANY_DETAILS_TABS_IDS = {
  USERS: 'users',
  JOBS: 'jobs',
  COLLECTIONS: 'collections',
};

/* Tabs in collections tab in company details page */
export const COMPANY_COLLECTIONS_DETAILS_TABS_QS_KEY = 'companyCollectionsDetailsTab';
export const COMPANY_COLLECTIONS_DETAILS_TABS_IDS = {
  COMPANY: 'company',
  PALETTE: 'palette',
};
