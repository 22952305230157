import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';

import { actions as CompanyUsersActions, selectors as CompanyUsersSelectors } from '@palette/state/CompanyUsers';

import * as CompanyModel from '@palette/models/Company';

import styles from './AddNewUserModal.less';

const AddNewUserModal = ({ visible, onClose, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currenciesOptions = useMemo(() => (
    company.currencies.map((currency) => ({
      label: currency.code,
      value: currency.code,
    }))
  ), [company]);

  const rolesOptions = useMemo(() => (
    company.roles.map((role) => ({
      label: role.name,
      value: role.id,
    }))
  ), [company]);

  const addNewUserIsPending = useSelector(CompanyUsersSelectors.addNewUserIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    currency: company.currency,
    role: null,
  };

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const handleFinish = useCallback((values) => {
    dispatch(CompanyUsersActions.addNewUser({ companyId: company.id, ...values, onSuccessCB: cleanAndClose }));
  }, [cleanAndClose]);

  const handleAddNewUser = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('addNewUserModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddNewUser}
      okText={t('addNewUserModal.addNewUser')}
      loading={addNewUserIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="firstName"
          label={t('addNewUserModal.form.firstName.label')}
          required
          rules={[
            { required: true, message: t('addNewUserModal.form.firstName.rules.required') },
          ]}
        >
          <Input size="big" disabled={addNewUserIsPending} />
        </FormItem>
        <FormItem
          name="lastName"
          label={t('addNewUserModal.form.lastName.label')}
          required
          rules={[
            { required: true, message: t('addNewUserModal.form.lastName.rules.required') },
          ]}
        >
          <Input size="big" disabled={addNewUserIsPending} />
        </FormItem>
        <FormItem
          name="email"
          label={t('addNewUserModal.form.email.label')}
          required
          rules={[
            { required: true, message: t('addNewUserModal.form.email.rules.required') },
            { type: 'email', warningOnly: false, message: t('addNewUserModal.form.email.rules.email') },
          ]}
        >
          <Input size="big" disabled={addNewUserIsPending} />
        </FormItem>
        <FormItem
          name="currency"
          label={t('addNewUserModal.form.currency.label')}
          required
        >
          <Select
            size="big"
            options={currenciesOptions}
            disabled={addNewUserIsPending}
            allowClear={false}
          />
        </FormItem>
        <FormItem
          name="role"
          label={t('addNewUserModal.form.role.label')}
          required
          rules={[
            { required: true, message: t('addNewUserModal.form.role.required') },
          ]}
        >
          <Select size="big" options={rolesOptions} disabled={addNewUserIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

AddNewUserModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  company: CompanyModel.propTypes.isRequired,
};

AddNewUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddNewUserModal;
