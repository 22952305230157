import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Dropdown as AntDDropdown, Menu as AntDMenu } from 'antd';

import ThreeDotsFilled from '@palette/components/utils/Icons/ThreeDotsFilled';

import styles from './MoreMenu.less';

const classNames = bindClassNames.bind(styles);

const MoreMenu = ({ className, items, children, ...otherProps }) => {
  let childrenNode = (
    <div className={styles.contentWrapper}>
      <ThreeDotsFilled className={styles.icon} width={24} height={24} />
    </div>
  );

  if (children !== null) {
    childrenNode = children;
  }

  return (
    <AntDDropdown
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      overlayClassName={styles.overlay}
      trigger="click"
      dropdownRender={() => (
        <AntDMenu
          items={
            items.map((item) => {
              let iconNode = null;
              if (item.icon) {
                iconNode = (
                  <div className={styles.menuItemIcon}>
                    {item.icon}
                  </div>
                );
              }
              const labelNode = (
                <div
                  key={item.key}
                  className={classNames({
                    menuItem: true,
                    [item.className]: item.className && item.className !== '',
                  })}
                  onClick={item.onClick}
                >
                  {iconNode}
                  <div className={styles.menuItemTitle}>
                    {item.title}
                  </div>
                </div>
              );

              return {
                key: item.key,
                label: labelNode,
              };
            })
          }
        />
      )}
      {...otherProps}
    >
      <div>
        {childrenNode}
      </div>
    </AntDDropdown>
  );
};

MoreMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.any.isRequired,
    icon: PropTypes.any,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  children: PropTypes.any,
};

MoreMenu.defaultProps = {
  className: '',
  children: null,
};

export default MoreMenu;
