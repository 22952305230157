import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './CompanyDetailsSettingsBlock.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsBlock = ({ className, title, content }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.titleWrapper}>
      <div className={styles.title}>
        {title}
      </div>
    </div>
    <div className={styles.content}>
      {content}
    </div>
  </div>
);

CompanyDetailsSettingsBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

CompanyDetailsSettingsBlock.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsBlock;
