import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PaletteAdmin';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
});

export const transform = (paletteAdmin) => {
  if (!paletteAdmin) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(paletteAdmin, '_id'),
      email: manageStringAttribute(paletteAdmin, 'email'),
    }
  );
};

export const transformList = (paletteAdmins) => paletteAdmins.map((paletteAdmin) => transform(paletteAdmin));
