import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import ApiLogsListWrapper from '@palette/components/apiLogs/ApiLogsListWrapper/ApiLogsListWrapper';

import styles from './ApiLogsPageContent.less';

const classNames = bindClassNames.bind(styles);

const ApiLogsPageContent = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PageTitle title={t('apiLogsPageContent.title')} />
      <ApiLogsListWrapper className={styles.apiLogsList} />
    </div>
  );
};

ApiLogsPageContent.propTypes = {
  className: PropTypes.string,
};

ApiLogsPageContent.defaultProps = {
  className: '',
};

export default ApiLogsPageContent;
