import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './TableHeaderCell.less';

const classNames = bindClassNames.bind(styles);

const TableHeaderCell = ({ className, type, children }) => (
  <div
    className={classNames({
      wrapper: true,
      borderless: type === 'borderless',
      [className]: className !== '',
    })}
  >
    {children}
  </div>
);

TableHeaderCell.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  children: PropTypes.any.isRequired,
};

TableHeaderCell.defaultProps = {
  className: '',
  type: 'default',
};

export default TableHeaderCell;
