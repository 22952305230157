import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';

import {
  useCompanyInParams,
} from '@palette/hooks/CompanyHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { ROLE_FILTER_QS_KEY } from '@palette/constants/user';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CompanyUsersListRoleFilter.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsersListRoleFilter = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { company, isPending } = useCompanyInParams();

  const [roleFilterValue] = useKeyInSearch(ROLE_FILTER_QS_KEY);

  const rolesOptions = useMemo(() => {
    if (company === null) return [];

    return company.roles.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  }, [company]);

  const handleRoleFilterChange = useCallback((newRoleFilterValue = null) => {
    const QSToAdd = {};
    const keysToDelete = [];

    if (newRoleFilterValue === null) {
      keysToDelete.push(ROLE_FILTER_QS_KEY);
    } else {
      QSToAdd[ROLE_FILTER_QS_KEY] = newRoleFilterValue;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={rolesOptions}
      placeholder={t('companyUsersListRoleFilter.allRoles')}
      value={roleFilterValue}
      onChange={handleRoleFilterChange}
      disabled={isPending}
      popupMatchSelectWidth={false}
    />
  );
};

CompanyUsersListRoleFilter.propTypes = {
  className: PropTypes.string,
};

CompanyUsersListRoleFilter.defaultProps = {
  className: '',
};

export default CompanyUsersListRoleFilter;
