import _keyBy from 'lodash/keyBy';

import { buildSortOptionKey } from '@palette/helpers/CommonHelper';

export const FEATURES = {
  CHALLENGE: 'challenge',
  COMPENSATION: 'compensation',
  STATEMENT_VALIDATION: 'statementValidation',
  STATEMENT_VALIDATION_NOTIFICATIONS: 'statementValidationNotifications',
  DIGEST_NOTIFICATIONS: 'digestNotifications',
  NOTEBOOKS: 'notebooks',
  PLANV2: 'planV2',
  PLANV3: 'planV3',
};

export const FEATURES_I18N = {
  [FEATURES.CHALLENGE]: 'companyFeatures.challenge',
  [FEATURES.COMPENSATION]: 'companyFeatures.compensation',
  [FEATURES.STATEMENT_VALIDATION]: 'companyFeatures.statementValidation',
  [FEATURES.STATEMENT_VALIDATION_NOTIFICATIONS]: 'companyFeatures.statementValidationNotifications',
  [FEATURES.DIGEST_NOTIFICATIONS]: 'companyFeatures.digestNotifications',
  [FEATURES.NOTEBOOKS]: 'companyFeatures.notebooks',
  [FEATURES.PLANV2]: 'companyFeatures.planV2',
  [FEATURES.PLANV3]: 'companyFeatures.planV3',
};

export const SEARCHED_COMPANY_QS_KEY = 'searchedCompany';

export const IS_LIVE_QS_KEY = 'isLive';

export const HAS_STRIPE_SUBSCRIPTION_QS_KEY = 'hasStripeSubscription';

export const HAS_TRIAL_END_DATE_QS_KEY = 'hasTrialEndDate';

export const IS_ARCHIVED_QS_KEY = 'isArchived';

export const SORT_NAME_QS_KEY = 'sortName';

export const SORT_CREATED_DATE_QS_KEY = 'sortCreatedDate';

export const COMPANIES_LIST_FILTERS_KEYS = {
  IS_LIVE: 'IS_LIVE',
  IS_NOT_LIVE: 'IS_NOT_LIVE',
  HAS_STRIPE_SUBSCRIPTION: 'HAS_STRIPE_SUBSCRIPTION',
  HAS_NOT_STRIPE_SUBSCRIPTION: 'HAS_NOT_STRIPE_SUBSCRIPTION',
  HAS_TRIAL_END_DATE: 'HAS_TRIAL_END_DATE',
  HAS_NOT_TRIAL_END_DATE: 'HAS_NOT_TRIAL_END_DATE',
  IS_ARCHIVED: 'IS_ARCHIVED',
  IS_NOT_ARCHIVED: 'IS_NOT_ARCHIVED',
};

export const COMPANIES_LIST_FILTERS = [
  {
    key: COMPANIES_LIST_FILTERS_KEYS.IS_LIVE,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.isLive',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.IS_NOT_LIVE,
    qsKey: IS_LIVE_QS_KEY,
    qsKeyValue: 'true',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.IS_NOT_LIVE,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.isNotLive',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.IS_LIVE,
    qsKey: IS_LIVE_QS_KEY,
    qsKeyValue: 'false',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.HAS_STRIPE_SUBSCRIPTION,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.hasStripeSubscription',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_STRIPE_SUBSCRIPTION,
    qsKey: HAS_STRIPE_SUBSCRIPTION_QS_KEY,
    qsKeyValue: 'true',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_STRIPE_SUBSCRIPTION,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.hasNotStripeSubscription',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.HAS_STRIPE_SUBSCRIPTION,
    qsKey: HAS_STRIPE_SUBSCRIPTION_QS_KEY,
    qsKeyValue: 'false',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.HAS_TRIAL_END_DATE,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.hasTrialEndDate',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_TRIAL_END_DATE,
    qsKey: HAS_TRIAL_END_DATE_QS_KEY,
    qsKeyValue: 'true',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_TRIAL_END_DATE,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.hasNotTrialEndDate',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.HAS_TRIAL_END_DATE,
    qsKey: HAS_TRIAL_END_DATE_QS_KEY,
    qsKeyValue: 'false',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.IS_ARCHIVED,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.isArchived',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.IS_NOT_ARCHIVED,
    qsKey: IS_ARCHIVED_QS_KEY,
    qsKeyValue: 'true',
  },
  {
    key: COMPANIES_LIST_FILTERS_KEYS.IS_NOT_ARCHIVED,
    filterNameI18NId: 'companiesListWrapper.filters.tags.labels.isNotArchived',
    oppositeFilterKey: COMPANIES_LIST_FILTERS_KEYS.IS_ARCHIVED,
    qsKey: IS_ARCHIVED_QS_KEY,
    qsKeyValue: 'false',
  },
];

export const COMPANIES_LIST_FILTERS_BY_KEY = _keyBy(COMPANIES_LIST_FILTERS, 'key');

export const COMPANIES_LIST_SORT_OPTIONS_KEYS = {
  CREATED_DATE_ASC: 'CREATED_DATE_ASC',
  CREATED_DATE_DESC: 'CREATED_DATE_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
};

export const COMPANIES_LIST_SORT_OPTIONS = [
  {
    key: COMPANIES_LIST_SORT_OPTIONS_KEYS.NAME_ASC,
    sortOptionNameI18NId: 'companiesListWrapper.sort.options.labels.nameAsc',
    qsKey: SORT_NAME_QS_KEY,
    qsKeyValue: 'asc',
  },
  {
    key: COMPANIES_LIST_SORT_OPTIONS_KEYS.NAME_DESC,
    sortOptionNameI18NId: 'companiesListWrapper.sort.options.labels.nameDesc',
    qsKey: SORT_NAME_QS_KEY,
    qsKeyValue: 'desc',
  },
  {
    key: COMPANIES_LIST_SORT_OPTIONS_KEYS.CREATED_DATE_DESC,
    sortOptionNameI18NId: 'companiesListWrapper.sort.options.labels.createdDateDesc',
    qsKey: SORT_CREATED_DATE_QS_KEY,
    qsKeyValue: 'desc',
  },
  {
    key: COMPANIES_LIST_SORT_OPTIONS_KEYS.CREATED_DATE_ASC,
    sortOptionNameI18NId: 'companiesListWrapper.sort.options.labels.createdDateAsc',
    qsKey: SORT_CREATED_DATE_QS_KEY,
    qsKeyValue: 'asc',
  },
];

export const COMPANIES_LIST_SORT_OPTIONS_BY_KEY = _keyBy(COMPANIES_LIST_SORT_OPTIONS, (sortOption) => buildSortOptionKey(sortOption.qsKey, sortOption.qsKeyValue));

export const DEFAULT_SYNC_INTERVAL = 15;

export const SYNC_INTERVAL_MINUTES_OPTIONS = [DEFAULT_SYNC_INTERVAL, 30, 45];

export const SYNC_INTERVAL_HOURS_OPTIONS = Array.from({ length: 24 }, (_, index) => index + 1).map((hour) => ({
  label: hour,
  value: hour * 60,
}));
