import React, { useCallback } from 'react';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Menu from '@palette/components/navigation/Menu/Menu';
import Image from '@palette/components/designSystem/Image/Image';
import Button from '@palette/components/designSystem/Button/Button';
import ForwardLine from '@palette/components/utils/Icons/ForwardLine';

import { APP_TOP_ID } from '@palette/constants/navigation';

import { actions as ProfileActions } from '@palette/state/Profile';

import styles from './RestrictedPageWrapper.less';

const classNames = bindClassNames.bind(styles);

const RestrictedPageWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(ProfileActions.logout());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div id={APP_TOP_ID} />
      <div className={styles.header}>
        <Image src="/img/Logo_Palette.png" height={32} />
        <h2 className={styles.headerItem}>
          {t('restrictedPageWrapper.header.title')}
        </h2>
        <Menu
          className={classNames({
            headerItem: true,
            menu: true,
          })}
        />
        <Button
          className={styles.logoutBtn}
          type="linkDestroy"
          flattenLink
          icon={(<ForwardLine width={16} height={16} />)}
          onClick={handleLogout}
        >
          {t('restrictedPageWrapper.header.logout')}
        </Button>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default RestrictedPageWrapper;
