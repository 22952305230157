import { getI18n } from '@palette/i18n';

export const deduplicateFilterFn = (element, index, array) => (array.indexOf(element) === index);

export const comaSeparatorFormatter = (value) => (
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
);

export const comaSeparatorParser = (value) => {
  const parsedValue = `${value}`.replace(/(,)/g, '');
  if (parsedValue === '') return '0';
  return parsedValue;
};

export const capitalize = (string) => string
  .toLowerCase()
  .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());

export const formatNumberShortDisplay = (value) => {
  const i18n = getI18n();

  const parsedValue = parseFloat(value);

  const formatter = new Intl.NumberFormat(i18n.language, {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
  return formatter.format(parsedValue);
};

export const floatToFixedNumber = (value, fractionDigits = 2) => {
  let parsedValue = parseFloat(parseFloat(value).toFixed(fractionDigits));
  if (parsedValue && parsedValue % 1 === 0) {
    parsedValue = parseInt(parsedValue, 10);
  }

  return parsedValue;
};

export const extractDateFromMongoId = (mongoId) => (
  new Date(parseInt(mongoId.substring(0, 8), 16) * 1000)
);
export const buildSortOptionKey = (sortKey, sortKeyValue) => (`${sortKey}_${sortKeyValue}`);
