import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import UpdateSupportOwner from '@palette/components/companyDetails/UpdateSupportOwner/UpdateSupportOwner';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsGlobalSupportOwner.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalSupportOwner = ({ className, company }) => {
  const { t } = useTranslation();

  const canUpdateCompany = useAccessToCompanyDetails(company.id);

  const supportOwnerNode = useMemo(() => {
    let ownerNode = (
      <div className={styles.supportOwner}>
        {t('companyDetailsSettingsGlobalSupportOwner.undefined')}
      </div>
    );

    if (company.supportOwner !== null) {
      ownerNode = (
        <div className={styles.supportOwner}>
          {company.supportOwner.email}
        </div>
      );
    }

    return (
      <div className={styles.supportOwnerWrapper}>
        <Tooltip title={t('companyDetailsSettingsGlobalSupportOwner.supportOwner')}>
          <div className={styles.supportOwnerIconWrapper}>
            <ProfileFilled className={styles.supportOwnerIcon} />
            <img className={styles.supportOwnerPaletteLogo} src="/img/logo-small.png" alt="Palette Logo" />
          </div>
        </Tooltip>
        {ownerNode}
      </div>
    );
  }, [company, canUpdateCompany]);

  const updateSupportOwnerNode = useMemo(() => {
    if (!canUpdateCompany) return null;

    return (
      <UpdateSupportOwner className={styles.updateSupportOwner} />
    );
  }, [canUpdateCompany]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {supportOwnerNode}
      {updateSupportOwnerNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalSupportOwner.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalSupportOwner.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalSupportOwner;
