import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsGlobalLiveStatus.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalLiveStatus = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateCompany = useAccessToCompanyDetails(company.id);

  const turnCompanyLiveIsPending = useSelector(CompaniesSelectors.turnCompanyLiveIsPending);
  const offboardCompanyIsPending = useSelector(CompaniesSelectors.offboardCompanyIsPending);

  const disableActions = useMemo(() => (
    turnCompanyLiveIsPending
    || offboardCompanyIsPending
  ), [turnCompanyLiveIsPending, offboardCompanyIsPending]);

  const liveStatusIconNode = useMemo(() => {
    if (company.live) {
      return (
        <CheckFilled
          className={styles.liveIcon}
          width={20}
          height={20}
        />
      );
    }

    return (
      <ClosePopupFilled
        className={styles.notLiveIcon}
        width={20}
        height={20}
      />
    );
  }, [company]);

  const liveStatusLabelNode = useMemo(() => (
    <div className={styles.liveStatusWrapper}>
      {liveStatusIconNode}
      <div
        className={classNames({
          liveStatusLabel: true,
          isLive: company.live,
        })}
      >
        {company.live ? t('companyDetailsSettingsGlobalLiveStatus.isLive') : t('companyDetailsSettingsGlobalLiveStatus.isNotLive')}
      </div>
    </div>
  ), [
    liveStatusIconNode,
    company,
  ]);

  const handleOffboardCompany = useCallback(() => {
    dispatch(CompaniesActions.offboardCompany({ companyId: company.id }));
  }, [company]);

  const handleTurnCompanyLive = useCallback(() => {
    dispatch(CompaniesActions.turnCompanyLive({ companyId: company.id }));
  }, [company]);

  const updateLiveStatusNode = useMemo(() => {
    if (!canUpdateCompany) return null;

    if (company.live) {
      return (
        <Popconfirm
          title={t('companyDetailsSettingsGlobalLiveStatus.offboardIt.popconfirmTitle')}
          onConfirm={handleOffboardCompany}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
          disabled={disableActions}
        >
          <Button
            type="linkDestroy"
            flattenLink
            disabled={disableActions}
          >
            {t('companyDetailsSettingsGlobalLiveStatus.offboardIt.label')}
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Popconfirm
        title={t('companyDetailsSettingsGlobalLiveStatus.turnItLive.popconfirmTitle')}
        onConfirm={handleTurnCompanyLive}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={disableActions}
      >
        <Button
          type="link"
          flattenLink
          disabled={disableActions}
        >
          {t('companyDetailsSettingsGlobalLiveStatus.turnItLive.label')}
        </Button>
      </Popconfirm>
    );
  }, [
    canUpdateCompany,
    company,
    disableActions,
    handleOffboardCompany,
    handleTurnCompanyLive,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {liveStatusLabelNode}
      {updateLiveStatusNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalLiveStatus.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalLiveStatus.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalLiveStatus;
