import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Image from '@palette/components/designSystem/Image/Image';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Link from '@palette/components/designSystem/Link/Link';
import LockFilled from '@palette/components/utils/Icons/LockFilled';
import UnlockFilled from '@palette/components/utils/Icons/UnlockFilled';
import CompanyInfosCreatedAt from '@palette/components/companyDetails/CompanyInfosCreatedAt/CompanyInfosCreatedAt';
import CompanyInfosTrialEndDate from '@palette/components/companyDetails/CompanyInfosTrialEndDate/CompanyInfosTrialEndDate';
import CompanyInfosSeats from '@palette/components/companyDetails/CompanyInfosSeats/CompanyInfosSeats';
import CompanyStatuses from '@palette/components/companyDetails/CompanyStatuses/CompanyStatuses';
import CompanyCommissionsRelatedSettings from '@palette/components/companyDetails/CompanyCommissionsRelatedSettings/CompanyCommissionsRelatedSettings';
import CompanyUsedResources from '@palette/components/companyDetails/CompanyUsedResources/CompanyUsedResources';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import routePaths from '@palette/config/routePaths';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompaniesListItem.less';

const classNames = bindClassNames.bind(styles);

const CompaniesListItem = ({ className, company }) => {
  const { t } = useTranslation();

  const canAccessToCompanyDetails = useAccessToCompanyDetails(company.id);

  const globalInfosNode = useMemo(() => (
    <div className={styles.globalInfosWrapper}>
      <div className={styles.nameDatesWrapper}>
        <div className={styles.nameWrapper}>
          <div className={styles.logoWrapper}>
            <Image className={styles.logo} src={company.logo !== '' ? company.logo : '/img/handshake.png'} />
          </div>
          <div className={styles.name}>
            {company.name}
          </div>
        </div>
        <CompanyInfosCreatedAt className={styles.createdAt} company={company} />
        <CompanyInfosTrialEndDate className={styles.trialEndDate} company={company} />
      </div>
      <CompanyInfosSeats className={styles.seats} company={company} />
      <CompanyStatuses className={styles.statuses} company={company} />
    </div>
  ), [company]);

  const detailsNode = useMemo(() => (
    <div className={styles.detailsWrapper}>
      <CompanyCommissionsRelatedSettings company={company} />
      <CompanyUsedResources company={company} />
    </div>
  ), [company]);

  const accessIconNode = useMemo(() => {
    if (canAccessToCompanyDetails) {
      return (
        <Tooltip title={t('companiesListItem.canAccessToCompanyDetails')}>
          <div className={styles.accessIcon}>
            <UnlockFilled className={styles.unlock} />
          </div>
        </Tooltip>
      );
    }

    let canNotAccessToCompanyDetailsTooltip = t('companiesListItem.canNotAccessToCompanyDetails');
    if (company.supportOwner !== null) {
      canNotAccessToCompanyDetailsTooltip = `${canNotAccessToCompanyDetailsTooltip} ${t('companiesListItem.contactSupportOwner', { supportOwner: company.supportOwner.email })}`;
    }

    return (
      <Tooltip title={canNotAccessToCompanyDetailsTooltip}>
        <div className={styles.accessIcon}>
          <LockFilled className={styles.lock} />
        </div>
      </Tooltip>
    );
  }, [canAccessToCompanyDetails, company]);

  const contentNode = useMemo(() => (
    <>
      {globalInfosNode}
      {detailsNode}
      {accessIconNode}
    </>
  ), [globalInfosNode, detailsNode, accessIconNode]);

  if (canAccessToCompanyDetails) {
    return (
      <Link
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        path={routePaths.companyDetails}
        params={{ companyId: company.id }}
      >
        {contentNode}
      </Link>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompaniesListItem.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompaniesListItem.defaultProps = {
  className: '',
};

export default CompaniesListItem;
