import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { setSagaMiddleware } from '@palette/helpers/SagasHelper';

import reducers from '@palette/state/reducers';
import { rootSaga } from '@palette/state/sagas';

export const getStore = (sagaMiddleware = createSagaMiddleware()) => {
  const middlewares = [
    sagaMiddleware,
  ];

  const store = configureStore({
    reducer: reducers,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production',
  });

  setSagaMiddleware(sagaMiddleware);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default getStore;
