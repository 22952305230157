import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Button as AntDButton } from 'antd';

import styles from './Button.less';

const classNames = bindClassNames.bind(styles);

const Button = ({
  className,
  type,
  pressed,
  disabled,
  flattenLink,
  thin,
  children,
  ...otherProps
}) => (
  <AntDButton
    className={classNames({
      wrapper: true,
      disabled,
      primaryBlue: type === 'primaryBlue',
      secondary: type === 'secondary',
      link: type === 'link',
      linkSecondary: type === 'linkSecondary',
      linkSecondaryBlack: type === 'linkSecondaryBlack',
      linkDestroy: type === 'linkDestroy',
      flat: type === 'flat',
      flatFill: type === 'flatFill',
      flatFillBlue: type === 'flatFillBlue',
      flatFillBlueOutlined: type === 'flatFillBlueOutlined',
      danger: type === 'danger',
      pressed,
      flattenLink: (
        flattenLink && (
          type === 'link'
          || type === 'linkSecondaryBlack'
          || type === 'linkDestroy'
        )
      ),
      thin: (
        thin && (
          type === 'primary'
          || type === 'primaryBlue'
          || type === 'secondary'
          || type === 'danger'
        )
      ),
      [className]: className !== '',
    })}
    type={type.startsWith('link') ? 'link' : 'default'}
    disabled={disabled}
    {...otherProps}
  >
    {children}
  </AntDButton>
);

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'primaryBlue',
    'secondary',
    'link',
    'linkSecondary',
    'linkSecondaryBlack',
    'linkDestroy',
    'flat',
    'flatFill',
    'flatFillBlue',
    'flatFillBlueOutlined',
    'danger',
  ]),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  pressed: PropTypes.bool,
  flattenLink: PropTypes.bool,
  thin: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'primary',
  children: null,
  disabled: false,
  pressed: false,
  flattenLink: false,
  thin: false,
};

export default Button;
