import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { useImpersonateActionIsAvailable } from '@palette/hooks/UserHooks';
import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { IMPERSONATE_MODES } from '@palette/constants/user';
import { RIGHTS } from '@palette/constants/role';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as CompanyUsersActions } from '@palette/state/CompanyUsers';

import styles from './UsersListItemActionsMoreMenu.less';

const classNames = bindClassNames.bind(styles);

const UsersListItemActionsMoreMenu = ({ className, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canImpersonateInDevMode = useProfileRights([RIGHTS.ADMIN_LOCAL_CONNECT_AS]);

  const impersonateActionIsAvailable = useImpersonateActionIsAvailable(user);

  const moreMenuItems = useMemo(() => {
    const menuItems = [];

    if (canImpersonateInDevMode && impersonateActionIsAvailable) {
      const impersonateDevModeMenuItem = {
        key: 'impersonateDevMode',
        title: t('usersListItemActionsMoreMenu.impersonateDevMode'),
        icon: (
          <EyesLine width={16} height={16} />
        ),
        onClick: () => {
          dispatch(CompanyUsersActions.impersonate({ userId: user.id, mode: IMPERSONATE_MODES.LOCALHOST }));
        },
      };

      menuItems.push(impersonateDevModeMenuItem);
    }

    return menuItems;
  }, [
    canImpersonateInDevMode,
    impersonateActionIsAvailable,
    user,
  ]);

  if (!canImpersonateInDevMode) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
      />
    </div>
  );
};

UsersListItemActionsMoreMenu.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UsersListItemActionsMoreMenu.defaultProps = {
  className: '',
};

export default UsersListItemActionsMoreMenu;
