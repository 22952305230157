import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import UserAvatar from '@palette/components/companyUsers/UserAvatar/UserAvatar';
import Button from '@palette/components/designSystem/Button/Button';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import LinkFilled from '@palette/components/utils/Icons/LinkFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import UsersListItemActions from '@palette/components/companyUsers/UsersListItemActions/UsersListItemActions';

import { getUserStatus } from '@palette/helpers/UserHelper';
import { getDayjs } from '@palette/helpers/DayjsHelper';

import { KNOWN_ROLES_TAG_TYPES, STATUSES } from '@palette/constants/user';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as CompanyUsersActions } from '@palette/state/CompanyUsers';

import styles from './UsersListItem.less';

const classNames = bindClassNames.bind(styles);

const UsersListItem = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dayJS = getDayjs();

  const userStatus = useMemo(() => (
    getUserStatus(user)
  ), [user]);

  const handleCopyInvitationLinkToClipboard = useCallback(() => {
    dispatch(CompanyUsersActions.copyInvitationLinkToClipboard({ user }));
  }, [user]);

  const statusNode = useMemo(() => {
    if (userStatus === STATUSES.OFFBOARDED) {
      const now = dayJS.utc();
      const userLeaveDateMoment = dayJS.utc(user.leaveDate);

      let deactivatedLabel = t('usersListItem.userStatus.willBeDeactivated', { date: userLeaveDateMoment.format('LL') });
      if (userLeaveDateMoment.isSameOrBefore(now)) {
        deactivatedLabel = t('usersListItem.userStatus.wasDeactivated', { date: userLeaveDateMoment.format('LL') });
      }

      return (
        <Tooltip title={deactivatedLabel}>
          <div>
            <Tag className={styles.statusTag} type="danger">
              <div className={styles.statusWrapper}>
                <ClosePopupFilled />
                <div className={styles.statusLabel}>
                  {t('usersListItem.userStatus.offboarded')}
                </div>
              </div>
            </Tag>
          </div>
        </Tooltip>
      );
    }

    if (userStatus === STATUSES.NOT_INVITED) {
      return (
        <Tag className={styles.statusTag} type="default">
          <div className={styles.statusWrapper}>
            <InfoCircleFilled />
            <div className={styles.statusLabel}>
              {t('usersListItem.noAccount')}
            </div>
          </div>
        </Tag>
      );
    }

    if (userStatus === STATUSES.PENDING) {
      return (
        <Tooltip title={t('usersListItem.userStatus.pending.hint')}>
          <div onClick={handleCopyInvitationLinkToClipboard}>
            <Tag
              className={classNames({
                statusTag: true,
                clickableTag: true,
              })}
              type="warning"
            >
              <div className={styles.statusWrapper}>
                <LinkFilled />
                <div className={styles.statusLabel}>
                  {t('usersListItem.userStatus.pending')}
                </div>
              </div>
            </Tag>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tag className={styles.statusTag} type="success">
        <div className={styles.statusWrapper}>
          <CheckFilled />
          <div className={styles.statusLabel}>
            {t('usersListItem.userStatus.connected')}
          </div>
        </div>
      </Tag>
    );
  }, [
    userStatus,
    user,
    handleCopyInvitationLinkToClipboard,
  ]);

  const roleNode = useMemo(() => {
    if (userStatus === STATUSES.OFFBOARDED || userStatus === STATUSES.NOT_INVITED) return null;

    return (
      <Tag type={KNOWN_ROLES_TAG_TYPES[user.account.role.name] || 'skyblue'}>
        {user.account.role.name}
      </Tag>
    );
  }, [userStatus, user]);

  const handleCopyEmailToClipboard = useCallback(() => {
    dispatch(CompanyUsersActions.copyEmailToClipboard({ user }));
  }, [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.displayNameWrapper}>
          <UserAvatar user={user} />
          <div className={styles.displayName}>
            {user.displayName}
          </div>
        </div>
        <div className={styles.roleStatusWrapper}>
          {roleNode}
          {statusNode}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.emailWrapper}>
          <div className={styles.email}>
            {user.email}
          </div>
          <Tooltip title={t('usersListItem.copyEmailToClipboard')}>
            <Button
              className={styles.copyEmailToClipboardBtn}
              type="link"
              flattenLink
              icon={(<DuplicateFilled width={14} height={14} />)}
              onClick={handleCopyEmailToClipboard}
            />
          </Tooltip>
        </div>
        <UsersListItemActions className={styles.actionsWrapper} user={user} />
      </div>
    </div>
  );
};

UsersListItem.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UsersListItem.defaultProps = {
  className: '',
};

export default UsersListItem;
