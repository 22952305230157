import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Tag as AntTag } from 'antd';

import styles from './Tag.less';

const classNames = bindClassNames.bind(styles);

const Tag = ({ className, type, color, children }) => (
  <AntTag
    className={classNames({
      wrapper: true,
      success: type === 'success',
      warning: type === 'warning',
      info: type === 'info',
      skyblue: type === 'skyblue',
      danger: type === 'danger',
      [className]: className !== '',
    })}
    color={color}
  >
    {children}
  </AntTag>
);

Tag.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'success', 'warning', 'info', 'skyblue', 'danger']),
  color: PropTypes.string,
  children: PropTypes.any,
};

Tag.defaultProps = {
  className: '',
  type: 'default',
  color: null,
  children: null,
};

export default Tag;
