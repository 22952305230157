import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import CompanyCollectionsList from '@palette/components/companyCollections/CompanyCollectionsList/CompanyCollectionsList';

import { COMPANY_COLLECTIONS_DETAILS_TABS_QS_KEY, COMPANY_COLLECTIONS_DETAILS_TABS_IDS } from '@palette/constants/tabs';

import * as MongoCollectionsStatsModel from '@palette/models/MongoCollectionsStats';

import styles from './CompanyCollectionsDetailsTabs.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsDetailsTabs = ({ className, mongoCollectionsStats }) => {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: COMPANY_COLLECTIONS_DETAILS_TABS_IDS.COMPANY,
      title: t('companyCollectionsDetailsTabs.tabs.company'),
      content: (<CompanyCollectionsList collectionsDetailsList={mongoCollectionsStats.companyCollectionsDetails} />),
      defaultActive: true,
    });

    finalTabs.push({
      tabKey: COMPANY_COLLECTIONS_DETAILS_TABS_IDS.PALETTE,
      title: t('companyCollectionsDetailsTabs.tabs.palette'),
      content: (<CompanyCollectionsList collectionsDetailsList={mongoCollectionsStats.paletteCollectionsDetails} />),
    });

    return finalTabs;
  }, [mongoCollectionsStats]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={COMPANY_COLLECTIONS_DETAILS_TABS_QS_KEY}
      />
    </div>
  );
};

CompanyCollectionsDetailsTabs.propTypes = {
  className: PropTypes.string,
  mongoCollectionsStats: MongoCollectionsStatsModel.propTypes.isRequired,
};

CompanyCollectionsDetailsTabs.defaultProps = {
  className: '',
};

export default CompanyCollectionsDetailsTabs;
