import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './ForgotPasswordForm.less';

const classNames = bindClassNames.bind(styles);

const ForgotPasswordForm = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const forgotPasswordIsPending = useSelector(ProfileSelectors.forgotPasswordIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    email: '',
  };

  const handleFinish = useCallback(({ email }) => {
    dispatch(ProfileActions.forgotPassword({ email }));
  }, []);

  const handleForgotPassword = useCallback(() => form.submit(), [form]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="email"
          label={t('forgotPasswordForm.form.email.label')}
          required
          rules={[
            { required: true, message: t('forgotPasswordForm.form.email.rules.required') },
          ]}
        >
          <Input size="big" type="email" placeholder={t('forgotPasswordForm.form.email.placeholder')} disabled={forgotPasswordIsPending} />
        </FormItem>
        <Button className={styles.submitBtn} onClick={handleForgotPassword} loading={forgotPasswordIsPending}>
          {t('forgotPasswordForm.form.submit')}
        </Button>
      </Form>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  className: '',
};

export default ForgotPasswordForm;
