import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import styles from './CompanyDetailsSettingsGlobalDocumentationUrl.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalDocumentationUrl = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateCompany = useAccessToCompanyDetails(company.id);

  const updateDocumentationUrlIsPending = useSelector(CompaniesSelectors.updateDocumentationUrlIsPending);

  const [form] = AntDForm.useForm();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const onUpdateDocumentationUrlSuccess = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form]);

  const updateDocumentationUrl = useCallback(() => {
    if (!canUpdateCompany) return;

    const { documentationUrl } = form.getFieldsValue(true);

    const finalDocumentationUrl = documentationUrl === '' ? null : documentationUrl;

    dispatch(CompaniesActions.updateDocumentationUrl({ companyId: company.id, documentationUrl: finalDocumentationUrl, onSuccessCB: onUpdateDocumentationUrlSuccess }));
  }, [canUpdateCompany, company, form, onUpdateDocumentationUrlSuccess]);

  const documentationUrlNode = useMemo(() => {
    let urlNode = (
      <div className={styles.noDocumentationUrlSet}>
        {t('companyDetailsSettingsGlobalDocumentationUrl.noDocumentationUrlSet')}
      </div>
    );
    if (company.documentationUrl !== null) {
      urlNode = (
        <ExternalLink
          className={styles.companyDocumentationLink}
          displayIcon
          href={company.documentationUrl}
        >
          {t('companyDetailsSettingsGlobalDocumentationUrl.companyDocumentation')}
        </ExternalLink>
      );
    }

    return (
      <div className={styles.documentationUrlWrapper}>
        <FolderOpenFilled className={styles.documentationUrlIcon} />
        {urlNode}
      </div>
    );
  }, [
    company,
  ]);

  const displayModeNode = useMemo(() => {
    if (!canUpdateCompany) {
      return (
        <div className={styles.displayModeWrapper}>
          {documentationUrlNode}
        </div>
      );
    }

    return (
      <div className={styles.displayModeWrapper}>
        {documentationUrlNode}
        <Button
          type="link"
          flattenLink
          icon={<PenFilled width={18} height={18} />}
          onClick={() => setEditModeEnabled(true)}
          disabled={updateDocumentationUrlIsPending}
        />
      </div>
    );
  }, [
    canUpdateCompany,
    documentationUrlNode,
    setEditModeEnabled,
    updateDocumentationUrlIsPending,
    company,
  ]);

  const initialValues = useMemo(() => ({
    documentationUrl: company.documentationUrl,
  }), [company]);

  useEffect(() => {
    if (editModeEnabled) {
      form.resetFields();
    }
  }, [initialValues, editModeEnabled]);

  const disableEditMode = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form, setEditModeEnabled]);

  const editModeNode = useMemo(() => (
    <div className={styles.editModeWrapper}>
      <Form initialValues={initialValues} form={form}>
        <FormItem
          name="documentationUrl"
          label={t('companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.label')}
          rules={[
            { type: 'url', message: t('companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.rules.urlType') },
          ]}
        >
          <Input placeholder={t('companyDetailsSettingsGlobalDocumentationUrl.form.documentationUrl.placeholder')} disabled={updateDocumentationUrlIsPending} />
        </FormItem>
        <div className={styles.actions}>
          <Button className={styles.updateDocumentationUrlBtn} onClick={updateDocumentationUrl} disabled={updateDocumentationUrlIsPending}>
            {t('common.global.edit')}
          </Button>
          <Button
            type="linkSecondary"
            onClick={disableEditMode}
            disabled={updateDocumentationUrlIsPending}
          >
            {t('common.global.cancel')}
          </Button>
        </div>
      </Form>
    </div>
  ), [
    form,
    initialValues,
    updateDocumentationUrl,
    updateDocumentationUrlIsPending,
    disableEditMode,
  ]);

  const contentNode = useMemo(() => {
    if (editModeEnabled) {
      return editModeNode;
    }

    return displayModeNode;
  }, [
    editModeEnabled,
    displayModeNode,
    editModeNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalDocumentationUrl.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalDocumentationUrl.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalDocumentationUrl;
