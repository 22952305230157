import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TimezoneFilled from '@palette/components/utils/Icons/TimezoneFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import Tag from '@palette/components/designSystem/Tag/Tag';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';

import { TIME_OFFSETS } from '@palette/constants/timezone';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyCommissionsRelatedSettings.less';

const classNames = bindClassNames.bind(styles);

const CompanyCommissionsRelatedSettings = ({ className, company }) => {
  const { t } = useTranslation();

  const timezoneNode = useMemo(() => {
    if (company.timezone === null) return null;

    if (TIME_OFFSETS[company.timezone]) {
      return t('companiesListItem.timezone.timeOffsetLabel', {
        timeOffsetLabel: TIME_OFFSETS[company.timezone].label,
      });
    }

    return t('companiesListItem.timezone.default_value', { timezone: company.timezone });
  }, [company]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tooltip title={timezoneNode === null ? t('companiesListItem.timezone.no_default_value') : timezoneNode}>
        <div className={styles.timezoneWrapper}>
          <TimezoneFilled className={styles.timezoneIcon} width={20} height={20} />
          <div className={styles.timezone}>
            {timezoneNode !== null ? timezoneNode : '-'}
          </div>
        </div>
      </Tooltip>
      <Tooltip title={t('companiesListItem.statementStrategy', { strategy: t(`statementStrategy.propertyTypes.${company.statementStrategy.type}`) })}>
        <div className={styles.statementStrategyWrapper}>
          <StatementsFilled className={styles.statementStrategyIcon} width={20} height={20} />
          <div className={styles.statementStrategy}>
            {t(`statementStrategy.propertyTypes.${company.statementStrategy.type}`)}
          </div>
        </div>
      </Tooltip>
      <Tooltip
        title={t('companiesListItem.currencies')}
        content={(
          <div className={styles.currenciesList}>
            {
              company.currencies.map((currency) => (
                <Tag
                  key={currency.code}
                  className={styles.currency}
                  type="skyblue"
                >
                  {`${currency.code}${currency.isDefault ? ` (${t('companiesListItem.currency.isDefault')})` : ''}`}
                </Tag>
              ))
            }
          </div>
        )}
      >
        <div className={styles.currenciesWrapper}>
          <DollarFilled className={styles.currenciesIcon} width={20} height={20} />
          <div className={styles.currencies}>
            {t('companiesListItem.currencies', { default: company.currency, count: company.currencies.length })}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

CompanyCommissionsRelatedSettings.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyCommissionsRelatedSettings.defaultProps = {
  className: '',
};

export default CompanyCommissionsRelatedSettings;
