import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { actions as CompanyCollectionsActions } from '@palette/state/CompanyCollections/slice';
import { manageError as manageCompanyCollectionsError } from '@palette/state/CompanyCollections/errors';

import { showErrorNotification } from '@palette/helpers/SagasHelper';

import * as MongoCollectionsStatsModel from '@palette/models/MongoCollectionsStats';

import * as CompanyCollectionsService from '@palette/services/CompanyCollectionsService';

export function* getCompanyCollectionsDetails({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompanyCollectionsService.getCompanyCollectionsDetails, callData);

  if (callResult.ok) {
    const collectionsDetails = MongoCollectionsStatsModel.transform(callResult.data);

    yield put(CompanyCollectionsActions.setCollectionsDetails({ companyId, collectionsDetails }));
  } else {
    const error = manageCompanyCollectionsError(callResult);
    showErrorNotification(error);
  }

  yield put(CompanyCollectionsActions.getCompanyCollectionsDetailsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CompanyCollectionsActions.getCompanyCollectionsDetails.type, getCompanyCollectionsDetails),
  ]);
}

export function* clean() {
  yield put(CompanyCollectionsActions.resetToInitialState());
}
