import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';
import { RIGHTS } from '@palette/constants/role';

export function* getCompanyCollectionsDetails(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyCollections.getCollectionsDetails, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW],
  );
}
