import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompanyDetailsSettingsGlobal from '@palette/components/companyDetails/CompanyDetailsSettingsGlobal/CompanyDetailsSettingsGlobal';
import CompanyDetailsSettingsFeatures from '@palette/components/companyDetails/CompanyDetailsSettingsFeatures/CompanyDetailsSettingsFeatures';
import CompanyDetailsSettingsConnectors from '@palette/components/companyDetails/CompanyDetailsSettingsConnectors/CompanyDetailsSettingsConnectors';
import CompanyDetailsSettingsBilling from '@palette/components/companyDetails/CompanyDetailsSettingsBilling/CompanyDetailsSettingsBilling';
import CompanyDetailsSettingsFreeTrial from '@palette/components/companyDetails/CompanyDetailsSettingsFreeTrial/CompanyDetailsSettingsFreeTrial';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettings.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettings = ({ className, company }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <CompanyDetailsSettingsGlobal company={company} />
    <CompanyDetailsSettingsFeatures company={company} />
    <CompanyDetailsSettingsConnectors company={company} />
    <CompanyDetailsSettingsBilling company={company} />
    <CompanyDetailsSettingsFreeTrial company={company} />
  </div>
);

CompanyDetailsSettings.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettings.defaultProps = {
  className: '',
};

export default CompanyDetailsSettings;
