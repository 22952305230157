import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Image from '@palette/components/designSystem/Image/Image';
import BoxFilled from '@palette/components/utils/Icons/BoxFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyStatuses.less';

const classNames = bindClassNames.bind(styles);

const CompanyStatuses = ({ className, company }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        company.stripeSubscriptionId !== null && (
          <Tooltip title={t('companiesListItem.hasStripeSubscription')}>
            <div>
              <Image
                className={styles.stripeIcon}
                src="/img/StripeLogo.png"
              />
            </div>
          </Tooltip>
        )
      }
      <Tooltip title={company.archived ? t('companiesListItem.isArchived') : t('companiesListItem.isNotArchived')}>
        <div>
          <BoxFilled
            className={classNames({
              archivedIcon: true,
              isArchived: company.archived,
            })}
            width={20}
            height={20}
          />
        </div>
      </Tooltip>
      <Tooltip title={company.live ? t('companiesListItem.isLive') : t('companiesListItem.isNotLive')}>
        <div>
          {
            company.live && (
              <CheckFilled
                className={styles.liveIcon}
                width={20}
                height={20}
              />
            )
          }
          {
            !company.live && (
              <ClosePopupFilled
                className={styles.notLiveIcon}
                width={20}
                height={20}
              />
            )
          }
        </div>
      </Tooltip>
    </div>
  );
};

CompanyStatuses.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyStatuses.defaultProps = {
  className: '',
};

export default CompanyStatuses;
