import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import EditCompanyModal from '@palette/components/companyDetails/EditCompanyModal/EditCompanyModal';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import styles from './EditCompany.less';

const classNames = bindClassNames.bind(styles);

const EditCompany = ({ className, company }) => {
  const { t } = useTranslation();
  const [editCompanyModalIsVisible, showEditCompanyModal] = useState(false);

  const canEditCompany = useAccessToCompanyDetails(company.id);

  if (!canEditCompany) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button type="secondary" onClick={() => showEditCompanyModal(true)}>
        {t('editCompany.edit')}
      </Button>
      {
        editCompanyModalIsVisible && (
          <EditCompanyModal visible onClose={() => showEditCompanyModal(false)} company={company} />
        )
      }
    </div>
  );
};

EditCompany.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

EditCompany.defaultProps = {
  className: '',
};

export default EditCompany;
