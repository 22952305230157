import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import styles from './JobListItemJsonModal.less';

const JobListItemJsonModal = ({ visible, onClose, modalTitle, jsonString }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.modal}
      title={modalTitle ?? t('jobListItemJsonModal.title')}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
    >
      <div className={styles.jsonWrapper}>
        <pre>
          {JSON.stringify(JSON.parse(jsonString), null, 4)}
        </pre>
      </div>
    </Modal>
  );
};

JobListItemJsonModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  modalTitle: PropTypes.string,
  jsonString: PropTypes.string.isRequired,
};

JobListItemJsonModal.defaultProps = {
  visible: false,
  onClose: () => {},
  modalTitle: null,
};

export default JobListItemJsonModal;
