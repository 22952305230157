import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CompaniesListItem from '@palette/components/companies/CompaniesListItem/CompaniesListItem';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompaniesList.less';

const classNames = bindClassNames.bind(styles);

const CompaniesList = ({ className, companies }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => {
    if (companies === null) return null;

    if (companies.length === 0) {
      return (
        <DefaultEmptyState description={t('companiesList.empty.description')} />
      );
    }

    return companies.map((company) => (
      <CompaniesListItem key={company.id} className={styles.company} company={company} />
    ));
  }, [
    companies,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompaniesList.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.arrayOf(CompanyModel.propTypes),
};

CompaniesList.defaultProps = {
  className: '',
  companies: null,
};

export default CompaniesList;
