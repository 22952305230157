import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import styles from './ErrorPage.less';

const classNames = bindClassNames.bind(styles);

const ErrorPage = ({ className }) => {
  const error = useRouteError();
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <h1>{t('errorPage.title')}</h1>
      <img className={styles.notFoundIllu} src="/img/no-results-found.png" alt={t('errorPage.notFoundIllu.alt')} />
      <div className={styles.details}>
        {error.statusText || error.message}
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  className: PropTypes.string,
};

ErrorPage.defaultProps = {
  className: '',
};

export default ErrorPage;
