import _keyBy from 'lodash/keyBy';

import { buildSortOptionKey } from '@palette/helpers/CommonHelper';

export const KNOWN_ROLES = {
  ADMIN: 'Admin',
  EDITING_MANAGER: 'Editing Manager',
  MANAGER: 'Manager',
  REPRESENTATIVE: 'Representative',
};

export const KNOWN_ROLES_TAG_TYPES = {
  [KNOWN_ROLES.ADMIN]: 'danger',
  [KNOWN_ROLES.EDITING_MANAGER]: 'info',
  [KNOWN_ROLES.MANAGER]: 'info',
  [KNOWN_ROLES.REPRESENTATIVE]: 'success',
};

export const STATUSES = {
  OFFBOARDED: 'OFFBOARDED',
  NOT_INVITED: 'NOT_INVITED',
  PENDING: 'PENDING',
  CONNECTED: 'CONNECTED',
};

export const IMPERSONATE_MODES = {
  PRODUCTION: 'PRODUCTION',
  LOCALHOST: 'LOCALHOST',
};

export const SEARCHED_USER_QS_KEY = 'searchedUser';

export const SORT_LAST_NAME_QS_KEY = 'sortLastName';

export const COMPANY_USERS_LIST_SORT_OPTIONS_KEYS = {
  LAST_NAME_ASC: 'LAST_NAME_ASC',
  LAST_NAME_DESC: 'LAST_NAME_DESC',
};

export const COMPANY_USERS_LIST_SORT_OPTIONS = [
  {
    key: COMPANY_USERS_LIST_SORT_OPTIONS_KEYS.LAST_NAME_ASC,
    sortOptionNameI18NId: 'companyUsersListWrapper.sort.options.labels.lastNameAsc',
    qsKey: SORT_LAST_NAME_QS_KEY,
    qsKeyValue: 'asc',
  },
  {
    key: COMPANY_USERS_LIST_SORT_OPTIONS_KEYS.LAST_NAME_DESC,
    sortOptionNameI18NId: 'companyUsersListWrapper.sort.options.labels.lastNameDesc',
    qsKey: SORT_LAST_NAME_QS_KEY,
    qsKeyValue: 'desc',
  },
];

export const COMPANY_USERS_LIST_SORT_OPTIONS_BY_KEY = _keyBy(
  COMPANY_USERS_LIST_SORT_OPTIONS,
  (sortOption) => buildSortOptionKey(sortOption.qsKey, sortOption.qsKeyValue),
);

export const ROLE_FILTER_QS_KEY = 'roleFilter';

export const STATUS_FILTER_QS_KEY = 'statusFilter';
