import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CompanyJobStatsList from '@palette/components/companyJobs/CompanyJobStatsList/CompanyJobStatsList';
import JobsList from '@palette/components/companyJobs/JobsList/JobsList';

import * as JobsDetailsModel from '@palette/models/JobsDetails';

import styles from './CompanyJobsDetails.less';

const classNames = bindClassNames.bind(styles);

const CompanyJobsDetails = ({ className, jobsDetails }) => {
  const { t } = useTranslation();

  const statsListNode = useMemo(() => (
    <CompanyJobStatsList statsList={jobsDetails.stats} />
  ), [jobsDetails]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.statsWrapper}>
        <div className={styles.statsTitle}>
          {t('companyJobsDetails.statsTitle')}
        </div>
        {statsListNode}
      </div>
      <JobsList className={styles.jobsList} jobs={jobsDetails.jobs} />
    </div>
  );
};

CompanyJobsDetails.propTypes = {
  className: PropTypes.string,
  jobsDetails: JobsDetailsModel.propTypes.isRequired,
};

CompanyJobsDetails.defaultProps = {
  className: '',
};

export default CompanyJobsDetails;
