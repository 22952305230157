import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import JobsListItem from '@palette/components/companyJobs/JobsListItem/JobsListItem';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';

import { scrollToTop } from '@palette/helpers/NavigationHelper';

import { LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import { actions as NavigationActions } from '@palette/state/Navigation';

import * as AsyncJobModel from '@palette/models/AsyncJob';
import * as PaginationModel from '@palette/models/Pagination';

import styles from './JobsList.less';

const classNames = bindClassNames.bind(styles);

const JobsList = ({ className, jobs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [jobsListLimit] = useLimitInSearch();
  const [jobsListPage] = usePageInSearch();

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
    scrollToTop();
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    scrollToTop();
  }, []);

  const paginationNode = useMemo(() => {
    if (jobs.length === 0) return null;

    const jobsListPagination = PaginationModel.transform({
      total: jobs.length,
      limit: jobsListLimit,
      page: jobsListPage,
    });

    return (
      <Pagination
        className={styles.pagination}
        pagination={jobsListPagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    );
  }, [
    jobs,
    jobsListLimit,
    jobsListPage,
    handlePageChange,
    handleLimitChange,
  ]);

  const filteredJobsList = useMemo(() => (
    jobs.slice(jobsListPage * jobsListLimit, (jobsListPage + 1) * jobsListLimit)
  ), [
    jobs,
    jobsListLimit,
    jobsListPage,
  ]);

  const contentNode = useMemo(() => {
    if (jobs.length === 0) {
      return (
        <DefaultEmptyState description={t('jobsList.empty.description')} />
      );
    }

    return filteredJobsList.map((job) => (
      <JobsListItem key={job.id} className={styles.job} job={job} />
    ));
  }, [
    jobs,
    filteredJobsList,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {paginationNode}
    </div>
  );
};

JobsList.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.arrayOf(AsyncJobModel.propTypes).isRequired,
};

JobsList.defaultProps = {
  className: '',
};

export default JobsList;
