import React from 'react';

import CompanyFilled from '@palette/components/utils/Icons/CompanyFilled';
import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';

import { hasAtLeastOneRight } from '@palette/helpers/RoleHelper';

import routePaths from '@palette/config/routePaths';

import { RIGHTS } from '@palette/constants/role';

const menuItems = [
  {
    key: 'companies',
    show: () => true,
    path: routePaths.companies,
    icon: (<CompanyFilled />),
    i18nId: 'menu.companies',
    selectedOnPaths: [
      routePaths.companyDetails,
    ],
  },
  {
    key: 'apiLogs',
    show: (profile) => (hasAtLeastOneRight(profile, [RIGHTS.ADMIN_API_LOGS_VIEW])),
    path: routePaths.apiLogs,
    icon: (<SpeedometerFilled />),
    i18nId: 'menu.apiLogs',
    selectedOnPaths: [],
  },
];

export default menuItems;
