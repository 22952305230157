import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';
import { RIGHTS } from '@palette/constants/role';

export function* getCompanyJobsDetails(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyJobs.getJobsDetails, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW],
  );
}

export function* getAsyncJobById(payload) {
  const {
    companyId,
    jobId,
  } = payload;

  const data = {
    companyId,
    jobId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyJobs.getAsyncJobById, data, getConfig()),
    [RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW],
  );
}
