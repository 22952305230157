import React from 'react';

import RestrictedPageWrapper from '@palette/pages/shared/RestrictedPageWrapper/RestrictedPageWrapper';
import CompaniesPage from '@palette/pages/restricted/CompaniesPage/CompaniesPage';
import CompanyDetailsPage from '@palette/pages/restricted/CompanyDetailsPage/CompanyDetailsPage';
import ApiLogsPage from '@palette/pages/restricted/ApiLogsPage/ApiLogsPage';

import routePaths from '@palette/config/routePaths';

export const restrictedRoutes = [
  {
    element: (
      <RestrictedPageWrapper />
    ),
    children: [
      {
        path: routePaths.companies,
        element: (
          <CompaniesPage />
        ),
      },
      {
        path: routePaths.companyDetails,
        element: (
          <CompanyDetailsPage />
        ),
      },
      {
        path: routePaths.apiLogs,
        element: (
          <ApiLogsPage />
        ),
      },
    ],
  },
];

export default restrictedRoutes;
