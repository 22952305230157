import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Switch as AntDSwitch } from 'antd';

import styles from './Switch.less';

const classNames = bindClassNames.bind(styles);

const Switch = ({ className, value, checked, ...otherProps }) => {
  let finalChecked = checked;

  if (value !== null) {
    finalChecked = value;
  }

  return (
    <AntDSwitch
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      checked={finalChecked}
      {...otherProps}
    />
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
};

Switch.defaultProps = {
  className: '',
  value: null,
  checked: false,
};

export default Switch;
