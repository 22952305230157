import {
  call,
  all,
  takeLatest,
} from 'redux-saga/effects';

import { deleteCookie } from '@palette/helpers/CookiesHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

import { entities } from '@palette/state/entities';

export function* cleanAll() {
  const entitiesCleans = entities.map((entity) => entity.clean());
  yield all(entitiesCleans);
}

export function* forceLogout() {
  if (window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance) {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2) auth2.signOut();
  }

  localStorage.clear();
  sessionStorage.clear();
  deleteCookie('sessionId');

  yield call(cleanAll);

  redirectTo({ path: routePaths.login });
}

export function* loop() {
  yield all([
    takeLatest(ExceptionsActions.forceLogout.type, forceLogout),
  ]);
}
