/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _findIndex from 'lodash/findIndex';

/*
 * Initial State
 */
const initialState = {
  getCompanyJobsDetailsIsPending: false,

  jobsDetailsByCompanyId: {},
  autoSyncIsPendingById: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'companyJobs',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Company Jobs Details */
    getCompanyJobsDetails: (state) => {
      state.getCompanyJobsDetailsIsPending = true;
    },
    setJobsDetailsList: (state, { payload }) => {
      const { jobsDetailsList } = payload;

      state.jobsDetailsByCompanyId = _mergeWith(
        _cloneDeep(original(state.jobsDetailsByCompanyId)),
        _keyBy(jobsDetailsList, (jobDetails) => (jobDetails.company.id)),
      );
    },
    getCompanyJobsDetailsCompleted: (state) => {
      state.getCompanyJobsDetailsIsPending = false;
    },
    /* Copy Job's Id to Clipboard */
    copyJobIdToClipboard: () => {},
    /* Auto Sync Job Status */
    autoSync: (state, { payload }) => {
      const { job } = payload;
      state.autoSyncIsPendingById = _mergeWith(
        _cloneDeep(original(state.autoSyncIsPendingById)),
        {
          [job.id]: true,
        },
      );
    },
    updateJob: (state, { payload }) => {
      const { job } = payload;
      const clonedJobsDetailsByCompanyId = _cloneDeep(original(state.jobsDetailsByCompanyId));

      const companyJobsDetails = clonedJobsDetailsByCompanyId[job.companyId];

      const jobIndex = _findIndex(companyJobsDetails.jobs, (companyJob) => (companyJob.id === job.id));
      const newJobs = companyJobsDetails.jobs;
      newJobs.splice(jobIndex, 1, job);

      state.jobsDetailsByCompanyId = _mergeWith(
        clonedJobsDetailsByCompanyId,
        {
          [job.companyId]: {
            ...companyJobsDetails,
            jobs: newJobs,
          },
        },
      );
    },
    autoSyncCompleted: (state, { payload }) => {
      const { job } = payload;
      state.autoSyncIsPendingById = _mergeWith(
        _cloneDeep(original(state.autoSyncIsPendingById)),
        {
          [job.id]: false,
        },
      );
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const rawJobsDetailsByCompanyId = (state) => root(state).jobsDetailsByCompanyId;
const rawAutoSyncIsPendingById = (state) => root(state).autoSyncIsPendingById;

const getCompanyJobsDetailsIsPending = (state) => root(state).getCompanyJobsDetailsIsPending;

const autoSyncIsPending = createSelector(
  [rawAutoSyncIsPendingById, getProps],
  (autoSyncIsPendingById, { jobId }) => (
    autoSyncIsPendingById[jobId] || false
  ),
);

const getCompanyJobsDetails = createSelector(
  [rawJobsDetailsByCompanyId, getProps],
  (jobsDetailsByCompanyId, { companyId }) => (
    jobsDetailsByCompanyId[companyId] || null
  ),
);

export const selectors = {
  getCompanyJobsDetailsIsPending,
  autoSyncIsPending,

  getCompanyJobsDetails,
};
