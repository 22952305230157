import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as PaletteAdminModel from '@palette/models/PaletteAdmin';

import styles from './CompanyDetailsSettingsGlobalPaletteAdminItem.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalPaletteAdminItem = ({ className, paletteAdmin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { companyId, canAccessToCompanyDetails } = useCompanyInParams();

  const removePaletteAdminIsPending = useSelector(CompaniesSelectors.removePaletteAdminIsPending);

  const removePaletteAdmin = useCallback(() => {
    dispatch(CompaniesActions.removePaletteAdmin({ companyId, paletteAdminId: paletteAdmin.id }));
  }, [paletteAdmin, companyId]);

  const removePaletteAdminNode = useMemo(() => {
    if (!canAccessToCompanyDetails) return null;

    return (
      <Popconfirm
        title={t('companyDetailsSettingsGlobalPaletteAdminItem.removePaletteAdmin.popconfirmTitle')}
        onConfirm={removePaletteAdmin}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={removePaletteAdminIsPending}
      >
        <Button
          className={styles.removeBtn}
          type="linkDestroy"
          flattenLink
          disabled={removePaletteAdminIsPending}
          icon={(<TrashFilled />)}
        >
          {t('companyDetailsSettingsGlobalPaletteAdminItem.removePaletteAdmin.label')}
        </Button>
      </Popconfirm>
    );
  }, [
    canAccessToCompanyDetails,
    removePaletteAdminIsPending,
    removePaletteAdmin,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.email}>
        {paletteAdmin.email}
      </div>
      {removePaletteAdminNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalPaletteAdminItem.propTypes = {
  className: PropTypes.string,
  paletteAdmin: PaletteAdminModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalPaletteAdminItem.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalPaletteAdminItem;
