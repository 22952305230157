import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import CompanyDetailsUsers from '@palette/components/companyDetails/CompanyDetailsUsers/CompanyDetailsUsers';
import CompanyDetailsJobs from '@palette/components/companyDetails/CompanyDetailsJobs/CompanyDetailsJobs';
import CompanyDetailsCollections from '@palette/components/companyDetails/CompanyDetailsCollections/CompanyDetailsCollections';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { COMPANY_DETAILS_TABS_IDS, COMPANY_DETAILS_TABS_QS_KEY } from '@palette/constants/tabs';
import { RIGHTS } from '@palette/constants/role';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsTabs.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsTabs = ({ className, company }) => {
  const { t } = useTranslation();

  const canViewJobsDetails = useProfileRights([RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW]);
  const canViewCollectionsDetails = useProfileRights([RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW]);

  const usersNode = useMemo(() => (
    <CompanyDetailsUsers company={company} />
  ), [company]);

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: COMPANY_DETAILS_TABS_IDS.USERS,
      title: t('companyDetailsTabs.tabs.users'),
      content: usersNode,
      defaultActive: true,
    });

    if (canViewJobsDetails) {
      finalTabs.push({
        tabKey: COMPANY_DETAILS_TABS_IDS.JOBS,
        title: t('companyDetailsTabs.tabs.jobs'),
        content: (<CompanyDetailsJobs company={company} />),
      });
    }

    if (canViewCollectionsDetails) {
      finalTabs.push({
        tabKey: COMPANY_DETAILS_TABS_IDS.COLLECTIONS,
        title: t('companyDetailsTabs.tabs.collections'),
        content: (<CompanyDetailsCollections company={company} />),
      });
    }

    return finalTabs;
  }, [usersNode, canViewJobsDetails, canViewCollectionsDetails, company]);

  // While there are no more tabs, remove "tabs display" for only one tab
  const contentNode = useMemo(() => {
    if (!canViewJobsDetails && !canViewCollectionsDetails) return usersNode;

    return (
      <Tabs
        tabs={tabs}
        qsTabKey={COMPANY_DETAILS_TABS_QS_KEY}
      />
    );
  }, [canViewJobsDetails, canViewCollectionsDetails, usersNode, tabs]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompanyDetailsTabs.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsTabs.defaultProps = {
  className: '',
};

export default CompanyDetailsTabs;
