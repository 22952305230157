/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
  getProfileIsPending: false,
  loginIsPending: false,
  resetPasswordIsPending: false,
  forgotPasswordIsPending: false,

  profile: null,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Profile */
    getProfile: (state) => {
      state.getProfileIsPending = true;
    },
    setProfile: (state, { payload }) => {
      const { profile = null } = payload;
      state.profile = profile;
    },
    getProfileCompleted: (state) => {
      state.getProfileIsPending = false;
    },
    /* Login */
    login: (state) => {
      state.loginIsPending = true;
    },
    loginCompleted: (state) => {
      state.loginIsPending = false;
    },
    /* Reset Password */
    resetPassword: (state) => {
      state.resetPasswordIsPending = true;
    },
    resetPasswordCompleted: (state) => {
      state.resetPasswordIsPending = false;
    },
    /* Forgot Password */
    forgotPassword: (state) => {
      state.forgotPasswordIsPending = true;
    },
    forgotPasswordCompleted: (state) => {
      state.forgotPasswordIsPending = false;
    },
    /* Logout */
    logout: () => {},
    /* Connect As (Google Auth) */
    connectAs: () => {},
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const profile = (state) => root(state).profile;

const getProfileIsPending = (state) => root(state).getProfileIsPending;
const loginIsPending = (state) => root(state).loginIsPending;
const resetPasswordIsPending = (state) => root(state).resetPasswordIsPending;
const forgotPasswordIsPending = (state) => root(state).forgotPasswordIsPending;

export const selectors = {
  getProfileIsPending,
  loginIsPending,
  resetPasswordIsPending,
  forgotPasswordIsPending,

  profile,
};
