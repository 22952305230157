import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'MongoCollectionsDetails';

export const propTypes = PropTypes.shape({
  collectionName: PropTypes.string,
  size: PropTypes.number,
  storageSize: PropTypes.number,
  avgObjSize: PropTypes.number,
  count: PropTypes.number,
});

export const transform = (mongoCollectionsDetails) => {
  if (!mongoCollectionsDetails) {
    return null;
  }

  return (
    {
      collectionName: manageStringAttribute(mongoCollectionsDetails, 'name'),
      size: manageNumberAttribute(mongoCollectionsDetails, 'size'),
      storageSize: manageNumberAttribute(mongoCollectionsDetails, 'storageSize'),
      avgObjSize: manageNumberAttribute(mongoCollectionsDetails, 'avgObjSize'),
      count: manageNumberAttribute(mongoCollectionsDetails, 'count'),
    }
  );
};

export const transformList = (mongoCollectionsDetailsList) => mongoCollectionsDetailsList.map((mongoCollectionsDetails) => transform(mongoCollectionsDetails));
