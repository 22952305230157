import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import { actions as PaletteAdminsActions, selectors as PaletteAdminsSelectors } from '@palette/state/PaletteAdmins';

import styles from './PaletteAdminSelect.less';

const classNames = bindClassNames.bind(styles);

const PaletteAdminSelect = ({ className, disabled, filterIfAlreadyInCompany, ...otherProps }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { company } = useCompanyInParams();

  const getPaletteAdminsIsPending = useSelector(PaletteAdminsSelectors.getPaletteAdminsIsPending);
  const paletteAdminsList = useSelector(PaletteAdminsSelectors.getPaletteAdminsList);

  useEffect(() => {
    dispatch(PaletteAdminsActions.getPaletteAdmins());
  }, []);

  const filteredPaletteAdminsList = useMemo(() => {
    if (company === null || !filterIfAlreadyInCompany) return paletteAdminsList;

    const companyPaletteAdminsIds = company.paletteAdmins.map((paletteAdmin) => (paletteAdmin.id));

    return paletteAdminsList.filter((paletteAdmin) => (!companyPaletteAdminsIds.includes(paletteAdmin.id)));
  }, [
    company,
    filterIfAlreadyInCompany,
    paletteAdminsList,
  ]);

  const paletteAdminsOptions = useMemo(() => (
    filteredPaletteAdminsList.map((paletteAdmin) => ({
      label: paletteAdmin.email,
      value: paletteAdmin.id,
    }))
  ), [filteredPaletteAdminsList]);

  if (!getPaletteAdminsIsPending && paletteAdminsOptions.length === 0) {
    let emptyStateDescription = t('paletteAdminSelect.empty.description');
    if (filterIfAlreadyInCompany) {
      emptyStateDescription = t('paletteAdminSelect.emptyFiltered.description');
    }

    return (
      <DefaultEmptyState
        description={emptyStateDescription}
      />
    );
  }

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={paletteAdminsOptions}
      showSearch
      disabled={disabled || getPaletteAdminsIsPending}
      {...otherProps}
    />
  );
};

PaletteAdminSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  filterIfAlreadyInCompany: PropTypes.bool,
};

PaletteAdminSelect.defaultProps = {
  className: '',
  disabled: false,
  filterIfAlreadyInCompany: false,
};

export default PaletteAdminSelect;
