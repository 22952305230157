import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompanyInfosSeats from '@palette/components/companyDetails/CompanyInfosSeats/CompanyInfosSeats';
import CompanyStatuses from '@palette/components/companyDetails/CompanyStatuses/CompanyStatuses';
import CompanyCommissionsRelatedSettings from '@palette/components/companyDetails/CompanyCommissionsRelatedSettings/CompanyCommissionsRelatedSettings';
import CompanyUsedResources from '@palette/components/companyDetails/CompanyUsedResources/CompanyUsedResources';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyInANutshell.less';

const classNames = bindClassNames.bind(styles);

const CompanyInANutshell = ({ className, company }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.globalInfosWrapper}>
      <CompanyInfosSeats company={company} />
      <CompanyStatuses company={company} />
    </div>
    <div className={styles.detailsWrapper}>
      <CompanyCommissionsRelatedSettings company={company} />
      <CompanyUsedResources company={company} />
    </div>
  </div>
);

CompanyInANutshell.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyInANutshell.defaultProps = {
  className: '',
};

export default CompanyInANutshell;
