import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import CompanyDetailsSettingsBlock from '@palette/components/companyDetails/CompanyDetailsSettingsBlock/CompanyDetailsSettingsBlock';
import CompanyInfosTrialEndDate from '@palette/components/companyDetails/CompanyInfosTrialEndDate/CompanyInfosTrialEndDate';
import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { getDayjs } from '@palette/helpers/DayjsHelper';

import { RIGHTS } from '@palette/constants/role';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsFreeTrial.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsFreeTrial = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dayJS = getDayjs();

  const canUpdateTrial = useProfileRights([RIGHTS.ADMIN_COMPANIES_TRIAL_UPDATE]);

  const updateTrialSettingsIsPending = useSelector(CompaniesSelectors.updateTrialSettingsIsPending);

  const [form] = AntDForm.useForm();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const onUpdateTrialSettingsSuccess = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form]);

  const updateTrialSettings = useCallback(() => {
    if (!canUpdateTrial) return;

    const { trialEndDate, trialPaymentUrl } = form.getFieldsValue(true);

    const finalTrialEndDate = dayJS(trialEndDate).utc().endOf('day').format();
    const finalTrialPaymentUrl = trialPaymentUrl === '' ? null : trialPaymentUrl;

    dispatch(CompaniesActions.updateTrialSettings({ companyId: company.id, trialEndDate: finalTrialEndDate, trialPaymentUrl: finalTrialPaymentUrl, onSuccessCB: onUpdateTrialSettingsSuccess }));
  }, [canUpdateTrial, company, form, onUpdateTrialSettingsSuccess]);

  const handleDeleteTrialSettings = useCallback(() => {
    if (!canUpdateTrial) return;

    dispatch(CompaniesActions.updateTrialSettings({ companyId: company.id, trialEndDate: null, trialPaymentUrl: null, onSuccessCB: onUpdateTrialSettingsSuccess }));
  }, [canUpdateTrial, company, form, onUpdateTrialSettingsSuccess]);

  const trialDataNode = useMemo(() => {
    if (company.trialEndDate === null && company.trialPaymentUrl === null) {
      return (
        <div className={styles.noTrialSet}>
          {t('companyDetailsSettingsFreeTrial.noTrialSet')}
        </div>
      );
    }

    let trialPaymentUrlNode = (
      <div className={styles.noTrialPaymentUrlSet}>
        {t('companyDetailsSettingsFreeTrial.noTrialPaymentUrlSet')}
      </div>
    );
    if (company.trialPaymentUrl !== null) {
      trialPaymentUrlNode = (
        <>
          <div className={styles.trialPaymentUrlLabel}>
            {t('companyDetailsSettingsFreeTrial.trialPaymentUrlLabel')}
          </div>
          <div className={styles.trialPaymentUrl}>
            {company.trialPaymentUrl}
          </div>
        </>
      );
    }

    return (
      <div className={styles.trialDataWrapper}>
        <CompanyInfosTrialEndDate company={company} displayTooltip={false} />
        <div className={styles.trialPaymentUrlWrapper}>
          {trialPaymentUrlNode}
        </div>
      </div>
    );
  }, [
    company,
  ]);

  const displayModeNode = useMemo(() => {
    if (!canUpdateTrial) {
      return (
        <div className={styles.displayModeWrapper}>
          {trialDataNode}
        </div>
      );
    }

    return (
      <div className={styles.displayModeWrapper}>
        {trialDataNode}
        <Button
          className={styles.enableEditModeButton}
          type="link"
          flattenLink
          icon={<PenFilled width={18} height={18} />}
          onClick={() => setEditModeEnabled(true)}
          disabled={updateTrialSettingsIsPending}
        >
          {t('companyDetailsSettingsFreeTrial.editTrialSettings')}
        </Button>
        {
          (company.trialEndDate !== null || company.trialPaymentUrl !== null) && (
            <Popconfirm
              title={t('companyDetailsSettingsFreeTrial.deleteTrialSettings.popconfirmTitle')}
              onConfirm={handleDeleteTrialSettings}
              okText={t('common.global.yes')}
              cancelText={t('common.global.no')}
              size="small"
              disabled={updateTrialSettingsIsPending}
            >
              <Button
                className={styles.deleteTrialSettingsBtn}
                type="linkDestroy"
                flattenLink
                icon={(<TrashFilled width={16} height={16} />)}
                disabled={updateTrialSettingsIsPending}
              >
                {t('companyDetailsSettingsFreeTrial.deleteTrialSettings.buttonLabel')}
              </Button>
            </Popconfirm>
          )
        }
      </div>
    );
  }, [
    canUpdateTrial,
    trialDataNode,
    setEditModeEnabled,
    updateTrialSettingsIsPending,
    company,
    handleDeleteTrialSettings,
  ]);

  const initialValues = useMemo(() => ({
    trialEndDate: company.trialEndDate !== null ? dayJS.utc(company.trialEndDate) : null,
    trialPaymentUrl: company.trialPaymentUrl,
  }), [company]);

  useEffect(() => {
    if (editModeEnabled) {
      form.resetFields();
    }
  }, [initialValues, editModeEnabled]);

  const disableEditMode = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form, setEditModeEnabled]);

  const editModeNode = useMemo(() => (
    <div className={styles.editModeWrapper}>
      <Form initialValues={initialValues} form={form}>
        <FormItem
          name="trialEndDate"
          label={t('companyDetailsSettingsFreeTrial.form.trialEndDate.label')}
          required
        >
          <DatePicker
            className={styles.trialEndDateDatePicker}
            picker="date"
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            disabled={updateTrialSettingsIsPending}
          />
        </FormItem>
        <FormItem
          name="trialPaymentUrl"
          label={t('companyDetailsSettingsFreeTrial.form.trialPaymentUrl.label')}
          rules={[
            { type: 'url', message: t('companyDetailsSettingsFreeTrial.form.trialPaymentUrl.rules.urlType') },
          ]}
        >
          <Input placeholder={t('companyDetailsSettingsFreeTrial.form.trialPaymentUrl.placeholder')} disabled={updateTrialSettingsIsPending} />
        </FormItem>
        <div className={styles.actions}>
          <Button className={styles.updateTrialSettingsBtn} onClick={updateTrialSettings} disabled={updateTrialSettingsIsPending}>
            {t('common.global.edit')}
          </Button>
          <Button
            type="linkSecondary"
            onClick={disableEditMode}
            disabled={updateTrialSettingsIsPending}
          >
            {t('common.global.cancel')}
          </Button>
        </div>
      </Form>
    </div>
  ), [
    form,
    initialValues,
    updateTrialSettings,
    updateTrialSettingsIsPending,
    disableEditMode,
  ]);

  const contentNode = useMemo(() => {
    if (editModeEnabled) {
      return editModeNode;
    }

    return displayModeNode;
  }, [
    editModeEnabled,
    displayModeNode,
    editModeNode,
  ]);

  return (
    <CompanyDetailsSettingsBlock
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyDetailsSettingsFreeTrial.title')}
      content={contentNode}
    />
  );
};

CompanyDetailsSettingsFreeTrial.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsFreeTrial.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsFreeTrial;
