import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { Menu as AntDMenu } from 'antd';

import { useSelectedMenuKeys, useShowedMenuItems } from '@palette/hooks/MenuHooks';

import { getQSStringified } from '@palette/helpers/MenuHelper';

import styles from './Menu.less';

const classNames = bindClassNames.bind(styles);

const Menu = ({ className }) => {
  const { t } = useTranslation();

  const showedMenuItems = useShowedMenuItems();
  const selectedMenuItemKeys = useSelectedMenuKeys();

  const antdMenuItems = useMemo(() => (
    showedMenuItems.map((menuItem) => ({
      key: menuItem.key,
      icon: menuItem.icon,
      label: (
        <Link
          to={{
            pathname: menuItem.path,
            search: getQSStringified(menuItem.pathQS),
          }}
        >
          {t(menuItem.i18nId)}
        </Link>
      ),
    }))
  ), [showedMenuItems]);

  return (
    <AntDMenu
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      mode="horizontal"
      selectedKeys={selectedMenuItemKeys}
      items={antdMenuItems}
    />
  );
};

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
