import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import TimezoneSelect from '@palette/components/utils/TimezoneSelect/TimezoneSelect';
import CurrencySelect from '@palette/components/utils/CurrencySelect/CurrencySelect';
import Select from '@palette/components/designSystem/Select/Select';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import PictureUploadInput from '@palette/components/utils/PictureUploadInput/PictureUploadInput';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getLocalTimezone } from '@palette/helpers/DayjsHelper';

import routePaths from '@palette/config/routePaths';

import { HALF_MONTH_PIVOTS, STATEMENT_STRATEGY_TYPES, WEEK_ROLL_TYPES } from '@palette/constants/statements';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import styles from './CreateCompanyModal.less';

const CreateCompanyModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createCompanyIsPending = useSelector(CompaniesSelectors.createCompanyIsPending);
  const checkSimilarCompanyNameIsPending = useSelector(CompaniesSelectors.checkSimilarCompanyNameIsPending);
  const similarCompanies = useSelector(CompaniesSelectors.getSimilarCompanies);

  const [form] = AntDForm.useForm();

  const typePropertyTypes = Object.keys(STATEMENT_STRATEGY_TYPES).map((type) => ({
    label: t(`statementStrategy.propertyTypes.${type}`),
    value: type,
  }));

  const rollPropertyTypes = Object.keys(WEEK_ROLL_TYPES).map((type) => ({
    label: t(`statementStrategy.weekBegins.${type}`),
    value: type,
  }));

  const breakPropertyTypes = Object.keys(HALF_MONTH_PIVOTS).map((type) => ({
    label: t('statementStrategy.monthBreaks.types', {
      break: t(`statementStrategy.monthBreaks.${type}`),
    }),
    value: type,
  }));

  const initialLogo = null;
  const initialName = undefined;
  const initialCurrency = 'USD';
  const initialTimezone = getLocalTimezone();
  const initialStrategyType = STATEMENT_STRATEGY_TYPES.MONTH;
  const initialRollType = WEEK_ROLL_TYPES.US;
  const initialBreakType = HALF_MONTH_PIVOTS.FIFTEENTH;

  const initialValues = {
    logo: initialLogo,
    name: initialName,
    currency: initialCurrency,
    timezone: initialTimezone,
    strategyType: initialStrategyType,
    rollType: initialRollType,
    breakType: initialBreakType,
  };

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const [currentStrategyType, setCurrentStrategyType] = useState(STATEMENT_STRATEGY_TYPES.MONTH);

  const checkSimilarCompanyName = useCallback(
    _debounce((newName) => {
      dispatch(CompaniesActions.checkSimilarCompanyName({ name: newName }));
    }, 700),
    [],
  );

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.name !== undefined) {
      checkSimilarCompanyName(changedValues.name);
    }

    if (changedValues.strategyType !== undefined) {
      setCurrentStrategyType(changedValues.strategyType);
    }
  }, [checkSimilarCompanyName, setCurrentStrategyType]);

  const onCompanyCreationSuccess = useCallback((companyId) => {
    redirectTo({ path: routePaths.companyDetails, params: { companyId } });
    cleanAndClose();
  }, [cleanAndClose]);

  const handleFinish = useCallback((values) => {
    dispatch(CompaniesActions.createCompany({ company: values, onSuccessCB: onCompanyCreationSuccess }));
  }, [onCompanyCreationSuccess]);

  const handleCreateCompany = () => form.submit();

  const similarCompaniesNode = useMemo(() => {
    let similarCompaniesContentNode = null;

    if (checkSimilarCompanyNameIsPending) {
      similarCompaniesContentNode = (
        <LoadingLine className={styles.similarCompaniesLoaderIcon} width={16} height={16} spin />
      );
    } else if (similarCompanies.length !== 0) {
      const similarCompaniesNames = similarCompanies.map((similarCompany) => similarCompany.name).join(', ');

      similarCompaniesContentNode = t('createCompanyModal.similarCompanies.label', { similarCompaniesNames });
    }

    return (
      <div className={styles.similarCompaniesWrapper}>
        {similarCompaniesContentNode}
      </div>
    );
  }, [checkSimilarCompanyNameIsPending, similarCompanies]);

  const periodNode = useMemo(() => {
    if (currentStrategyType === STATEMENT_STRATEGY_TYPES.MONTH) return null;

    if (currentStrategyType === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
      return (
        <FormItem
          name="breakType"
          label={t('createCompanyModal.form.breakType.label')}
          required
          rules={[
            { required: true, message: t('createCompanyModal.form.breakType.rules.required') },
          ]}
        >
          <Select
            size="big"
            placeholder={t('createCompanyModal.form.breakType.placeholder')}
            options={breakPropertyTypes}
          />
        </FormItem>
      );
    }

    return (
      <FormItem
        name="rollType"
        label={t('createCompanyModal.form.rollType.label')}
        required
        rules={[
          { required: true, message: t('createCompanyModal.form.rollType.rules.required') },
        ]}
      >
        <Select
          size="big"
          placeholder={t('createCompanyModal.form.rollType.placeholder')}
          options={rollPropertyTypes}
        />
      </FormItem>
    );
  }, [currentStrategyType]);

  return (
    <Modal
      className={styles.modal}
      title={t('createCompanyModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleCreateCompany}
      okText={t('createCompanyModal.createCompany')}
      loading={createCompanyIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          className={styles.logoFormItem}
          name="logo"
        >
          <PictureUploadInput placeholder={t('createCompanyModal.form.logo.placeholder')} disabled={createCompanyIsPending} />
        </FormItem>
        <div className={styles.nameFieldWrapper}>
          <FormItem
            className={styles.nameFormItem}
            name="name"
            label={t('createCompanyModal.form.name.label')}
            required
            rules={[
              { required: true, message: t('createCompanyModal.form.name.rules.required') },
            ]}
          >
            <Input size="big" placeholder={t('createCompanyModal.form.name.placeholder')} disabled={createCompanyIsPending} />
          </FormItem>
          {similarCompaniesNode}
        </div>
        <FormItem
          name="currency"
          label={t('createCompanyModal.form.currency.label')}
          required
        >
          <CurrencySelect
            size="big"
            disabled={createCompanyIsPending}
          />
        </FormItem>
        <FormItem
          name="timezone"
          label={t('createCompanyModal.form.timezone.label')}
          required
        >
          <TimezoneSelect size="big" disabled={createCompanyIsPending} />
        </FormItem>
        <FormItem
          name="strategyType"
          label={t('createCompanyModal.form.strategyType.label')}
          required
          rules={[
            { required: true, message: t('createCompanyModal.form.strategyType.rules.required') },
          ]}
        >
          <Select
            size="big"
            placeholder={t('createCompanyModal.form.strategyType.placeholder')}
            options={typePropertyTypes}
          />
        </FormItem>
        {periodNode}
      </Form>
    </Modal>
  );
};

CreateCompanyModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateCompanyModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CreateCompanyModal;
