import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Popover from '@palette/components/designSystem/Popover/Popover';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CompanyDetailsSettingsGlobalPaletteAdminItem from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalPaletteAdminItem/CompanyDetailsSettingsGlobalPaletteAdminItem';
import AddPaletteAdmin from '@palette/components/companyDetails/AddPaletteAdmin/AddPaletteAdmin';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsGlobalPaletteAdmins.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobalPaletteAdmins = ({ className, company }) => {
  const { t } = useTranslation();

  const canUpdateCompany = useAccessToCompanyDetails(company.id);

  const paletteAdminsListNode = useMemo(() => {
    const tagNode = (
      <Tag className={styles.paletteAdminsCountTag} type="skyblue">
        {t('companyDetailsSettingsGlobalPaletteAdmins.paletteAdmins', { count: company.paletteAdmins.length })}
      </Tag>
    );

    if (company.paletteAdmins.length === 0) {
      return tagNode;
    }

    const popoverContentNode = (
      <div className={styles.popoverContentWrapper}>
        {
          company.paletteAdmins.map((paletteAdmin) => (
            <CompanyDetailsSettingsGlobalPaletteAdminItem
              key={paletteAdmin.id}
              className={styles.paletteAdmin}
              paletteAdmin={paletteAdmin}
            />
          ))
        }
      </div>
    );

    return (
      <Popover
        trigger="hover"
        content={popoverContentNode}
      >
        <div>
          {tagNode}
        </div>
      </Popover>
    );
  }, [company, canUpdateCompany]);

  const addPaletteAdminNode = useMemo(() => {
    if (!canUpdateCompany) return null;

    return (
      <AddPaletteAdmin />
    );
  }, [canUpdateCompany]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {paletteAdminsListNode}
      {addPaletteAdminNode}
    </div>
  );
};

CompanyDetailsSettingsGlobalPaletteAdmins.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobalPaletteAdmins.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobalPaletteAdmins;
