// public pages: basePath + path
export const publicRoutePaths = {
  login: 'login',
  resetPassword: 'reset-password/:resetPasswordToken',
  forgotPassword: 'forgot-password',
  connectAs: 'connect-as/:adminSessionId',
};

// Restricted pages: basePath + path
export const restrictedRoutePaths = {
  companies: 'companies',
  companyDetails: 'companies/:companyId',
  apiLogs: 'logs',
};

const routePaths = {
  basePath: '/',

  // specific public page for maintenance mode
  maintenance: 'maintenance',

  // public route paths
  ...publicRoutePaths,

  // restricted route paths
  ...restrictedRoutePaths,
};

export default routePaths;
