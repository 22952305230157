import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import CompanyFilled from '@palette/components/utils/Icons/CompanyFilled';

import { useCompaniesLastUsedFiltersPathQS } from '@palette/hooks/CompanyHooks';

import routePaths from '@palette/config/routePaths';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const CompanyBreadcrumb = ({ className, company }) => {
  const { t } = useTranslation();

  const companiesLastUsedFiltersPathQS = useCompaniesLastUsedFiltersPathQS();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.companies}
          qsObject={companiesLastUsedFiltersPathQS}
        >
          <CompanyFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('menu.companies')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      <div className={styles.currentLocationWrapper}>
        <CompanyFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {company?.name}
        </div>
      </div>
    </div>
  );
};

CompanyBreadcrumb.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes,
};

CompanyBreadcrumb.defaultProps = {
  className: '',
  company: null,
};

export default CompanyBreadcrumb;
