import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { useProfile } from '@palette/hooks/ProfileHooks';

import menuItems from '@palette/config/menu';

import { useCompaniesLastUsedFiltersPathQS } from '@palette/hooks/CompanyHooks';

export const useShowedMenuItems = () => {
  const profile = useProfile();

  const companiesLastUsedFiltersPathQS = useCompaniesLastUsedFiltersPathQS();

  const showedMenuItems = useMemo(() => (
    menuItems.filter((item) => (item.show(profile) && !!item.path))
  ), [profile]);

  return useMemo(() => (
    showedMenuItems.map((menuItem) => {
      if (menuItem.key === 'companies') {
        return {
          ...menuItem,
          pathQS: companiesLastUsedFiltersPathQS,
        };
      }

      return menuItem;
    })
  ), [
    showedMenuItems,
    companiesLastUsedFiltersPathQS,
  ]);
};

export const useSelectedMenuKeys = () => {
  const showedMenuItems = useShowedMenuItems();

  return showedMenuItems.map((item) => {
    const pathMath = useMatch(item.path) !== null;
    const hasMatches = item.selectedOnPaths.some((path) => (useMatch(path) !== null));

    return (pathMath || hasMatches) ? item.key : null;
  }).filter(Boolean);
};
