import React from 'react';

import ConnectAsPage from '@palette/pages/public/ConnectAsPage/ConnectAsPage';
import ForgotPasswordPage from '@palette/pages/public/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '@palette/pages/public/LoginPage/LoginPage';
import MaintenancePage from '@palette/pages/public/MaintenancePage/MaintenancePage';
import ResetPasswordPage from '@palette/pages/public/ResetPasswordPage/ResetPasswordPage';

import routePaths from '@palette/config/routePaths';
import appConfig from '@palette/config/app';

export const publicRoutes = [
  {
    path: routePaths.connectAs,
    element: (
      <ConnectAsPage />
    ),
  },
  {
    path: routePaths.login,
    element: (
      <LoginPage />
    ),
  },
  {
    path: routePaths.maintenance,
    element: (
      <MaintenancePage />
    ),
  },
];

if (appConfig.ENABLE_PASSWORD_AUTH) {
  publicRoutes.push({
    path: routePaths.resetPassword,
    element: (
      <ResetPasswordPage />
    ),
  });

  publicRoutes.push({
    path: routePaths.forgotPassword,
    element: (
      <ForgotPasswordPage />
    ),
  });
}

export default publicRoutes;
