import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'Role';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  rights: PropTypes.objectOf(PropTypes.bool),
});

export const transform = (role) => {
  if (!role) {
    return null;
  }

  const rightsArray = manageArrayAttribute(role, 'rights', null);
  const rightsInRaw = rightsArray !== null;
  let rights = {};
  if (rightsInRaw) {
    rights = rightsArray.reduce((res, right) => {
      res[right] = true;
      return res;
    }, {});
  }

  return (
    {
      id: manageStringAttribute(role, '_id'),
      name: manageStringAttribute(role, 'name'),
      description: manageStringAttribute(role, 'description'),
      rights,
    }
  );
};

export const transformList = (roles) => roles.map((role) => transform(role));
