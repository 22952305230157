import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'Connector';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  displayName: PropTypes.string,
  autoSync: PropTypes.bool,
});

export const transform = (connector) => {
  if (!connector) {
    return null;
  }

  const type = manageStringAttribute(connector, 'type');
  const name = manageStringAttribute(connector, 'name', null);
  let displayName = type;
  if (name !== null) {
    displayName = `${displayName} (${name})`;
  }

  return (
    {
      id: manageStringAttribute(connector, '_id'),
      type,
      name,
      displayName,
      autoSync: manageBooleanAttribute(connector, 'autoSync'),
    }
  );
};

export const transformList = (connectors) => connectors.map((connector) => transform(connector));

export const merge = (connector1, connector2) => {
  if (!connector2) return connector1;
  if (!connector1) return connector2;

  return connector2;
};
