import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Alert as AntDAlert } from 'antd';

import { ALERT_ICONS, ALERT_TYPES } from '@palette/constants/alert';

import styles from './Alert.less';

const classNames = bindClassNames.bind(styles);

const Alert = ({ className, type, message, ...otherProps }) => {
  const AlertIcon = ALERT_ICONS[type];

  let alertType = type;
  if (type === ALERT_TYPES.NEUTRAL) {
    alertType = 'info';
  }

  return (
    <AntDAlert
      className={classNames({
        wrapper: true,
        neutral: type === ALERT_TYPES.NEUTRAL,
        [className]: className !== '',
      })}
      type={alertType}
      message={message}
      showIcon
      icon={<AlertIcon />}
      {...otherProps}
    />
  );
};

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  message: PropTypes.any.isRequired,
};

Alert.defaultProps = {
  className: '',
  type: 'info',
};

export default Alert;
