import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Image from '@palette/components/designSystem/Image/Image';
import GoogleAuthButton from '@palette/components/login/GoogleAuthButton/GoogleAuthButton';
import EmailPasswordForm from '@palette/components/login/EmailPasswordForm/EmailPasswordForm';
import Link from '@palette/components/designSystem/Link/Link';

import appConfig from '@palette/config/app';
import routePaths from '@palette/config/routePaths';

import styles from './LoginPageContent.less';

const classNames = bindClassNames.bind(styles);

const LoginPageContent = ({ className }) => {
  const { t } = useTranslation();

  const googleAuthButtonNode = useMemo(() => {
    if (appConfig.GOOGLE_CLIENT_ID === null) return null;

    return (
      <GoogleAuthButton className={styles.googleAuthButton} />
    );
  }, [appConfig.GOOGLE_CLIENT_ID]);

  const passwordAuthNode = useMemo(() => {
    if (!appConfig.ENABLE_PASSWORD_AUTH) return null;

    return (
      <>
        <EmailPasswordForm className={styles.passwordAuthWrapper} />
        <Link path={routePaths.forgotPassword}>
          {t('loginPageContent.forgotPassword')}
        </Link>
      </>
    );
  }, [appConfig.ENABLE_PASSWORD_AUTH]);

  const intermediaryTitleNode = useMemo(() => {
    if (googleAuthButtonNode === null || passwordAuthNode === null) return null;

    return (
      <div className={styles.intermediaryTitle}>
        <div className={styles.dots} />
        <div className={styles.title}>
          {t('loginPageContent.intermediaryTitle')}
        </div>
        <div className={styles.dots} />
      </div>
    );
  }, [googleAuthButtonNode, passwordAuthNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Image src="/img/Logo_Palette.png" height={60} />
      <h1>
        {t('loginPageContent.title')}
      </h1>
      <h2>
        {t('loginPageContent.subtitle')}
      </h2>
      {googleAuthButtonNode}
      {intermediaryTitleNode}
      {passwordAuthNode}
    </div>
  );
};

LoginPageContent.propTypes = {
  className: PropTypes.string,
};

LoginPageContent.defaultProps = {
  className: '',
};

export default LoginPageContent;
