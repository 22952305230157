import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

export const ALERT_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  NEUTRAL: 'neutral',
  ERROR: 'error',
};

export const ALERT_ICONS = {
  [ALERT_TYPES.SUCCESS]: CheckFilled,
  [ALERT_TYPES.INFO]: InfoCircleFilled,
  [ALERT_TYPES.WARNING]: InfoCircleFilled,
  [ALERT_TYPES.NEUTRAL]: InfoCircleFilled,
  [ALERT_TYPES.ERROR]: ClosePopupFilled,
};
