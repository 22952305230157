/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';

/*
 * Initial State
 */
const initialState = {
  getCompanyCollectionsDetailsIsPending: false,

  collectionsDetailsByCompanyId: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'companyCollections',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Company Collections Details */
    getCompanyCollectionsDetails: (state) => {
      state.getCompanyCollectionsDetailsIsPending = true;
    },
    setCollectionsDetails: (state, { payload }) => {
      const { companyId, collectionsDetails } = payload;

      state.collectionsDetailsByCompanyId = _mergeWith(
        _cloneDeep(original(state.collectionsDetailsByCompanyId)),
        {
          [companyId]: collectionsDetails,
        },
      );
    },
    getCompanyCollectionsDetailsCompleted: (state) => {
      state.getCompanyCollectionsDetailsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const rawCollectionsDetailsByCompanyId = (state) => root(state).collectionsDetailsByCompanyId;

const getCompanyCollectionsDetailsIsPending = (state) => root(state).getCompanyCollectionsDetailsIsPending;

const getCompanyCollectionsDetails = createSelector(
  [rawCollectionsDetailsByCompanyId, getProps],
  (collectionsDetailsByCompanyId, { companyId }) => (
    collectionsDetailsByCompanyId[companyId] || null
  ),
);

export const selectors = {
  getCompanyCollectionsDetailsIsPending,

  getCompanyCollectionsDetails,
};
