import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import _orderBy from 'lodash/orderBy';

import Loader from '@palette/components/utils/Loader/Loader';
import CompanyUsersList from '@palette/components/companyUsers/CompanyUsersList/CompanyUsersList';
import Input from '@palette/components/designSystem/Input/Input';
import CompanyUsersListSortOptions from '@palette/components/companyUsers/CompanyUsersListSortOptions/CompanyUsersListSortOptions';
import CompanyUsersListFilters from '@palette/components/companyUsers/CompanyUsersListFilters/CompanyUsersListFilters';

import { useCompanyUsersListSortInitialValue, useSearchedUserInSearch } from '@palette/hooks/UserHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getUserStatus } from '@palette/helpers/UserHelper';

import {
  ROLE_FILTER_QS_KEY,
  SEARCHED_USER_QS_KEY,
  SORT_LAST_NAME_QS_KEY,
  STATUS_FILTER_QS_KEY,
} from '@palette/constants/user';

import * as CompanyModel from '@palette/models/Company';

import { actions as CompanyUsersActions, selectors as CompanyUsersSelectors } from '@palette/state/CompanyUsers';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CompanyUsersListWrapper.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsersListWrapper = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchedUserFromSearch] = useSearchedUserInSearch();
  const sortValue = useCompanyUsersListSortInitialValue();
  const [roleFilterValue] = useKeyInSearch(ROLE_FILTER_QS_KEY);
  const [statusFilterValue] = useKeyInSearch(STATUS_FILTER_QS_KEY);

  const [searchedUser, setSearchedUser] = useState(searchedUserFromSearch);
  useEffect(() => {
    setSearchedUser(searchedUserFromSearch);
  }, [searchedUserFromSearch]);

  const getCompanyUsersIsPending = useSelector(CompanyUsersSelectors.getCompanyUsersIsPending);
  const usersList = useSelector((state) => CompanyUsersSelectors.getCompanyUsersList(state, { companyId: company.id }));

  useEffect(() => {
    dispatch(CompanyUsersActions.getCompanyUsers({ companyId: company.id }));
  }, [company.id]);

  const finalUsersList = useMemo(() => {
    let filteredUsersList = usersList;

    if (usersList.length !== 0) {
      if (searchedUserFromSearch !== '') {
        filteredUsersList = filteredUsersList.filter((user) => (
          user.displayName.toLowerCase().indexOf(searchedUserFromSearch.toLowerCase()) > -1
        ));
      }

      if (roleFilterValue !== null) {
        filteredUsersList = filteredUsersList.filter((user) => (
          user.account !== null && user.account.roleId === roleFilterValue
        ));
      }

      if (statusFilterValue !== null) {
        filteredUsersList = filteredUsersList.filter((user) => {
          const userStatus = getUserStatus(user);
          return userStatus.toLowerCase() === statusFilterValue.toLowerCase();
        });
      }
    }

    if (sortValue.sortKey === SORT_LAST_NAME_QS_KEY) {
      return _orderBy(filteredUsersList, [(user) => user.lastName.toLowerCase()], [sortValue.sortKeyValue]);
    }

    return filteredUsersList;
  }, [
    usersList,
    searchedUserFromSearch,
    sortValue,
    roleFilterValue,
    statusFilterValue,
  ]);

  const performUserSearch = useCallback(
    _debounce((newSearchedUser) => {
      const QSToAdd = {};
      const keysToDelete = [];

      if (newSearchedUser !== '') {
        QSToAdd[SEARCHED_USER_QS_KEY] = newSearchedUser;
      } else {
        keysToDelete.push(SEARCHED_USER_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }, 700),
    [],
  );

  const handleSearchUserChange = useCallback((newSearchedUser) => {
    setSearchedUser(newSearchedUser);

    if (newSearchedUser.length >= 1 || (newSearchedUser.length === 0 && searchedUser.length > 0)) {
      performUserSearch(newSearchedUser);
    }
  }, [searchedUser, performUserSearch]);

  const filtersNode = useMemo(() => (
    <>
      <div className={styles.filtersWrapper}>
        <Input
          className={styles.userSearch}
          type="search"
          defaultValue={searchedUserFromSearch}
          placeholder={t('companyUsersListWrapper.filters.userSearch.placeholder')}
          onChange={handleSearchUserChange}
          value={searchedUser}
        />
        <CompanyUsersListSortOptions className={styles.sortOptions} />
      </div>
      <CompanyUsersListFilters />
    </>
  ), [
    searchedUser,
    handleSearchUserChange,
    searchedUserFromSearch,
  ]);

  const listNode = useMemo(() => (
    <Loader className={styles.list} spinning={getCompanyUsersIsPending}>
      <CompanyUsersList className={styles.list} users={finalUsersList} />
    </Loader>
  ), [
    getCompanyUsersIsPending,
    finalUsersList,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {filtersNode}
      {listNode}
    </div>
  );
};

CompanyUsersListWrapper.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyUsersListWrapper.defaultProps = {
  className: '',
};

export default CompanyUsersListWrapper;
