import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageDateAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { ASYNC_JOB_STATUSES, ASYNC_JOB_TYPES } from '@palette/constants/asyncJobs';

export const modelName = 'AsyncJob';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  companyId: PropTypes.string,
  status: PropTypes.oneOf(Object.values(ASYNC_JOB_STATUSES)),
  type: PropTypes.oneOf(Object.values(ASYNC_JOB_TYPES)),
  params: PropTypes.string,
  lastActivityAt: PropTypes.string,
  launchDate: PropTypes.string,
  result: PropTypes.string,
  error: PropTypes.string,
  duration: PropTypes.number,
  userJob: PropTypes.bool,
  writeLock: PropTypes.bool,
});

export const manageAsyncJobStatus = (asyncJob) => (ASYNC_JOB_STATUSES[manageStringAttribute(asyncJob, 'status')] || ASYNC_JOB_STATUSES.QUEUED);

export const transform = (asyncJob) => {
  if (!asyncJob) {
    return null;
  }

  const type = (ASYNC_JOB_TYPES[manageStringAttribute(asyncJob, 'type')] || ASYNC_JOB_TYPES.COMPUTE);

  return (
    {
      id: manageStringAttribute(asyncJob, '_id'),
      companyId: manageStringAttribute(asyncJob, 'company'),
      status: manageAsyncJobStatus(asyncJob),
      type,
      params: manageStringAttribute(asyncJob, 'params', null),
      lastActivityAt: manageDateAttribute(asyncJob, 'lastActivityAt', null),
      launchDate: manageDateAttribute(asyncJob, 'launchDate', null),
      result: manageStringAttribute(asyncJob, 'result', null),
      error: manageStringAttribute(asyncJob, 'error', null),
      duration: managePureFloatAttribute(asyncJob, 'duration', null),
      userJob: manageBooleanAttribute(asyncJob, 'userJob'),
      writeLock: manageBooleanAttribute(asyncJob, 'writeLock'),
    }
  );
};

export const transformList = (asyncJobs) => asyncJobs.map((asyncJob) => transform(asyncJob));
