import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CompanyCollectionsListItem from '@palette/components/companyCollections/CompanyCollectionsListItem/CompanyCollectionsListItem';

import * as MongoCollectionsDetailsModel from '@palette/models/MongoCollectionsDetails';

import styles from './CompanyCollectionsList.less';

const classNames = bindClassNames.bind(styles);

const CompanyCollectionsList = ({ className, collectionsDetailsList }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => {
    if (collectionsDetailsList.length === 0) {
      return (
        <DefaultEmptyState description={t('companyCollectionsList.empty.description')} />
      );
    }

    return collectionsDetailsList.map((collectionsDetails) => (
      <CompanyCollectionsListItem key={collectionsDetails.collectionName} className={styles.collectionsDetails} collectionsDetails={collectionsDetails} />
    ));
  }, [
    collectionsDetailsList,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompanyCollectionsList.propTypes = {
  className: PropTypes.string,
  collectionsDetailsList: PropTypes.arrayOf(MongoCollectionsDetailsModel.propTypes).isRequired,
};

CompanyCollectionsList.defaultProps = {
  className: '',
};

export default CompanyCollectionsList;
