import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import AddNewUser from '@palette/components/companyUsers/AddNewUser/AddNewUser';
import CompanyUsersListWrapper from '@palette/components/companyUsers/CompanyUsersListWrapper/CompanyUsersListWrapper';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsUsers.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsUsers = ({ className, company }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          {t('companyDetailsUsers.title')}
        </div>
        <AddNewUser company={company} />
      </div>
      <CompanyUsersListWrapper className={styles.companyUsersList} company={company} />
    </div>
  );
};

CompanyDetailsUsers.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsUsers.defaultProps = {
  className: '',
};

export default CompanyDetailsUsers;
