import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

export function* addNewUser(payload) {
  const {
    companyId,
    firstName,
    lastName,
    email,
    currency,
    role,
  } = payload;

  const data = {
    companyId,
    firstName,
    lastName,
    email,
    currency,
    roleId: role,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyUsers.addNewUser, data, getConfig()),
    [],
  );
}

export function* listUsers(payload) {
  const {
    companyId,
  } = payload;

  const data = {
    companyId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyUsers.listUsers, data, getConfig()),
    [],
  );
}

export function* impersonate(payload) {
  const {
    userId,
  } = payload;

  const data = {
    userId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.companyUsers.impersonate, data, getConfig()),
    [],
  );
}
