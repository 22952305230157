import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import MoreMenuCheckbox from '@palette/components/designSystem/MoreMenuCheckbox/MoreMenuCheckbox';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';
import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';

import {
  useCompaniesListFiltersInitialValue,
} from '@palette/hooks/CompanyHooks';

import {
  COMPANIES_LIST_FILTERS,
  COMPANIES_LIST_FILTERS_BY_KEY,
  COMPANIES_LIST_FILTERS_KEYS,
} from '@palette/constants/company';
import { LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CompaniesListFilters.less';

const classNames = bindClassNames.bind(styles);

const CompaniesListFilters = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filtersInitialValue = useCompaniesListFiltersInitialValue();

  const [filters, setFilters] = useState(filtersInitialValue);

  useEffect(() => setFilters(filtersInitialValue), [filtersInitialValue]);

  const handleFiltersDropdownClose = useCallback(() => {
    if (_isEqual(filters, filtersInitialValue)) {
      return;
    }

    const QSToAdd = {};
    const keysToDelete = [LIMIT_QS_KEY, PAGE_QS_KEY];

    Object.values(COMPANIES_LIST_FILTERS_KEYS).forEach((companiesListFilterKey) => {
      const companiesListFilter = COMPANIES_LIST_FILTERS_BY_KEY[companiesListFilterKey];
      if (filters.includes(companiesListFilterKey)) {
        QSToAdd[companiesListFilter.qsKey] = companiesListFilter.qsKeyValue;
      } else if (!filters.includes(companiesListFilter.oppositeFilterKey)) {
        keysToDelete.push(companiesListFilter.qsKey);
      }
    });

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, [filters, filtersInitialValue]);

  const handleFilterChange = useCallback((companiesListFilter, onChangeEvent) => {
    const newFilters = _cloneDeep(filters);

    if (onChangeEvent.target.checked && !newFilters.includes(companiesListFilter.key)) {
      newFilters.push(companiesListFilter.key);
    } else if (!onChangeEvent.target.checked && newFilters.includes(companiesListFilter.key)) {
      const filterPosition = newFilters.indexOf(companiesListFilter.key);
      newFilters.splice(filterPosition, 1);
    }

    if (onChangeEvent.target.checked && companiesListFilter.oppositeFilterKey != null && newFilters.includes(companiesListFilter.oppositeFilterKey)) {
      const filterPosition = newFilters.indexOf(companiesListFilter.oppositeFilterKey);
      newFilters.splice(filterPosition, 1);
    }

    setFilters(newFilters);
  }, [filters]);

  const filterItems = useMemo(() => (
    Object.values(COMPANIES_LIST_FILTERS).map((companiesListFilter) => (
      {
        key: companiesListFilter.key,
        title: t(companiesListFilter.filterNameI18NId),
        onChange: (onChangeEvent) => handleFilterChange(companiesListFilter, onChangeEvent),
        isChecked: filters.includes(companiesListFilter.key),
      }
    ))
  ), [filters, handleFilterChange]);

  const actionButtonNode = useMemo(() => {
    if (!_isEqual(filters, filtersInitialValue)) {
      return (
        <>
          <ClosePopupLine className={styles.iconCloseFilter} width={18} height={18} />
          {t('companiesListWrapper.filters.tags.closeLabel')}
        </>
      );
    }

    return (
      <>
        <BarsFilled className={styles.iconFilter} width={24} height={24} />
        {t('companiesListWrapper.filters.tags.label')}
      </>
    );
  }, [filters, filtersInitialValue]);

  return (
    <MoreMenuCheckbox
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      items={filterItems}
      onClose={handleFiltersDropdownClose}
      placement="bottomRight"
    >
      {actionButtonNode}
    </MoreMenuCheckbox>
  );
};

CompaniesListFilters.propTypes = {
  className: PropTypes.string,
};

CompaniesListFilters.defaultProps = {
  className: '',
};

export default CompaniesListFilters;
