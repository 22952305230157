import {
  all,
  call,
  delay,
  put,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { actions as CompanyJobsActions } from '@palette/state/CompanyJobs/slice';
import { manageError as manageCompanyJobsError } from '@palette/state/CompanyJobs/errors';

import { showErrorNotification, showSuccessNotification } from '@palette/helpers/SagasHelper';
import { jobInProgress } from '@palette/helpers/AsyncJobHelper';

import { ASYNC_JOB_STATUSES } from '@palette/constants/asyncJobs';

import appConfig from '@palette/config/app';

import * as JobsDetailsModel from '@palette/models/JobsDetails';
import * as AsyncJobModel from '@palette/models/AsyncJob';

import * as CompanyJobsService from '@palette/services/CompanyJobsService';

export function* getCompanyJobsDetails({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompanyJobsService.getCompanyJobsDetails, callData);

  if (callResult.ok) {
    const jobsDetailsList = JobsDetailsModel.transformList(callResult.data);

    yield put(CompanyJobsActions.setJobsDetailsList({ jobsDetailsList }));
  } else {
    const error = manageCompanyJobsError(callResult);
    showErrorNotification(error);
  }

  yield put(CompanyJobsActions.getCompanyJobsDetailsCompleted());
}

export function* copyJobIdToClipboard({ payload = {} }) {
  const {
    job,
  } = payload;

  try {
    yield call(() => (navigator.clipboard.writeText(job.id)));
    showSuccessNotification({ i18nId: 'notifications.success.copyJobIdToClipboard', params: { jobId: job.id } });
  } catch (e) {
    showErrorNotification({
      i18nId: 'notifications.error.copyJobIdToClipboard',
      message: '',
    });
  }
}

export function* waitJob({ job, successSaga, errorSaga, completedSaga }) {
  let currentJob = job;
  while (jobInProgress(currentJob)) {
    const callResult = yield call(CompanyJobsService.getAsyncJobById, { companyId: job.companyId, jobId: currentJob.id });

    if (callResult.ok) {
      currentJob = AsyncJobModel.transform(callResult.data);
      yield put(CompanyJobsActions.updateJob({ job: currentJob }));
    } else {
      const error = manageCompanyJobsError(callResult);
      yield call(errorSaga, { job: currentJob, error });
      return;
    }

    yield delay(appConfig.ASYNC_JOB_POLLING_SLEEP_DURATION);
  }

  if (currentJob.status === ASYNC_JOB_STATUSES.ERRORED) {
    yield call(errorSaga, {
      job: currentJob,
      error: { i18nId: 'asyncJobs.errors.processing', message: currentJob.error },
    });
  }

  if (currentJob.status === ASYNC_JOB_STATUSES.DONE) {
    yield call(successSaga, {
      job: currentJob,
    });
  }

  yield call(completedSaga, {
    job: currentJob,
  });
}

export function* autoSyncSuccess({ job }) {
  yield put(CompanyJobsActions.updateJob({ job }));
  showSuccessNotification({ i18nId: 'notifications.success.autoSyncSuccess', params: { jobId: job.id } });
}

export function autoSyncError({ error }) {
  showErrorNotification(error);
}

export function* autoSyncCompleted({ job }) {
  yield put(CompanyJobsActions.autoSyncCompleted({ job }));
}

export function* autoSync({ payload = {} }) {
  const { job } = payload;

  yield spawn(waitJob, {
    job,
    successSaga: autoSyncSuccess,
    errorSaga: autoSyncError,
    completedSaga: autoSyncCompleted,
  });
}

export function* loop() {
  yield all([
    takeLatest(CompanyJobsActions.getCompanyJobsDetails.type, getCompanyJobsDetails),
    takeLatest(CompanyJobsActions.copyJobIdToClipboard.type, copyJobIdToClipboard),
    takeEvery(CompanyJobsActions.autoSync.type, autoSync),
  ]);
}

export function* clean() {
  yield put(CompanyJobsActions.resetToInitialState());
}
