import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { extractDateFromMongoId } from '@palette/helpers/CommonHelper';

import * as CompanyModel from '@palette/models/Company';
import * as UserModel from '@palette/models/User';

export const modelName = 'ApiLog';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  company: CompanyModel.propTypes,
  byPaletteAdmin: PropTypes.bool,
  status: PropTypes.number,
  time: PropTypes.number,
  url: PropTypes.string,
  user: UserModel.propTypes,
});

export const transform = (apiLog) => {
  if (!apiLog) {
    return null;
  }

  const id = manageStringAttribute(apiLog, '_id');

  const createdAt = extractDateFromMongoId(id).toISOString();

  return (
    {
      id,
      createdAt: manageDateAttribute({ createdAt }, 'createdAt'),
      company: CompanyModel.transform(manageFreeShapeObjectAttribute(apiLog, 'company')),
      byPaletteAdmin: manageBooleanAttribute(apiLog, 'isAdmin'),
      status: manageNumberAttribute(apiLog, 'status'),
      time: manageNumberAttribute(apiLog, 'time'),
      url: manageStringAttribute(apiLog, 'url'),
      user: UserModel.transform(manageFreeShapeObjectAttribute(apiLog, 'user')),
    }
  );
};

export const transformList = (apiLogs) => apiLogs.map((apiLog) => transform(apiLog));
