import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompanyUsersListRoleFilter from '@palette/components/companyUsers/CompanyUsersListRoleFilter/CompanyUsersListRoleFilter';
import CompanyUsersListStatusFilter from '@palette/components/companyUsers/CompanyUsersListStatusFilter/CompanyUsersListStatusFilter';

import styles from './CompanyUsersListFilters.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsersListFilters = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <CompanyUsersListRoleFilter className={styles.roleFilter} />
    <CompanyUsersListStatusFilter className={styles.statusFilter} />
  </div>
);

CompanyUsersListFilters.propTypes = {
  className: PropTypes.string,
};

CompanyUsersListFilters.defaultProps = {
  className: '',
};

export default CompanyUsersListFilters;
