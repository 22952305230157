import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import FlagFilled from '@palette/components/utils/Icons/FlagFilled';
import BadgeFilled from '@palette/components/utils/Icons/BadgeFilled';
import MicroshipFilled from '@palette/components/utils/Icons/MicroshipFilled';
import ElectricalPlugFilled from '@palette/components/utils/Icons/ElectricalPlugFilled';
import TimerFilled from '@palette/components/utils/Icons/TimerFilled';
import CompanyJobStatsList from '@palette/components/companyJobs/CompanyJobStatsList/CompanyJobStatsList';
import SaveAsFilled from '@palette/components/utils/Icons/SaveAsFilled';
import CompanyCollectionsStatsTotals from '@palette/components/companyCollections/CompanyCollectionsStatsTotals/CompanyCollectionsStatsTotals';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { formatDuration } from '@palette/helpers/DayjsHelper';

import { RIGHTS } from '@palette/constants/role';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyUsedResources.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsedResources = ({ className, company }) => {
  const { t } = useTranslation();

  const canViewCompanyTechDetails = useProfileRights([RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW]);

  const connectorsWrapperNode = useMemo(() => {
    const connectorsWrapper = (
      <div className={styles.connectorsWrapper}>
        <ElectricalPlugFilled className={styles.connectorsIcon} width={20} height={20} />
        <div className={styles.connectorsCount}>
          {company.connectorsCount}
        </div>
      </div>
    );

    if (company.connectorsNames.length === 0) {
      return connectorsWrapper;
    }

    const connectorsList = (
      <div className={styles.connectorsTypesList}>
        {
          company.connectorsNames.map((connectorType) => (
            <Tag
              key={connectorType}
              className={styles.connectorType}
              type="skyblue"
            >
              {connectorType}
            </Tag>
          ))
        }
      </div>
    );
    return (
      <Tooltip
        overlayClassName={styles.connectorsTooltip}
        title={t('companiesListItem.connectors.types')}
        content={connectorsList}
      >
        {connectorsWrapper}
      </Tooltip>
    );
  }, [company]);

  const jobsNode = useMemo(() => {
    if (!canViewCompanyTechDetails) return null;

    const cumulDuration = company.jobsStats.reduce((cumul, jobStats) => (cumul + jobStats.totalDuration), 0);

    const jobsStatsWrapper = (
      <div className={styles.jobsStatsWrapper}>
        <TimerFilled className={styles.jobsStatsIcon} width={20} height={20} />
        <div className={styles.jobsStatsAverageDuration}>
          {formatDuration(cumulDuration)}
        </div>
      </div>
    );

    if (company.jobsStats.length === 0) {
      return jobsStatsWrapper;
    }

    const jobsDurationsList = (
      <CompanyJobStatsList statsList={company.jobsStats} />
    );

    return (
      <Tooltip
        overlayClassName={styles.jobsStatsTooltip}
        title={t('companiesListItem.jobs.statsPerJobType')}
        content={jobsDurationsList}
      >
        {jobsStatsWrapper}
      </Tooltip>
    );
  }, [canViewCompanyTechDetails, company]);

  const mongoCollectionsNode = useMemo(() => {
    if (!canViewCompanyTechDetails) return null;

    const mongoCollectionsStatsWrapper = (
      <div className={styles.mongoCollectionsStatsWrapper}>
        <SaveAsFilled className={styles.mongoCollectionsStatsIcon} width={20} height={20} />
        <div className={styles.mongoCollectionsStatsTotal}>
          {prettyBytes(company.mongoCollectionsStats.totalStorage)}
        </div>
      </div>
    );

    const mongoCollectionsStatsDetails = (
      <CompanyCollectionsStatsTotals mongoCollectionsStats={company.mongoCollectionsStats} />
    );

    return (
      <Tooltip
        overlayClassName={styles.mongoCollectionsStatsTooltip}
        title={t('companiesListItem.mongoCollectionsStats.statsDetails')}
        content={mongoCollectionsStatsDetails}
      >
        {mongoCollectionsStatsWrapper}
      </Tooltip>
    );
  }, [canViewCompanyTechDetails, company]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {connectorsWrapperNode}
      <Tooltip title={t('companiesListItem.plansCount', { count: company.plansCount })}>
        <div className={styles.plansWrapper}>
          <MedicalChartFilled className={styles.plansIcon} width={20} height={20} />
          <div className={styles.plans}>
            {company.plansCount}
          </div>
        </div>
      </Tooltip>
      <Tooltip title={t('companiesListItem.quotasCount', { count: company.quotasCount })}>
        <div className={styles.quotasWrapper}>
          <FlagFilled className={styles.quotasIcon} width={20} height={20} />
          <div className={styles.quotas}>
            {company.quotasCount}
          </div>
        </div>
      </Tooltip>
      <Tooltip title={t('companiesListItem.challengesCount', { count: company.challengesCount })}>
        <div className={styles.challengesWrapper}>
          <BadgeFilled className={styles.challengesIcon} width={20} height={20} />
          <div className={styles.challenges}>
            {company.challengesCount}
          </div>
        </div>
      </Tooltip>
      <Tooltip title={t('companiesListItem.reconciliationsCount', { count: company.reconciliationsCount })}>
        <div className={styles.reconciliationsWrapper}>
          <MicroshipFilled className={styles.reconciliationsIcon} width={20} height={20} />
          <div className={styles.reconciliations}>
            {company.reconciliationsCount}
          </div>
        </div>
      </Tooltip>
      {jobsNode}
      {mongoCollectionsNode}
    </div>
  );
};

CompanyUsedResources.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyUsedResources.defaultProps = {
  className: '',
};

export default CompanyUsedResources;
