import {
  put,
  all,
  call,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as CompaniesActions } from '@palette/state/Companies/slice';
import { manageError as manageCompaniesError } from '@palette/state/Companies/errors';

import { actions as CompanyJobsActions, selectors as CompanyJobsSelectors } from '@palette/state/CompanyJobs';

import { DEFAULT_SYNC_INTERVAL } from '@palette/constants/company';

import { showErrorNotification, showSuccessNotification } from '@palette/helpers/SagasHelper';

import * as CompanyModel from '@palette/models/Company';
import * as PaginationModel from '@palette/models/Pagination';
import * as SimilarCompanyModel from '@palette/models/SimilarCompany';

import { actions as ProfileActions } from '@palette/state/Profile';

import * as CompaniesService from '@palette/services/CompaniesService';

export function* getCompanies({ payload = {} }) {
  const {
    searchedCompanyName,
    isLive = null,
    hasStripeSubscription = null,
    hasTrialEndDate = null,
    isArchived = null,
    page,
    limit,
    sort = null,
  } = payload;

  const callData = {
    searchedCompanyName,
    isLive,
    hasStripeSubscription,
    hasTrialEndDate,
    isArchived,
    page,
    limit,
    sort,
  };

  const callResult = yield call(CompaniesService.getCompanies, callData);

  if (callResult.ok) {
    const companies = CompanyModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);
    yield put(CompaniesActions.setCompanies({ companies, pagination }));
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.getCompaniesCompleted());
}

export function* createCompany({ payload = {} }) {
  const {
    company,
    onSuccessCB = null,
  } = payload;

  const {
    logo = null,
    name,
    currency,
    timezone,
    strategyType,
    rollType,
    breakType,
  } = company;

  const callData = {
    logo,
    name,
    currency,
    timezone,
    strategyType,
    rollType,
    breakType,
  };

  const callResult = yield call(CompaniesService.createCompany, callData);

  if (callResult.ok) {
    // Refresh Profile
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.createCompany' });

    if (onSuccessCB !== null) onSuccessCB(callResult.data._id);
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.createCompanyCompleted());
}

export function* checkSimilarCompanyName({ payload = {} }) {
  const {
    name,
  } = payload;

  const callData = {
    name,
  };

  const callResult = yield call(CompaniesService.checkSimilarCompanyName, callData);

  if (callResult.ok) {
    const similarCompanies = SimilarCompanyModel.transformList(callResult.data);
    yield put(CompaniesActions.setSimilarCompanies({ similarCompanies }));
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.checkSimilarCompanyNameCompleted());
}

export function* getCompanyById({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompaniesService.getCompanyById, callData);

  if (callResult.ok) {
    const company = CompanyModel.transform(callResult.data);
    yield put(CompaniesActions.setCompany({ company }));
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.getCompanyByIdCompleted());
}

export function* updateCompany({ payload = {} }) {
  const {
    companyId,
    company,
    onSuccessCB = null,
  } = payload;

  const {
    logo = null,
    name,
    timezone,
  } = company;

  const callData = {
    companyId,
    logo,
    name,
    timezone,
  };

  const callResult = yield call(CompaniesService.updateCompany, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateCompany' });

    if (onSuccessCB !== null) onSuccessCB(companyId);
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateCompanyCompleted());
}

export function* updateFeature({ payload = {} }) {
  const {
    companyId,
    featureKey,
    newFeatureStatus,
  } = payload;

  const callData = {
    companyId,
    featureKey,
    newFeatureStatus,
  };

  const callResult = yield call(CompaniesService.updateFeature, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateFeature' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateFeatureCompleted());
}

export function* updateConnector({ payload = {} }) {
  const {
    companyId,
    connectorId,
    newAutoSyncStatus,
  } = payload;

  const callData = {
    companyId,
    connectorId,
    newAutoSyncStatus,
  };

  const callResult = yield call(CompaniesService.updateConnector, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateConnector' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateConnectorCompleted());
}

export function* deleteConnector({ payload = {} }) {
  const {
    companyId,
    connectorId,
  } = payload;

  const callData = {
    connectorId,
  };

  const callResult = yield call(CompaniesService.deleteConnector, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.deleteConnector' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.deleteConnectorCompleted());
}

export function* updateTrialSettings({ payload = {} }) {
  const {
    companyId,
    trialEndDate,
    trialPaymentUrl,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    trialEndDate,
    trialPaymentUrl,
  };

  const callResult = yield call(CompaniesService.updateTrialSettings, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(companyId);

    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateTrialSettings' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateTrialSettingsCompleted());
}

export function* updateBillingSettings({ payload = {} }) {
  const {
    companyId,
    stripeSubscriptionId,
    minSeats,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    stripeSubscriptionId,
    minSeats,
  };

  const callResult = yield call(CompaniesService.updateBillingSettings, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(companyId);

    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateBillingSettings' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateBillingSettingsCompleted());
}

export function* turnCompanyLive({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompaniesService.turnCompanyLive, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateCompany' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.turnCompanyLiveCompleted());
}

export function* offboardCompany({ payload = {} }) {
  const {
    companyId,
  } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompaniesService.offboardCompany, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateCompany' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.offboardCompanyCompleted());
}

export function* addPaletteAdmin({ payload = {} }) {
  const {
    companyId,
    paletteAdminId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    paletteAdminId,
  };

  const callResult = yield call(CompaniesService.addPaletteAdmin, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.addPaletteAdmin' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.addPaletteAdminCompleted());
}

export function* removePaletteAdmin({ payload = {} }) {
  const {
    companyId,
    paletteAdminId,
  } = payload;

  const callData = {
    companyId,
    paletteAdminId,
  };

  const callResult = yield call(CompaniesService.removePaletteAdmin, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.removePaletteAdmin' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.removePaletteAdminCompleted());
}

export function* updateSupportOwner({ payload = {} }) {
  const {
    companyId,
    supportOwnerId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    supportOwnerId,
  };

  const callResult = yield call(CompaniesService.updateSupportOwner, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateSupportOwner' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateSupportOwnerCompleted());
}

export function* updateDocumentationUrl({ payload = {} }) {
  const {
    companyId,
    documentationUrl,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    documentationUrl,
  };

  const callResult = yield call(CompaniesService.updateDocumentationUrl, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateDocumentationUrl' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateDocumentationUrlCompleted());
}

export function* updateSyncInterval({ payload = {} }) {
  const {
    companyId,
    syncInterval = DEFAULT_SYNC_INTERVAL,
    onSuccessCB = null,
  } = payload;

  const callData = {
    companyId,
    syncInterval,
  };

  const callResult = yield call(CompaniesService.updateSyncInterval, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    showSuccessNotification({ i18nId: 'notifications.success.updateSyncInterval' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.updateSyncIntervalCompleted());
}

export function* recomputeTechnicalStats({ payload = {} }) {
  const { companyId } = payload;

  const callData = {
    companyId,
  };

  const callResult = yield call(CompaniesService.recomputeTechnicalStats, callData);

  if (callResult.ok) {
    // Refresh Company
    yield put(CompaniesActions.getCompanyById({ companyId }));
    yield take(CompaniesActions.getCompanyByIdCompleted.type);

    const companyJobsDetails = yield select(CompanyJobsSelectors.getCompanyJobsDetails, { companyId });
    if (companyJobsDetails !== null) {
      // Refresh Company's JobDetails
      yield put(CompanyJobsActions.getCompanyJobsDetails({ companyId }));
      yield take(CompanyJobsActions.getCompanyJobsDetailsCompleted.type);
    }

    showSuccessNotification({ i18nId: 'notifications.success.recomputeTechnicalStats' });
  } else {
    const error = manageCompaniesError(callResult);
    showErrorNotification(error);
  }

  yield put(CompaniesActions.recomputeTechnicalStatsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CompaniesActions.getCompanies.type, getCompanies),
    takeLatest(CompaniesActions.createCompany.type, createCompany),
    takeLatest(CompaniesActions.checkSimilarCompanyName.type, checkSimilarCompanyName),
    takeLatest(CompaniesActions.getCompanyById.type, getCompanyById),
    takeLatest(CompaniesActions.updateCompany.type, updateCompany),
    takeLatest(CompaniesActions.updateFeature.type, updateFeature),
    takeLatest(CompaniesActions.updateConnector.type, updateConnector),
    takeLatest(CompaniesActions.deleteConnector.type, deleteConnector),
    takeLatest(CompaniesActions.updateTrialSettings.type, updateTrialSettings),
    takeLatest(CompaniesActions.updateBillingSettings.type, updateBillingSettings),
    takeLatest(CompaniesActions.turnCompanyLive.type, turnCompanyLive),
    takeLatest(CompaniesActions.offboardCompany.type, offboardCompany),
    takeLatest(CompaniesActions.addPaletteAdmin.type, addPaletteAdmin),
    takeLatest(CompaniesActions.removePaletteAdmin.type, removePaletteAdmin),
    takeLatest(CompaniesActions.updateSupportOwner.type, updateSupportOwner),
    takeLatest(CompaniesActions.updateDocumentationUrl.type, updateDocumentationUrl),
    takeLatest(CompaniesActions.updateSyncInterval.type, updateSyncInterval),
    takeLatest(CompaniesActions.recomputeTechnicalStats.type, recomputeTechnicalStats),
  ]);
}

export function* clean() {
  yield put(CompaniesActions.resetToInitialState());
}
