import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddNewUserModal from '@palette/components/companyUsers/AddNewUserModal/AddNewUserModal';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import * as CompanyModel from '@palette/models/Company';

import styles from './AddNewUser.less';

const classNames = bindClassNames.bind(styles);

const AddNewUser = ({ className, company }) => {
  const { t } = useTranslation();
  const [addNewUserModalIsVisible, showAddNewUserModal] = useState(false);

  const canAddNewUser = useAccessToCompanyDetails(company.id);

  if (!canAddNewUser) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button type="secondary" onClick={() => showAddNewUserModal(true)}>
        {t('addNewUser.addNew')}
      </Button>
      {
        addNewUserModalIsVisible && (
          <AddNewUserModal visible onClose={() => showAddNewUserModal(false)} company={company} />
        )
      }
    </div>
  );
};

AddNewUser.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

AddNewUser.defaultProps = {
  className: '',
};

export default AddNewUser;
