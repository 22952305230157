import React from 'react';

import ApiLogsPageContent from '@palette/components/apiLogs/ApiLogsPageContent/ApiLogsPageContent';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';

const ApiLogsPage = () => {
  const canViewApiLogs = useProfileRights([RIGHTS.ADMIN_API_LOGS_VIEW]);

  if (!canViewApiLogs) return null;

  return (
    <ApiLogsPageContent />
  );
};

export default ApiLogsPage;
