import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

import { RIGHTS } from '@palette/constants/role';

export function* getApiLogs(payload) {
  const {
    page,
    limit,
  } = payload;

  const data = {
    page,
    limit,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.apiLogs.list, data, getConfig()),
    [RIGHTS.ADMIN_API_LOGS_VIEW],
  );
}
