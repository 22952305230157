import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import HourglassFilled from '@palette/components/utils/Icons/HourglassFilled';

import { getDayjs } from '@palette/helpers/DayjsHelper';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyInfosTrialEndDate.less';

const classNames = bindClassNames.bind(styles);

const CompanyInfosTrialEndDate = ({ className, company, displayTooltip }) => {
  const { t } = useTranslation();
  const dayJS = getDayjs();

  if (company.trialEndDate === null) return null;

  const infosNode = (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <HourglassFilled className={styles.trialEndDateIcon} />
      <div className={styles.trialEndDate}>
        {dayJS.utc(company.trialEndDate).format('ll')}
      </div>
    </div>
  );

  if (displayTooltip) {
    return (
      <Tooltip title={t('companiesListItem.trialEndDate', { date: dayJS.utc(company.trialEndDate).format('ll') })}>
        {infosNode}
      </Tooltip>
    );
  }

  return infosNode;
};

CompanyInfosTrialEndDate.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
  displayTooltip: PropTypes.bool,
};

CompanyInfosTrialEndDate.defaultProps = {
  className: '',
  displayTooltip: true,
};

export default CompanyInfosTrialEndDate;
