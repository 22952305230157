import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import CreateCompanyModal from '@palette/components/companies/CreateCompanyModal/CreateCompanyModal';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';

import styles from './CreateCompany.less';

const classNames = bindClassNames.bind(styles);

const CreateCompany = ({ className }) => {
  const { t } = useTranslation();
  const [createCompanyModalIsVisible, showCreateCompanyModal] = useState(false);

  const canCreateCompany = useProfileRights([RIGHTS.ADMIN_COMPANIES_CREATE]);

  if (!canCreateCompany) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button type="secondary" onClick={() => showCreateCompanyModal(true)}>
        {t('createCompany.createNewOne')}
      </Button>
      {
        createCompanyModalIsVisible && (
          <CreateCompanyModal visible onClose={() => showCreateCompanyModal(false)} />
        )
      }
    </div>
  );
};

CreateCompany.propTypes = {
  className: PropTypes.string,
};

CreateCompany.defaultProps = {
  className: '',
};

export default CreateCompany;
