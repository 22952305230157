/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _sortBy from 'lodash/sortBy';

import * as CompanyModel from '@palette/models/Company';

/*
 * Initial State
 */
const initialState = {
  getCompaniesIsPending: false,
  createCompanyIsPending: false,
  checkSimilarCompanyNameIsPending: false,
  getCompanyByIdIsPending: false,
  updateCompanyIsPending: false,
  updateFeatureIsPending: false,
  updateConnectorIsPending: false,
  updateTrialSettingsIsPending: false,
  updateBillingSettingsIsPending: false,
  turnCompanyLiveIsPending: false,
  offboardCompanyIsPending: false,
  addPaletteAdminIsPending: false,
  removePaletteAdminIsPending: false,
  updateSupportOwnerIsPending: false,
  updateDocumentationUrlIsPending: false,
  updateSyncIntervalIsPending: false,
  recomputeTechnicalStatsIsPending: false,
  deleteConnectorIsPending: false,

  byId: {},
  list: null,
  pagination: null,
  lastUsedFilters: {},
  similarCompanies: [],
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Companies */
    getCompanies: (state) => {
      state.getCompaniesIsPending = true;
    },
    setLastUsedFilters: (state, { payload }) => {
      state.lastUsedFilters = payload;
    },
    setCompanies: (state, { payload }) => {
      const { companies, pagination } = payload;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(companies, 'id'),
      );

      state.list = _map(companies, 'id');

      state.pagination = pagination;
    },
    getCompaniesCompleted: (state) => {
      state.getCompaniesIsPending = false;
    },
    /* Create Company */
    createCompany: (state) => {
      state.createCompanyIsPending = true;
    },
    createCompanyCompleted: (state) => {
      state.createCompanyIsPending = false;
    },
    /* Check Similar Company Name */
    checkSimilarCompanyName: (state) => {
      state.checkSimilarCompanyNameIsPending = true;
    },
    setSimilarCompanies: (state, { payload }) => {
      const { similarCompanies } = payload;

      state.similarCompanies = _sortBy(similarCompanies, ['score'], ['desc']);
    },
    checkSimilarCompanyNameCompleted: (state) => {
      state.checkSimilarCompanyNameIsPending = false;
    },
    /* Get Company By Id */
    getCompanyById: (state) => {
      state.getCompanyByIdIsPending = true;
    },
    setCompany: (state, { payload }) => {
      const { company } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStateCompany = clonedById[company.id] || null;

      clonedById[company.id] = CompanyModel.merge(inStateCompany, company);

      state.byId = clonedById;
    },
    getCompanyByIdCompleted: (state) => {
      state.getCompanyByIdIsPending = false;
    },
    /* Update Company */
    updateCompany: (state) => {
      state.updateCompanyIsPending = true;
    },
    updateCompanyCompleted: (state) => {
      state.updateCompanyIsPending = false;
    },
    /* Update Company Feature Setting */
    updateFeature: (state) => {
      state.updateFeatureIsPending = true;
    },
    updateFeatureCompleted: (state) => {
      state.updateFeatureIsPending = false;
    },
    /* Update Company Connector */
    updateConnector: (state) => {
      state.updateConnectorIsPending = true;
    },
    updateConnectorCompleted: (state) => {
      state.updateConnectorIsPending = false;
    },
    /* Update Company Trial Settings */
    updateTrialSettings: (state) => {
      state.updateTrialSettingsIsPending = true;
    },
    updateTrialSettingsCompleted: (state) => {
      state.updateTrialSettingsIsPending = false;
    },
    /* Update Company Billing Settings */
    updateBillingSettings: (state) => {
      state.updateBillingSettingsIsPending = true;
    },
    updateBillingSettingsCompleted: (state) => {
      state.updateBillingSettingsIsPending = false;
    },
    /* Turn Company Live */
    turnCompanyLive: (state) => {
      state.turnCompanyLiveIsPending = true;
    },
    turnCompanyLiveCompleted: (state) => {
      state.turnCompanyLiveIsPending = false;
    },
    /* Offboard Company */
    offboardCompany: (state) => {
      state.offboardCompanyIsPending = true;
    },
    offboardCompanyCompleted: (state) => {
      state.offboardCompanyIsPending = false;
    },
    /* Add Palette Admin */
    addPaletteAdmin: (state) => {
      state.addPaletteAdminIsPending = true;
    },
    addPaletteAdminCompleted: (state) => {
      state.addPaletteAdminIsPending = false;
    },
    /* Remove Palette Admin */
    removePaletteAdmin: (state) => {
      state.removePaletteAdminIsPending = true;
    },
    removePaletteAdminCompleted: (state) => {
      state.removePaletteAdminIsPending = false;
    },
    /* Update Support Owner */
    updateSupportOwner: (state) => {
      state.updateSupportOwnerIsPending = true;
    },
    updateSupportOwnerCompleted: (state) => {
      state.updateSupportOwnerIsPending = false;
    },
    /* Update Documentation Url */
    updateDocumentationUrl: (state) => {
      state.updateDocumentationUrlIsPending = true;
    },
    updateDocumentationUrlCompleted: (state) => {
      state.updateDocumentationUrlIsPending = false;
    },
    /* Update Sync Interval */
    updateSyncInterval: (state) => {
      state.updateSyncIntervalIsPending = true;
    },
    updateSyncIntervalCompleted: (state) => {
      state.updateSyncIntervalIsPending = false;
    },
    /* Recompute Technical Stats */
    recomputeTechnicalStats: (state) => {
      state.recomputeTechnicalStatsIsPending = true;
    },
    recomputeTechnicalStatsCompleted: (state) => {
      state.recomputeTechnicalStatsIsPending = false;
    },
    /* Delete Connector */
    deleteConnector: (state) => {
      state.deleteConnectorIsPending = true;
    },
    deleteConnectorCompleted: (state) => {
      state.deleteConnectorIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const rawById = (state) => root(state).byId;
const rawList = (state) => root(state).list;
const rawPagination = (state) => root(state).pagination;
const rawSimilarCompanies = (state) => root(state).similarCompanies;
const rawLastUsedFilters = (state) => root(state).lastUsedFilters;

const getCompaniesIsPending = (state) => root(state).getCompaniesIsPending;
const createCompanyIsPending = (state) => root(state).createCompanyIsPending;
const checkSimilarCompanyNameIsPending = (state) => root(state).checkSimilarCompanyNameIsPending;
const getCompanyByIdIsPending = (state) => root(state).getCompanyByIdIsPending;
const updateCompanyIsPending = (state) => root(state).updateCompanyIsPending;
const updateFeatureIsPending = (state) => root(state).updateFeatureIsPending;
const updateConnectorIsPending = (state) => root(state).updateConnectorIsPending;
const updateTrialSettingsIsPending = (state) => root(state).updateTrialSettingsIsPending;
const updateBillingSettingsIsPending = (state) => root(state).updateBillingSettingsIsPending;
const turnCompanyLiveIsPending = (state) => root(state).turnCompanyLiveIsPending;
const offboardCompanyIsPending = (state) => root(state).offboardCompanyIsPending;
const addPaletteAdminIsPending = (state) => root(state).addPaletteAdminIsPending;
const removePaletteAdminIsPending = (state) => root(state).removePaletteAdminIsPending;
const updateSupportOwnerIsPending = (state) => root(state).updateSupportOwnerIsPending;
const updateDocumentationUrlIsPending = (state) => root(state).updateDocumentationUrlIsPending;
const updateSyncIntervalIsPending = (state) => root(state).updateSyncIntervalIsPending;
const recomputeTechnicalStatsIsPending = (state) => root(state).recomputeTechnicalStatsIsPending;
const deleteConnectorIsPending = (state) => root(state).deleteConnectorIsPending;

const getCompaniesList = createSelector(
  [rawList, rawById],
  (list, byId) => {
    if (list === null) return null;

    return list.map((companyId) => byId[companyId] || null).filter(Boolean);
  },
);

const getLastUsedFilters = rawLastUsedFilters;

const getSimilarCompanies = rawSimilarCompanies;

const getPagination = rawPagination;

const getCompanyById = createSelector(
  [rawById, getProps],
  (byId, { companyId }) => byId[companyId] || null,
);

export const selectors = {
  getCompaniesIsPending,
  createCompanyIsPending,
  checkSimilarCompanyNameIsPending,
  getCompanyByIdIsPending,
  updateCompanyIsPending,
  updateFeatureIsPending,
  updateConnectorIsPending,
  updateTrialSettingsIsPending,
  updateBillingSettingsIsPending,
  turnCompanyLiveIsPending,
  offboardCompanyIsPending,
  addPaletteAdminIsPending,
  removePaletteAdminIsPending,
  updateSupportOwnerIsPending,
  updateDocumentationUrlIsPending,
  updateSyncIntervalIsPending,
  recomputeTechnicalStatsIsPending,
  deleteConnectorIsPending,

  getCompaniesList,
  getPagination,
  getLastUsedFilters,
  getSimilarCompanies,
  getCompanyById,
};
