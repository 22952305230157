import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { hasAtLeastOneRight } from '@palette/helpers/RoleHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

export const useProfile = () => useSelector(ProfileSelectors.profile);

export const useProfileRights = (rights) => {
  const profile = useProfile();

  return useMemo(() => (
    hasAtLeastOneRight(profile, rights)
  ), [profile, rights]);
};
