import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { Outlet, useLocation } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';

import { useProfile } from '@palette/hooks/ProfileHooks';

import {
  isMaintenancePage,
  isPublicPage,
  isRestrictedPage,
  isSamePagePathname,
  redirectTo,
} from '@palette/helpers/NavigationHelper';
import { getSessionId } from '@palette/helpers/SessionHelper';

import routePaths from '@palette/config/routePaths';

import { actions as ProfileActions } from '@palette/state/Profile';
import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

import styles from './PageWrapper.less';

const classNames = bindClassNames.bind(styles);

const PageWrapper = () => {
  const dispatch = useDispatch();

  const profile = useProfile();

  const redirectToHomePage = useCallback((forceRedirect = false) => {
    if (isPublicPage() || forceRedirect) {
      redirectTo({ path: routePaths.companies });
    }
  }, []);

  const redirectToLoginPage = useCallback(() => {
    redirectTo({ path: routePaths.login });
  }, []);

  const sessionId = getSessionId();

  useEffect(() => {
    if (sessionId !== null && profile === null) {
      dispatch(ProfileActions.getProfile({ onSuccessCB: redirectToHomePage }));
    } else if (sessionId !== null && profile !== null && (isSamePagePathname({ path: '' }) || isMaintenancePage())) {
      redirectToHomePage(true);
    } else if (sessionId === null && isRestrictedPage()) {
      dispatch(ExceptionsActions.forceLogout());
    } else if (sessionId === null && isSamePagePathname({ path: '' })) {
      redirectToLoginPage();
    }
  }, [sessionId, profile]);

  const location = useLocation();

  useEffect(() => {
    dispatch(NavigationActions.updateCurrentLocation({ location }));
  }, [location]);

  const contentNode = useMemo(() => {
    if (
      profile === null && !(isPublicPage() || isMaintenancePage())) {
      return (
        <Loader />
      );
    }

    return (
      <Outlet />
    );
  }, [profile, location]);

  return (
    <div
      className={classNames({
        wrapper: true,
      })}
    >
      {contentNode}
    </div>
  );
};

export default PageWrapper;
