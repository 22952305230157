import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import DragLine from '@palette/components/utils/Icons/DragLine';

import { useCompaniesListSortInitialValue } from '@palette/hooks/CompanyHooks';

import { buildSortOptionKey } from '@palette/helpers/CommonHelper';

import { actions as NavigationActions } from '@palette/state/Navigation';

import {
  COMPANIES_LIST_SORT_OPTIONS,
  COMPANIES_LIST_SORT_OPTIONS_BY_KEY,
} from '@palette/constants/company';

import styles from './CompaniesListSortOptions.less';

const classNames = bindClassNames.bind(styles);

const CompaniesListSortOptions = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sortInitialValue = useCompaniesListSortInitialValue();

  const [sort, setSort] = useState(sortInitialValue);

  useEffect(() => setSort(sortInitialValue), [sortInitialValue]);

  const handleSortOptionChange = useCallback((newSortOption) => {
    const QSToAdd = {};
    const keysToDelete = [];

    Object.values(COMPANIES_LIST_SORT_OPTIONS).forEach((companiesListSortOption) => {
      if (companiesListSortOption.qsKey === newSortOption.qsKey) {
        QSToAdd[newSortOption.qsKey] = newSortOption.qsKeyValue;
      } else {
        keysToDelete.push(companiesListSortOption.qsKey);
      }
    });

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const sortOptionsItems = useMemo(() => (
    Object.values(COMPANIES_LIST_SORT_OPTIONS).map((companiesListSortOption) => (
      {
        key: companiesListSortOption.key,
        title: t(companiesListSortOption.sortOptionNameI18NId),
        onClick: () => { handleSortOptionChange(companiesListSortOption); },
      }
    ))
  ), [handleSortOptionChange]);

  return (
    <MoreMenu
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      items={sortOptionsItems}
    >
      <DragLine className={styles.iconFilter} width={24} height={24} />
      {t(COMPANIES_LIST_SORT_OPTIONS_BY_KEY[buildSortOptionKey(sort.sortKey, sort.sortKeyValue)].sortOptionNameI18NId)}
    </MoreMenu>
  );
};

CompaniesListSortOptions.propTypes = {
  className: PropTypes.string,
};

CompaniesListSortOptions.defaultProps = {
  className: '',
};

export default CompaniesListSortOptions;
