import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';

import { useGAErrorDescriptionInSearch, useGAErrorInSearch } from '@palette/hooks/AuthenticationHooks';

import { ALERT_TYPES } from '@palette/constants/alert';

import appConfig from '@palette/config/app';
import apiConfig from '@palette/config/api';

import styles from './GoogleAuthButton.less';

const classNames = bindClassNames.bind(styles);

const GoogleAuthButton = ({ className }) => {
  const { t } = useTranslation();

  const webhookUrl = encodeURIComponent(`${apiConfig.BASE_URL}/webhooks/google-auth`);

  /* eslint-disable max-len */
  const googleHref = useMemo(() => (
    `https://accounts.google.com/o/oauth2/v2/auth?client_id=${appConfig.GOOGLE_CLIENT_ID}&response_type=code&access_type=offline&prompt=consent&state=Admin&scope=email%20profile%20openid&redirect_uri=${webhookUrl}`
  ), [appConfig.GOOGLE_CLIENT_ID, webhookUrl]);
  /* eslint-enable max-len */

  const [gaErrorValue, gaErrorInSearch] = useGAErrorInSearch();
  const [gaErrorDescriptionValue, gaErrorDescriptionInSearch] = useGAErrorDescriptionInSearch();

  const errorNode = useMemo(() => {
    if (!gaErrorInSearch && !gaErrorDescriptionInSearch) return null;

    let errorDescription = gaErrorValue;
    if (errorDescription !== '') {
      errorDescription = `${errorDescription} - `;
    }
    errorDescription = `${errorDescription}${gaErrorDescriptionValue}`;

    return (
      <Alert
        className={styles.errorAlert}
        type={ALERT_TYPES.ERROR}
        message={t('googleAuthButton.error')}
        description={errorDescription}
      />
    );
  }, [
    gaErrorValue,
    gaErrorDescriptionValue,
    gaErrorInSearch,
    gaErrorDescriptionInSearch,
  ]);

  return (
    <>
      <a
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        href={googleHref}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 5.60667C17.2227 5.60667 19.0594 6.78278 20.1066 7.76556L24.1989 3.77C21.6855 1.43389 18.415 0 14.5 0C8.82885 0 3.93108 3.25444 1.54663 7.99111L6.23496 11.6322C7.41108 8.13611 10.6655 5.60667 14.5 5.60667Z"
            fill="#EA4335"
          />
          <path
            d="M28.4198 14.8222C28.4198 13.63 28.3231 12.76 28.1136 11.8578H14.4998V17.2389H22.4909C22.3298 18.5761 21.4598 20.59 19.5264 21.9433L24.102 25.4878C26.8409 22.9583 28.4198 19.2367 28.4198 14.8222Z"
            fill="#4285F4"
          />
          <path
            d="M6.25111 17.3678C5.945 16.4655 5.76778 15.4989 5.76778 14.5C5.76778 13.5011 5.945 12.5344 6.235 11.6322L1.54667 7.99109C0.563889 9.95664 0 12.1639 0 14.5C0 16.8361 0.563889 19.0433 1.54667 21.0089L6.25111 17.3678Z"
            fill="#FBBC05"
          />
          <path
            d="M14.5 29C18.415 29 21.7016 27.7111 24.1022 25.4878L19.5266 21.9434C18.3022 22.7972 16.6589 23.3934 14.5 23.3934C10.6655 23.3934 7.41108 20.8639 6.25108 17.3678L1.56274 21.0089C3.94719 25.7456 8.82886 29 14.5 29Z"
            fill="#34A853"
          />
        </svg>
        <span>{t('googleAuthButton.signIn')}</span>
      </a>
      {errorNode}
    </>
  );
};

GoogleAuthButton.propTypes = {
  className: PropTypes.string,
};

GoogleAuthButton.defaultProps = {
  className: '',
};

export default GoogleAuthButton;
