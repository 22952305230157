/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';

/*
 * Initial State
 */
const initialState = {
  getPaletteAdminsIsPending: false,

  byId: {},
  list: [],
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'paletteAdmins',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Palette Admins */
    getPaletteAdmins: (state) => {
      state.getPaletteAdminsIsPending = true;
    },
    setPaletteAdmins: (state, { payload }) => {
      const { paletteAdmins } = payload;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(paletteAdmins, 'id'),
      );

      state.list = _map(paletteAdmins, 'id');
    },
    getPaletteAdminsCompleted: (state) => {
      state.getPaletteAdminsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const rawById = (state) => root(state).byId;
const rawList = (state) => root(state).list;

const getPaletteAdminsIsPending = (state) => root(state).getPaletteAdminsIsPending;

const getPaletteAdminsList = createSelector(
  [rawList, rawById],
  (list, byId) => (
    list.map((paletteAdminId) => byId[paletteAdminId] || null).filter(Boolean)
  ),
);

export const selectors = {
  getPaletteAdminsIsPending,

  getPaletteAdminsList,
};
