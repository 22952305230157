import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import PasswordRules from '@palette/components/resetPassword/PasswordRules/PasswordRules';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';

import { isValidPassword } from '@palette/helpers/PasswordHelper';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './ResetPasswordForm.less';

const classNames = bindClassNames.bind(styles);

const ResetPasswordForm = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { resetPasswordToken } = useParams();

  const [passwordCandidate, setPasswordCandidate] = useState('');

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setPasswordCandidate(allValues.password);
  }, []);

  const resetPasswordIsPending = useSelector(ProfileSelectors.resetPasswordIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const handleFinish = useCallback(({ password }) => {
    dispatch(ProfileActions.resetPassword({ resetPasswordToken, password }));
  }, [resetPasswordToken]);

  const handleResetPassword = useCallback(() => form.submit(), [form]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PasswordRules className={styles.passwordRules} password={passwordCandidate} />
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          name="password"
          label={t('resetPasswordForm.form.password.label')}
          required
          rules={[
            { required: true, message: t('resetPasswordForm.form.password.rules.required') },
            () => ({
              validator(_, value) {
                if (!value || isValidPassword(value)) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('resetPasswordForm.form.password.rules.isNotValid')));
              },
            }),
          ]}
        >
          <Input size="big" type="password" placeholder={t('resetPasswordForm.form.password.placeholder')} disabled={resetPasswordIsPending} />
        </FormItem>
        <FormItem
          name="passwordConfirm"
          label={t('resetPasswordForm.form.passwordConfirm.label')}
          required
          rules={[
            { required: true, message: t('resetPasswordForm.form.passwordConfirm.rules.required') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('resetPasswordForm.form.passwordConfirm.rules.isDifferent')));
              },
            }),
          ]}
        >
          <Input size="big" type="password" placeholder={t('resetPasswordForm.form.passwordConfirm.placeholder')} disabled={resetPasswordIsPending} />
        </FormItem>
        <Button className={styles.submitBtn} onClick={handleResetPassword} loading={resetPasswordIsPending}>
          {t('resetPasswordForm.form.submit')}
        </Button>
      </Form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  className: '',
};

export default ResetPasswordForm;
