import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '@palette/components/designSystem/Switch/Switch';
import CompanyDetailsSettingsBlock from '@palette/components/companyDetails/CompanyDetailsSettingsBlock/CompanyDetailsSettingsBlock';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';
import { FEATURES_I18N } from '@palette/constants/company';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsFeatures.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsFeatures = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateFeatures = useProfileRights([RIGHTS.ADMIN_COMPANIES_FEATURES_UPDATE]);

  const updateFeatureIsPending = useSelector(CompaniesSelectors.updateFeatureIsPending);

  const updateCompanyFeature = useCallback((featureKey, newFeatureStatus) => {
    if (!canUpdateFeatures) return;

    dispatch(CompaniesActions.updateFeature({ companyId: company.id, featureKey, newFeatureStatus }));
  }, [canUpdateFeatures, company]);

  const contentNode = useMemo(() => (
    Object.entries(company.features).map(([featureKey, featureIsEnabled]) => (
      <div className={styles.feature} key={featureKey}>
        <Switch
          className={styles.featureSwitch}
          value={featureIsEnabled}
          disabled={(!canUpdateFeatures || updateFeatureIsPending)}
          onChange={(newFeatureStatus) => updateCompanyFeature(featureKey, newFeatureStatus)}
        />
        <div className={styles.featureLabel}>
          {t(FEATURES_I18N[featureKey])}
        </div>
      </div>
    ))
  ), [company, canUpdateFeatures, updateFeatureIsPending, updateCompanyFeature]);

  return (
    <CompanyDetailsSettingsBlock
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyDetailsSettingsFeatures.title')}
      content={contentNode}
    />
  );
};

CompanyDetailsSettingsFeatures.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsFeatures.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsFeatures;
