import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import EditCompany from '@palette/components/companyDetails/EditCompany/EditCompany';
import Image from '@palette/components/designSystem/Image/Image';
import CompanyInfosCreatedAt from '@palette/components/companyDetails/CompanyInfosCreatedAt/CompanyInfosCreatedAt';
import CompanyInfosTrialEndDate from '@palette/components/companyDetails/CompanyInfosTrialEndDate/CompanyInfosTrialEndDate';
import RecomputeTechnicalStats from '@palette/components/companyDetails/RecomputeTechnicalStats/RecomputeTechnicalStats';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsPageHeader.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsPageHeader = ({ className, company }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.logoNameWrapper}>
      <div className={styles.logoWrapper}>
        <Image className={styles.logo} src={company.logo !== '' ? company.logo : '/img/handshake.png'} />
      </div>
      <PageTitle className={styles.name} title={company.name || ''} />
    </div>
    <div className={styles.globalInfosActionsWrapper}>
      <CompanyInfosCreatedAt className={styles.createdAt} company={company} />
      <CompanyInfosTrialEndDate className={styles.trialEndDate} company={company} />
      <EditCompany className={styles.editCompany} company={company} />
      <RecomputeTechnicalStats className={styles.recomputeTechnicalStats} company={company} />
    </div>
  </div>
);

CompanyDetailsPageHeader.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsPageHeader.defaultProps = {
  className: '',
};

export default CompanyDetailsPageHeader;
