/* eslint-disable no-param-reassign */
import { createSelector, createSlice, original } from '@reduxjs/toolkit';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';

/*
 * Initial State
 */
const initialState = {
  addNewUserIsPending: false,
  getCompanyUsersIsPending: false,
  impersonateIsPending: false,

  byId: {},
  listByCompanyId: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Add new user to Company */
    addNewUser: (state) => {
      state.addNewUserIsPending = true;
    },
    addNewUserCompleted: (state) => {
      state.addNewUserIsPending = false;
    },
    /* Get Company Users */
    getCompanyUsers: (state) => {
      state.getCompanyUsersIsPending = true;
    },
    setCompanyUsers: (state, { payload }) => {
      const { companyId, users } = payload;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(users, 'id'),
      );

      const usersList = _map(users, 'id');

      state.listByCompanyId = _mergeWith(
        _cloneDeep(original(state.listByCompanyId)),
        {
          [companyId]: usersList,
        },
      );
    },
    getCompanyUsersCompleted: (state) => {
      state.getCompanyUsersIsPending = false;
    },
    /* Copy Email to Clipboard */
    copyEmailToClipboard: () => {},
    /* Copy Invitation Link to Clipboard */
    copyInvitationLinkToClipboard: () => {},
    /* Impersonate */
    impersonate: (state) => {
      state.impersonateIsPending = true;
    },
    impersonateCompleted: (state) => {
      state.impersonateIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const rawById = (state) => root(state).byId;
const rawListByCompanyId = (state) => root(state).listByCompanyId;

const addNewUserIsPending = (state) => root(state).addNewUserIsPending;
const getCompanyUsersIsPending = (state) => root(state).getCompanyUsersIsPending;
const impersonateIsPending = (state) => root(state).impersonateIsPending;

const getCompanyUsersList = createSelector(
  [rawListByCompanyId, rawById, getProps],
  (listByCompanyId, byId, { companyId }) => {
    const usersList = listByCompanyId[companyId] || [];
    return usersList.map((userId) => byId[userId] || null).filter(Boolean);
  },
);

export const selectors = {
  addNewUserIsPending,
  getCompanyUsersIsPending,
  impersonateIsPending,

  getCompanyUsersList,
};
