import React from 'react';
import { RouterProvider } from 'react-router-dom';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import router from '@palette/routing/router';

const Routing = () => (
  <RouterProvider router={router}>
    <MetaTag isRouter />
  </RouterProvider>
);

export default Routing;
