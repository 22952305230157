import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

import * as MongoCollectionsDetailsModel from '@palette/models/MongoCollectionsDetails';

export const modelName = 'MongoCollectionsStats';

export const propTypes = PropTypes.shape({
  totalCompanyCollections: PropTypes.number,
  totalPaletteCollections: PropTypes.number,
  totalStorage: PropTypes.number,
  companyCollectionsDetails: PropTypes.arrayOf(MongoCollectionsDetailsModel.propTypes),
  paletteCollectionsDetails: PropTypes.arrayOf(MongoCollectionsDetailsModel.propTypes),
});

export const transform = (mongoCollectionsStats) => {
  if (!mongoCollectionsStats) {
    return null;
  }

  const rawTotal = manageFreeShapeObjectAttribute(mongoCollectionsStats, 'total');

  return (
    {
      totalCompanyCollections: manageNumberAttribute(rawTotal, 'clientCollections'),
      totalPaletteCollections: manageNumberAttribute(rawTotal, 'paletteCollections'),
      totalStorage: manageNumberAttribute(rawTotal, 'totalStorage'),
      companyCollectionsDetails: MongoCollectionsDetailsModel.transformList(manageArrayAttribute(mongoCollectionsStats, 'clientCollections')),
      paletteCollectionsDetails: MongoCollectionsDetailsModel.transformList(manageArrayAttribute(mongoCollectionsStats, 'paletteCollections')),
    }
  );
};

export const transformList = (mongoCollectionsStatsList) => mongoCollectionsStatsList.map((mongoCollectionsStats) => transform(mongoCollectionsStats));
