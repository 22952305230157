import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {};

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    return {
      ...problem,
      ...error,
    };
  }

  return manageGlobalError({ status, problem, data });
};
