import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as RoleModel from '@palette/models/Role';

export const modelName = 'MetaUserAccount';

export const propTypes = PropTypes.shape({
  email: PropTypes.string,
  roleId: PropTypes.string,
  role: RoleModel.propTypes,
  pendingInvitation: PropTypes.bool,
  invitationLink: PropTypes.string,
});

export const transform = (metaUserAccount) => {
  if (!metaUserAccount) {
    return null;
  }

  let role = null;
  let roleId = manageStringAttribute(metaUserAccount, 'role', null, true);
  if (roleId === null) {
    role = RoleModel.transform(manageFreeShapeObjectAttribute(metaUserAccount, 'role', null));
    roleId = role?.id || null;
  }

  return (
    {
      email: manageStringAttribute(metaUserAccount, 'email'),
      roleId,
      role,
      pendingInvitation: manageBooleanAttribute(metaUserAccount, 'pendingInvitation'),
      invitationLink: manageStringAttribute(metaUserAccount, 'resetPasswordLink', null),
    }
  );
};

export const transformList = (metaUserAccounts) => metaUserAccounts.map((metaUserAccount) => transform(metaUserAccount));
