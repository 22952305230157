import qs from 'qs';

import { APP_TOP_ID } from '@palette/constants/navigation';

import routePaths, { publicRoutePaths, restrictedRoutePaths } from '@palette/config/routePaths';

let globalRouter = null;

export const setRouter = (router) => {
  globalRouter = router;
};

export const getRouterState = () => (globalRouter?.state);

export const stringifyQS = (qsObject, addQueryPrefix = true) => qs.stringify(qsObject, { addQueryPrefix });
export const getSearch = (location, ignoreQueryPrefix = true) => qs.parse(location.search || '', { ignoreQueryPrefix });

export const computePathname = (path, params) => {
  const generatedPath = Object.keys(params).reduce((finalPath, paramKey) => (
    finalPath.replaceAll(`:${paramKey}`, params[paramKey])
  ), path);

  return `${routePaths.basePath}${generatedPath}`;
};

export const getHistoryObject = ({ path, params = {}, qsObject = {} }) => ({
  pathname: computePathname(path, params),
  search: stringifyQS(qsObject),
});

export const navigateTo = (newLocation) => {
  if (globalRouter === null) {
    window.location.href = `./${newLocation.pathname}${newLocation.search}`;
    window.location.reload();
    return;
  }

  globalRouter.navigate(newLocation);
};

export const redirectTo = ({ path, params = {}, qsObject = {} }) => {
  const historyObject = getHistoryObject({ path, params, qsObject });

  navigateTo(historyObject);
};

export const pathMatchRoute = ({ path }) => {
  if (getRouterState().matches?.length > 0) {
    return getRouterState().matches.some((match) => (match.route?.path === path));
  }

  return false;
};

export const isSamePagePathname = ({ path, params = {}, qsObject = {} }) => {
  const pageHistoryObject = getHistoryObject({ path, params, qsObject });

  let locationPathname = getRouterState().location?.pathname || null;
  if (locationPathname === null) {
    locationPathname = window.location.pathname;
  }

  return locationPathname === pageHistoryObject.pathname;
};

export const isSamePage = ({ path, params = {}, qsObject = {} }) => {
  const pageHistoryObject = getHistoryObject({ path, params, qsObject });

  const routerState = getRouterState();

  let locationPathname = routerState.location?.pathname || null;
  if (locationPathname === null) {
    locationPathname = window.location.hash.substring(1);
  }

  let locationSearch = routerState.location ? routerState.location.search : null;
  if (locationSearch === null) {
    locationSearch = window.location.search;
  }

  return locationPathname === pageHistoryObject.pathname && locationSearch === pageHistoryObject.search;
};

export const isPublicPage = () => (
  Object.values(publicRoutePaths).some((publicRoutePath) => pathMatchRoute({ path: publicRoutePath }))
);

export const isMaintenancePage = () => (
  pathMatchRoute({ path: routePaths.maintenance })
);

export const isRestrictedPage = () => (
  Object.values(restrictedRoutePaths).some((restrictedRoutePath) => pathMatchRoute({ path: restrictedRoutePath }))
);

export const getCleanLocationSearchNewLocation = (location, keysToDelete) => {
  const qsObj = getSearch(location);

  keysToDelete.forEach((keyToDelete) => {
    delete qsObj[keyToDelete];
  });

  return {
    pathname: location.pathname,
    search: stringifyQS(qsObj),
  };
};

export const getAddQSToLocationNewLocation = (location, qsObject) => ({
  pathname: location.pathname,
  search: stringifyQS({
    ...getSearch(location),
    ...qsObject,
  }),
});

export const scrollToPosition = (position, smooth = true) => {
  let scrollIntoViewArg;
  if (smooth) {
    scrollIntoViewArg = {
      behavior: 'smooth',
    };
  }
  document.getElementById(position).scrollIntoView(scrollIntoViewArg);
};

export const scrollToTop = (smooth = true) => {
  scrollToPosition(APP_TOP_ID, smooth);
};
