import { STATUSES } from '@palette/constants/user';

export const getUserStatus = (metaUser) => {
  if (metaUser.leaveDate !== null) return STATUSES.OFFBOARDED;

  if (metaUser.account === null) return STATUSES.NOT_INVITED;

  if (metaUser.account.pendingInvitation) return STATUSES.PENDING;

  return STATUSES.CONNECTED;
};
