import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './EmailPasswordForm.less';

const classNames = bindClassNames.bind(styles);

const EmailPasswordForm = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginIsPending = useSelector(ProfileSelectors.loginIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    name: '',
    password: '',
  };

  const handleFinish = useCallback(({ email, password }) => {
    dispatch(ProfileActions.login({ email, password }));
  }, []);

  const handleLogin = useCallback(() => form.submit(), [form]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="email"
          label={t('emailPasswordForm.form.email.label')}
          required
          rules={[
            { required: true, message: t('emailPasswordForm.form.email.rules.required') },
          ]}
        >
          <Input size="big" type="email" placeholder={t('emailPasswordForm.form.email.placeholder')} disabled={loginIsPending} />
        </FormItem>
        <FormItem
          name="password"
          label={t('emailPasswordForm.form.password.label')}
          required
          rules={[
            { required: true, message: t('emailPasswordForm.form.password.rules.required') },
          ]}
        >
          <Input size="big" type="password" placeholder={t('emailPasswordForm.form.password.placeholder')} disabled={loginIsPending} />
        </FormItem>
        <Button className={styles.submitBtn} onClick={handleLogin} loading={loginIsPending}>
          {t('emailPasswordForm.form.submit')}
        </Button>
      </Form>
    </div>
  );
};

EmailPasswordForm.propTypes = {
  className: PropTypes.string,
};

EmailPasswordForm.defaultProps = {
  className: '',
};

export default EmailPasswordForm;
