import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';

import { getStore } from '@palette/state';
import { getI18n } from '@palette/i18n';

import Routing from '@palette/routing/Routing';

export const AppComponent = (props) => {
  let renderNode = (
    <Routing />
  );

  // Added I18nextProvider
  renderNode = (
    <I18nextProvider i18n={props.i18n}>
      {renderNode}
    </I18nextProvider>
  );

  // Added ReduxProvider
  renderNode = (
    <ReduxProvider store={props.store}>
      {renderNode}
    </ReduxProvider>
  );

  return renderNode;
};

AppComponent.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string,
    resolvedLanguage: PropTypes.string,
  }),
};

export const App = () => (
  <AppComponent store={getStore()} i18n={getI18n()} />
);

export default App;
