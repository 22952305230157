import React from 'react';

import { message as AntDMessage } from 'antd';

import { getI18n } from '@palette/i18n';

import SuccessNotificationIcon from '@palette/components/utils/Icons/SuccessNotificationIcon';
import ErrorNotificationIcon from '@palette/components/utils/Icons/ErrorNotificationIcon';

let sagaMiddlewareInstance = null;

export const setSagaMiddleware = (sagaMiddleware) => {
  sagaMiddlewareInstance = sagaMiddleware;
};

export const getSagaMiddleware = () => (
  sagaMiddlewareInstance
);

export const showErrorNotification = ({ i18nId, message }) => {
  const i18n = getI18n();
  AntDMessage.error(
    {
      icon: (<ErrorNotificationIcon />),
      content: i18n.t(i18nId, { message }),
      className: 'paletteNotification',
    },
    6,
  );
};

export const showSuccessNotification = ({ i18nId, params = {} }) => {
  const i18n = getI18n();
  AntDMessage.success(
    {
      icon: (<SuccessNotificationIcon />),
      content: i18n.t(i18nId, params),
      className: 'paletteNotification',
    },
    3,
  );
};
