import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import PaidSeatsFilled from '@palette/components/utils/Icons/PaidSeatsFilled';
import CompanyInfosMinSeats from '@palette/components/companyDetails/CompanyInfosMinSeats/CompanyInfosMinSeats';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyInfosSeats.less';

const classNames = bindClassNames.bind(styles);

const CompanyInfosSeats = ({ className, company }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tooltip title={t('companiesListItem.usersCount', { count: company.usersCount })}>
        <div className={styles.usersCountWrapper}>
          <TeamFilled className={styles.usersCountIcon} width={20} height={20} />
          <div className={styles.usersCount}>
            {company.usersCount}
          </div>
        </div>
      </Tooltip>
      <CompanyInfosMinSeats className={styles.minSeatsWrapper} company={company} />
      <Tooltip title={t('companiesListItem.seatsCount', { count: company.seatsCount })}>
        <div className={styles.seatsCountWrapper}>
          <PaidSeatsFilled className={styles.seatsCountIcon} width={20} height={20} />
          <div className={styles.seatsCount}>
            {company.seatsCount}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

CompanyInfosSeats.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyInfosSeats.defaultProps = {
  className: '',
};

export default CompanyInfosSeats;
