import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';

import { useAccessToCompanyDetails } from '@palette/hooks/CompanyHooks';

import routePaths from '@palette/config/routePaths';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyLink.less';

const classNames = bindClassNames.bind(styles);

const CompanyLink = ({ className, company }) => {
  const canAccessToCompanyDetails = useAccessToCompanyDetails(company.id);

  if (canAccessToCompanyDetails) {
    return (
      <Link
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        path={routePaths.companyDetails}
        params={{ companyId: company.id }}
      >
        {company.name}
      </Link>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {company.name}
    </div>
  );
};

CompanyLink.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyLink.defaultProps = {
  className: '',
};

export default CompanyLink;
