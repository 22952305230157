import { getBoolEnvValue, getEnvValue } from '@palette/helpers/ConfigHelper';

const app = {
  ENABLE_PASSWORD_AUTH: getBoolEnvValue(process.env.ENABLE_PASSWORD_AUTH),
  GOOGLE_CLIENT_ID: getEnvValue(process.env.GOOGLE_CLIENT_ID),
  PASSWORD_MIN_LENGTH: parseInt(getEnvValue(process.env.PASSWORD_MIN_LENGTH, 8), 10),
  PASSWORD_UPPERCASE_LETTERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_UPPERCASE_LETTERS_QUANTITY, 1), 10),
  PASSWORD_LOWERCASE_LETTERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_LOWERCASE_LETTERS_QUANTITY, 1), 10),
  PASSWORD_NUMBERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_NUMBERS_QUANTITY, 1), 10),
  PASSWORD_SYMBOLS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_SYMBOLS_QUANTITY, 1), 10),
  CLOUDINARY_API: getEnvValue(process.env.CLOUDINARY_API),
  CLOUDINARY_NAME: getEnvValue(process.env.CLOUDINARY_NAME),
  CLOUDINARY_PRESET: getEnvValue(process.env.CLOUDINARY_PRESET),
  APP_PROD_URL: getEnvValue(process.env.APP_PROD_URL, 'https://app.palettehq.com'),
  APP_LOCAL_URL: getEnvValue(process.env.APP_LOCAL_URL, 'http://localhost:3000'),
  ASYNC_JOB_POLLING_SLEEP_DURATION: parseInt(getEnvValue(process.env.ASYNC_JOB_POLLING_SLEEP_DURATION, '1000'), 10),
  ASYNC_JOB_PROGRESS_DELAY: parseInt(getEnvValue(process.env.ASYNC_JOB_PROGRESS_DELAY, '120'), 10),
};

export default app;
