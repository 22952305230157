import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import LockFilled from '@palette/components/utils/Icons/LockFilled';
import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import {
  hasLowercaseLetters,
  hasMinimumLength, hasNumbers, hasSymbols, hasUppercaseLetters,
} from '@palette/helpers/PasswordHelper';

import appConfig from '@palette/config/app';

import styles from './PasswordRules.less';

const classNames = bindClassNames.bind(styles);

const PasswordRules = ({ className, password }) => {
  const { t } = useTranslation();

  const getRuleNode = useCallback((ruleValidationFn, ruleDescription) => {
    let iconNode = null;
    if (password.length > 0) {
      if (ruleValidationFn(password)) {
        iconNode = (
          <CheckFilled className={styles.checkIcon} />
        );
      } else {
        iconNode = (
          <AlertOctagon className={styles.alertIcon} />
        );
      }
    }

    return (
      <div className={styles.ruleWrapper}>
        {iconNode}
        <div className={styles.rule}>
          {ruleDescription}
        </div>
      </div>
    );
  }, [password]);

  const rulesNodes = useMemo(() => (
    <div className={styles.rules}>
      {getRuleNode(hasMinimumLength, t('passwordRules.rule.hasMinLength', { count: appConfig.PASSWORD_MIN_LENGTH }))}
      {getRuleNode(hasUppercaseLetters, t('passwordRules.rule.hasUppercaseLetters', { count: appConfig.PASSWORD_UPPERCASE_LETTERS_QUANTITY }))}
      {getRuleNode(hasLowercaseLetters, t('passwordRules.rule.hasLowercaseLetters', { count: appConfig.PASSWORD_LOWERCASE_LETTERS_QUANTITY }))}
      {getRuleNode(hasNumbers, t('passwordRules.rule.hasNumbers', { count: appConfig.PASSWORD_NUMBERS_QUANTITY }))}
      {getRuleNode(hasSymbols, t('passwordRules.rule.hasSymbols', { count: appConfig.PASSWORD_SYMBOLS_QUANTITY }))}
    </div>
  ), [getRuleNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleWrapper}>
        <LockFilled className={styles.icon} width={16} height={16} />
        <div>
          {t('passwordRules.title')}
        </div>
      </div>
      {rulesNodes}
    </div>
  );
};

PasswordRules.propTypes = {
  className: PropTypes.string,
  password: PropTypes.string.isRequired,
};

PasswordRules.defaultProps = {
  className: '',
};

export default PasswordRules;
