import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import Input from '@palette/components/designSystem/Input/Input';
import Loader from '@palette/components/utils/Loader/Loader';
import CompaniesList from '@palette/components/companies/CompaniesList/CompaniesList';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import CompaniesListFilters from '@palette/components/companies/CompaniesListFilters/CompaniesListFilters';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CompaniesListSortOptions from '@palette/components/companies/CompaniesListSortOptions/CompaniesListSortOptions';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';
import Button from '@palette/components/designSystem/Button/Button';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';
import {
  useCompaniesListSortInitialValue,
  useHasStripeSubscriptionInSearch,
  useHasTrialEndDateInSearch,
  useIsArchivedInSearch,
  useIsLiveInSearch,
  useSearchedCompanyInSearch,
} from '@palette/hooks/CompanyHooks';

import {
  LIMIT_QS_KEY,
  PAGE_QS_KEY,
} from '@palette/constants/navigation';
import {
  COMPANIES_LIST_FILTERS,
  COMPANIES_LIST_FILTERS_BY_KEY,
  COMPANIES_LIST_FILTERS_KEYS,
  SEARCHED_COMPANY_QS_KEY,
} from '@palette/constants/company';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CompaniesListWrapper.less';

const classNames = bindClassNames.bind(styles);

const CompaniesListWrapper = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getCompaniesIsPending = useSelector(CompaniesSelectors.getCompaniesIsPending);
  const companiesList = useSelector(CompaniesSelectors.getCompaniesList);
  const companiesListPagination = useSelector(CompaniesSelectors.getPagination);

  const [getCompaniesLimit] = useLimitInSearch();
  const [getCompaniesPage] = usePageInSearch();
  const [searchedCompanyFromSearch] = useSearchedCompanyInSearch();
  const [,, isLiveRawValue] = useIsLiveInSearch();
  const [,, hasStripeSubscriptionRawValue] = useHasStripeSubscriptionInSearch();
  const [,, hasTrialEndDateRawValue] = useHasTrialEndDateInSearch();
  const [,, isArchivedRawValue] = useIsArchivedInSearch();

  const sortValue = useCompaniesListSortInitialValue();

  const [searchedCompany, setSearchedCompany] = useState(searchedCompanyFromSearch);
  useEffect(() => {
    setSearchedCompany(searchedCompanyFromSearch);
  }, [searchedCompanyFromSearch]);

  const payloadCallData = useMemo(() => {
    let finalIsLiveValue = isLiveRawValue;
    if (
      isLiveRawValue === null
      && hasStripeSubscriptionRawValue === null
      && hasTrialEndDateRawValue === null
      && isArchivedRawValue === null
    ) {
      finalIsLiveValue = 'true';
    }

    return {
      searchedCompanyName: searchedCompanyFromSearch,
      isLive: finalIsLiveValue,
      hasStripeSubscription: hasStripeSubscriptionRawValue,
      hasTrialEndDate: hasTrialEndDateRawValue,
      isArchived: isArchivedRawValue,
      page: getCompaniesPage,
      limit: getCompaniesLimit,
      sort: sortValue,
    };
  }, [
    getCompaniesPage,
    getCompaniesLimit,
    searchedCompanyFromSearch,
    isLiveRawValue,
    hasStripeSubscriptionRawValue,
    hasTrialEndDateRawValue,
    isArchivedRawValue,
    sortValue,
  ]);

  useEffect(() => {
    dispatch(CompaniesActions.setLastUsedFilters({
      isLive: isLiveRawValue,
      hasStripeSubscription: hasStripeSubscriptionRawValue,
      hasTrialEndDate: hasTrialEndDateRawValue,
      isArchived: isArchivedRawValue,
      searchedCompany: searchedCompanyFromSearch,
      sort: sortValue,
    }));

    dispatch(CompaniesActions.getCompanies(payloadCallData));
  }, [
    payloadCallData,
  ]);

  const performCompanySearch = useCallback(
    _debounce((newSearchedCompany) => {
      const QSToAdd = {};
      const keysToDelete = [PAGE_QS_KEY];

      if (newSearchedCompany !== '') {
        QSToAdd[SEARCHED_COMPANY_QS_KEY] = newSearchedCompany;
      } else {
        keysToDelete.push(SEARCHED_COMPANY_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }, 700),
    [],
  );

  const handleSearchCompanyChange = useCallback((newSearchedCompany) => {
    setSearchedCompany(newSearchedCompany);

    if (newSearchedCompany.length >= 1 || (newSearchedCompany.length === 0 && searchedCompany.length > 0)) {
      performCompanySearch(newSearchedCompany);
    }
  }, [searchedCompany, performCompanySearch]);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const filtersNode = useMemo(() => (
    <div className={styles.filtersWrapper}>
      <Input
        className={styles.companySearch}
        type="search"
        defaultValue={searchedCompanyFromSearch}
        placeholder={t('companiesListWrapper.filters.companySearch.placeholder')}
        onChange={handleSearchCompanyChange}
        value={searchedCompany}
      />
      <div className={styles.filtersSortOptionsWrapper}>
        <CompaniesListFilters />
        <CompaniesListSortOptions className={styles.sortOptions} />
      </div>
    </div>
  ), [searchedCompany, handleSearchCompanyChange, searchedCompanyFromSearch]);

  const handleResetFilters = useCallback(() => {
    const keysToDelete = [LIMIT_QS_KEY, PAGE_QS_KEY, SEARCHED_COMPANY_QS_KEY];

    COMPANIES_LIST_FILTERS.forEach((companiesListFilter) => {
      if (!keysToDelete.includes(companiesListFilter.qsKey)) {
        keysToDelete.push(companiesListFilter.qsKey);
      }
    });

    dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
  }, []);

  const resetFiltersNode = useMemo(() => {
    if (
      isLiveRawValue === null
      && hasStripeSubscriptionRawValue === null
      && hasTrialEndDateRawValue === null
      && isArchivedRawValue === null
      && searchedCompanyFromSearch === ''
    ) {
      return null;
    }

    return (
      <Button
        className={styles.resetFiltersButton}
        type="link"
        icon={<BarsFilled width={24} height={24} />}
        onClick={handleResetFilters}
      >
        {t('companiesListWrapper.filters.reset.label')}
      </Button>
    );
  }, [
    isLiveRawValue,
    hasStripeSubscriptionRawValue,
    hasTrialEndDateRawValue,
    isArchivedRawValue,
    searchedCompanyFromSearch,
    handleResetFilters,
  ]);

  const listCallFiltersNode = useMemo(() => {
    const tags = [];
    if (payloadCallData.isLive !== null) {
      tags.push((
        <Tag key="live" className={styles.listCallFilter} type="info">
          {
            payloadCallData.isLive === 'true'
              ? t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.IS_LIVE].filterNameI18NId)
              : t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.IS_NOT_LIVE].filterNameI18NId)
          }
        </Tag>
      ));
    }

    if (payloadCallData.hasStripeSubscription !== null) {
      tags.push((
        <Tag key="hasStripeSubscription" className={styles.listCallFilter} type="info">
          {
            payloadCallData.hasStripeSubscription === 'true'
              ? t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.HAS_STRIPE_SUBSCRIPTION].filterNameI18NId)
              : t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_STRIPE_SUBSCRIPTION].filterNameI18NId)
          }
        </Tag>
      ));
    }

    if (payloadCallData.hasTrialEndDate !== null) {
      tags.push((
        <Tag key="hasTrialEndDate" className={styles.listCallFilter} type="info">
          {
            payloadCallData.hasTrialEndDate === 'true'
              ? t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.HAS_TRIAL_END_DATE].filterNameI18NId)
              : t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.HAS_NOT_TRIAL_END_DATE].filterNameI18NId)
          }
        </Tag>
      ));
    }

    if (payloadCallData.isArchived !== null) {
      tags.push((
        <Tag key="isArchived" className={styles.listCallFilter} type="info">
          {
            payloadCallData.isArchived === 'true'
              ? t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.IS_ARCHIVED].filterNameI18NId)
              : t(COMPANIES_LIST_FILTERS_BY_KEY[COMPANIES_LIST_FILTERS_KEYS.IS_NOT_ARCHIVED].filterNameI18NId)
          }
        </Tag>
      ));
    }

    if (payloadCallData.searchedCompanyName !== '') {
      tags.push((
        <Tag key="searchedCompanyName" className={styles.listCallFilter} type="info">
          {payloadCallData.searchedCompanyName}
        </Tag>
      ));
    }

    return (
      <div className={styles.listCallFilters}>
        {tags}
        {resetFiltersNode}
      </div>
    );
  }, [
    payloadCallData,
    resetFiltersNode,
  ]);

  const listNode = useMemo(() => {
    let paginationNode = null;
    if (companiesList !== null && companiesList.length > 0) {
      paginationNode = (
        <Pagination
          className={styles.pagination}
          pagination={companiesListPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      );
    }

    return (
      <Loader className={styles.list} spinning={getCompaniesIsPending}>
        <CompaniesList className={styles.list} companies={companiesList} />
        {paginationNode}
      </Loader>
    );
  }, [
    getCompaniesIsPending,
    companiesList,
    companiesListPagination,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {filtersNode}
      {listCallFiltersNode}
      {listNode}
    </div>
  );
};

CompaniesListWrapper.propTypes = {
  className: PropTypes.string,
};

CompaniesListWrapper.defaultProps = {
  className: '',
};

export default CompaniesListWrapper;
