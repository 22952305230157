import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CompanyDetailsSettingsBlock from '@palette/components/companyDetails/CompanyDetailsSettingsBlock/CompanyDetailsSettingsBlock';
import CompanyDetailsSettingsGlobalLiveStatus from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalLiveStatus/CompanyDetailsSettingsGlobalLiveStatus';
import CompanyDetailsSettingsGlobalPaletteAdmins from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalPaletteAdmins/CompanyDetailsSettingsGlobalPaletteAdmins';
import CompanyDetailsSettingsGlobalSupportOwner from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalSupportOwner/CompanyDetailsSettingsGlobalSupportOwner';
import CompanyDetailsSettingsGlobalDocumentationUrl from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalDocumentationUrl/CompanyDetailsSettingsGlobalDocumentationUrl';
import CompanyDetailsSettingsGlobalSyncInterval from '@palette/components/companyDetails/CompanyDetailsSettingsGlobalSyncInterval/CompanyDetailsSettingsGlobalSyncInterval';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsGlobal.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsGlobal = ({ className, company }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => (
    <>
      <CompanyDetailsSettingsGlobalLiveStatus className={styles.item} company={company} />
      <CompanyDetailsSettingsGlobalPaletteAdmins className={styles.item} company={company} />
      <CompanyDetailsSettingsGlobalSupportOwner className={styles.item} company={company} />
      <CompanyDetailsSettingsGlobalDocumentationUrl className={styles.item} company={company} />
      <CompanyDetailsSettingsGlobalSyncInterval className={styles.item} company={company} />
    </>
  ), [
    company,
  ]);

  return (
    <CompanyDetailsSettingsBlock
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyDetailsSettingsGlobal.title')}
      content={contentNode}
    />
  );
};

CompanyDetailsSettingsGlobal.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsGlobal.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsGlobal;
