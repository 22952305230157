import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import UpdateSupportOwnerModal from '@palette/components/companyDetails/UpdateSupportOwnerModal/UpdateSupportOwnerModal';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import { useCompanyInParams } from '@palette/hooks/CompanyHooks';

import styles from './UpdateSupportOwner.less';

const classNames = bindClassNames.bind(styles);

const UpdateSupportOwner = ({ className }) => {
  const { canAccessToCompanyDetails } = useCompanyInParams();

  const [updateSupportOwnerModalIsVisible, showUpdateSupportOwnerModal] = useState(false);

  if (!canAccessToCompanyDetails) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        icon={(<PenFilled />)}
        onClick={() => showUpdateSupportOwnerModal(true)}
      />
      {
        updateSupportOwnerModalIsVisible && (
          <UpdateSupportOwnerModal visible onClose={() => showUpdateSupportOwnerModal(false)} />
        )
      }
    </div>
  );
};

UpdateSupportOwner.propTypes = {
  className: PropTypes.string,
};

UpdateSupportOwner.defaultProps = {
  className: '',
};

export default UpdateSupportOwner;
