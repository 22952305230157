import { put } from 'redux-saga/effects';

import apiConfig from '@palette/config/api';

import { getSagaMiddleware } from '@palette/helpers/SagasHelper';
import { isSamePagePathname, redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

export const parseProblemData = (data) => {
  let errorId = 'about:blank';
  let message = '';
  let additionalData = {};

  if (data) {
    const {
      type,
      title,
      ...otherAttributes
    } = data;

    if (type && type !== '') {
      errorId = type.substring(apiConfig.PROBLEM_BASE_URI.length);
    }

    if (title && title !== '') {
      message = title;
    }

    additionalData = otherAttributes;
  }

  return {
    errorId,
    message,
    additionalData,
  };
};

function* forceLogout() {
  yield put(ExceptionsActions.forceLogout());
}

const forceRedirectToMaintenancePage = () => {
  if (!isSamePagePathname({ path: routePaths.maintenance })) {
    redirectTo({ path: routePaths.maintenance });
  }
};

export const manageGlobalError = ({ status, problem, data }) => {
  let i18nId = 'global.error.unknown';
  let message = data?.error?.message || '';

  switch (status) {
    case 400: {
      i18nId = 'global.error.bad.request';
      if (problem?.errorId === 'constraint.violation') {
        i18nId = 'global.error.constraint.violation';
        const violations = problem?.additionalData?.violations || [];
        message = violations.map(({ param, msg }) => (`Violation on "${param}": ${msg}.`)).join(' - ');
      }
      break;
    }
    case 401: {
      i18nId = 'global.error.unauthorized';
      if (problem?.errorId === 'invalid.session') {
        i18nId = 'global.error.invalid.session';
        message = '';
        getSagaMiddleware().run(forceLogout);
      }
      break;
    }
    case 403: {
      i18nId = 'global.error.unauthorized';
      break;
    }
    case 404: {
      i18nId = 'global.error.not.found';
      break;
    }
    case 405: {
      i18nId = 'global.error.method.not.allowed';
      break;
    }
    case 500: {
      i18nId = 'global.error.server.error';
      break;
    }
    case 503: {
      i18nId = 'global.error.maintenance.enabled';
      message = '';
      forceRedirectToMaintenancePage();
      break;
    }
    default:
  }

  return {
    ...problem,
    i18nId,
    message,
  };
};
