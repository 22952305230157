import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import CompanyDetailsSettingsBlock from '@palette/components/companyDetails/CompanyDetailsSettingsBlock/CompanyDetailsSettingsBlock';
import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CompanyInfosMinSeats from '@palette/components/companyDetails/CompanyInfosMinSeats/CompanyInfosMinSeats';
import Image from '@palette/components/designSystem/Image/Image';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './CompanyDetailsSettingsBilling.less';

const classNames = bindClassNames.bind(styles);

const CompanyDetailsSettingsBilling = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canUpdateBilling = useProfileRights([RIGHTS.ADMIN_COMPANIES_BILLING_UPDATE]);

  const updateBillingSettingsIsPending = useSelector(CompaniesSelectors.updateBillingSettingsIsPending);

  const [form] = AntDForm.useForm();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const onUpdateBillingSettingsSuccess = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form]);

  const updateBillingSettings = useCallback(() => {
    if (!canUpdateBilling) return;

    const { stripeSubscriptionId, minSeats } = form.getFieldsValue(true);

    const finalStripeSubscriptionId = stripeSubscriptionId === '' ? null : stripeSubscriptionId;
    const finalMinSeats = minSeats === 0 ? null : minSeats;

    dispatch(CompaniesActions.updateBillingSettings({ companyId: company.id, stripeSubscriptionId: finalStripeSubscriptionId, minSeats: finalMinSeats, onSuccessCB: onUpdateBillingSettingsSuccess }));
  }, [canUpdateBilling, company, form, onUpdateBillingSettingsSuccess]);

  const billingDataNode = useMemo(() => {
    let subscriptionIdNode = (
      <div className={styles.subscriptionIdWrapper}>
        <Image
          className={styles.stripeIcon}
          src="/img/StripeLogo_grey.png"
        />
        <div className={styles.subscriptionIdLabel}>
          {t('companyDetailsSettingsBilling.noSubscriptionIdDefined')}
        </div>
      </div>
    );
    if (company.stripeSubscriptionId !== null) {
      subscriptionIdNode = (
        <div className={styles.subscriptionIdWrapper}>
          <Image
            className={styles.stripeIcon}
            src="/img/StripeLogo.png"
          />
          <div className={styles.subscriptionIdLabel}>
            {company.stripeSubscriptionId}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.billingDataWrapper}>
        {subscriptionIdNode}
        <CompanyInfosMinSeats className={styles.minSeats} company={company} />
      </div>
    );
  }, [
    company,
  ]);

  const displayModeNode = useMemo(() => {
    if (!canUpdateBilling) {
      return (
        <div className={styles.displayModeWrapper}>
          {billingDataNode}
        </div>
      );
    }

    return (
      <div className={styles.displayModeWrapper}>
        {billingDataNode}
        <Button
          className={styles.enableEditModeButton}
          type="link"
          flattenLink
          icon={<PenFilled width={18} height={18} />}
          onClick={() => setEditModeEnabled(true)}
          disabled={updateBillingSettingsIsPending}
        >
          {t('companyDetailsSettingsBilling.editBillingSettings')}
        </Button>
      </div>
    );
  }, [
    canUpdateBilling,
    billingDataNode,
    setEditModeEnabled,
    updateBillingSettingsIsPending,
  ]);

  const initialValues = useMemo(() => ({
    stripeSubscriptionId: company.stripeSubscriptionId,
    minSeats: company.minSeats,
  }), [company]);

  useEffect(() => {
    if (editModeEnabled) {
      form.resetFields();
    }
  }, [initialValues, editModeEnabled]);

  const disableEditMode = useCallback(() => {
    form.resetFields();
    setEditModeEnabled(false);
  }, [form, setEditModeEnabled]);

  const editModeNode = useMemo(() => (
    <div className={styles.editModeWrapper}>
      <Form initialValues={initialValues} form={form}>
        <FormItem
          name="stripeSubscriptionId"
          label={t('companyDetailsSettingsBilling.form.stripeSubscriptionId.label')}
        >
          <Input placeholder={t('companyDetailsSettingsBilling.form.stripeSubscriptionId.placeholder')} disabled={updateBillingSettingsIsPending} />
        </FormItem>
        <FormItem
          name="minSeats"
          label={t('companyDetailsSettingsBilling.form.minSeats.label')}
        >
          <Input
            type="number"
            placeholder={t('companyDetailsSettingsBilling.form.minSeats.placeholder')}
            disabled={updateBillingSettingsIsPending}
            min={0}
          />
        </FormItem>
        <div className={styles.actions}>
          <Button className={styles.updateBillingSettingsBtn} onClick={updateBillingSettings} disabled={updateBillingSettingsIsPending}>
            {t('common.global.edit')}
          </Button>
          <Button
            type="linkSecondary"
            onClick={disableEditMode}
            disabled={updateBillingSettingsIsPending}
          >
            {t('common.global.cancel')}
          </Button>
        </div>
      </Form>
    </div>
  ), [
    form,
    initialValues,
    updateBillingSettings,
    updateBillingSettingsIsPending,
    disableEditMode,
  ]);

  const contentNode = useMemo(() => {
    if (editModeEnabled) {
      return editModeNode;
    }

    return displayModeNode;
  }, [
    editModeEnabled,
    displayModeNode,
    editModeNode,
  ]);

  return (
    <CompanyDetailsSettingsBlock
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyDetailsSettingsBilling.title')}
      content={contentNode}
    />
  );
};

CompanyDetailsSettingsBilling.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

CompanyDetailsSettingsBilling.defaultProps = {
  className: '',
};

export default CompanyDetailsSettingsBilling;
