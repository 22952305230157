/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
  currentLocation: '',
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Update current location */
    updateCurrentLocation: (state, { payload }) => {
      const { location } = payload;
      state.currentLocation = location;
    },
    /* Add QS to location */
    addQSToLocation: () => {
      /* Nothing to do */
    },
    /* Clean location search */
    cleanLocationSearch: () => {
      /* Nothing to do */
    },
    /* Update and clean QS in location */
    updateAndCleanQSInLocation: () => {
      /* Nothing to do */
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getCurrentLocation = (state) => root(state).currentLocation;

export const selectors = {
  getCurrentLocation,
};
