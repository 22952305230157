import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';

import { useProfileRights } from '@palette/hooks/ProfileHooks';

import { RIGHTS } from '@palette/constants/role';

import { actions as CompaniesActions, selectors as CompaniesSelectors } from '@palette/state/Companies';

import * as CompanyModel from '@palette/models/Company';

import styles from './RecomputeTechnicalStats.less';

const classNames = bindClassNames.bind(styles);

const RecomputeTechnicalStats = ({ className, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canRecomputeTechnicalStats = useProfileRights([RIGHTS.ADMIN_COMPANIES_TECH_DETAILS_VIEW]);

  const recomputeTechnicalStatsIsPending = useSelector(CompaniesSelectors.recomputeTechnicalStatsIsPending);

  const handleRecomputeTechnicalStats = useCallback(() => {
    dispatch(CompaniesActions.recomputeTechnicalStats({ companyId: company.id }));
  }, [company]);

  if (!canRecomputeTechnicalStats) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="danger"
        onClick={handleRecomputeTechnicalStats}
        disabled={recomputeTechnicalStatsIsPending}
        loading={recomputeTechnicalStatsIsPending}
      >
        {t('recomputeTechnicalStats.label')}
      </Button>
    </div>
  );
};

RecomputeTechnicalStats.propTypes = {
  className: PropTypes.string,
  company: CompanyModel.propTypes.isRequired,
};

RecomputeTechnicalStats.defaultProps = {
  className: '',
};

export default RecomputeTechnicalStats;
