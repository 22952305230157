import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UsersListItem from '@palette/components/companyUsers/UsersListItem/UsersListItem';

import { KNOWN_ROLES } from '@palette/constants/user';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './CompanyUsersList.less';

const classNames = bindClassNames.bind(styles);

const CompanyUsersList = ({ className, users }) => {
  const { t } = useTranslation();

  const [adminUsers, otherUsers] = useMemo(() => {
    const admins = [];
    const others = [];

    users.forEach((user) => {
      if (user.account !== null && user.account.role?.name === KNOWN_ROLES.ADMIN) {
        admins.push(user);
      } else {
        others.push(user);
      }
    });

    return [admins, others];
  }, [users]);

  const contentNode = useMemo(() => {
    if (users.length === 0) {
      return (
        <DefaultEmptyState description={t('usersList.empty.description')} />
      );
    }

    let adminTitleNode = null;
    let otherTitleNode = null;

    if (adminUsers.length > 0 && otherUsers.length > 0) {
      adminTitleNode = (
        <div className={styles.title}>
          {t('companyUsersList.admins')}
        </div>
      );

      otherTitleNode = (
        <div className={styles.title}>
          {t('companyUsersList.others')}
        </div>
      );
    }

    const adminUsersNodes = adminUsers.map((user) => (
      <UsersListItem key={user.id} className={styles.user} user={user} />
    ));

    const otherUsersNodes = otherUsers.map((user) => (
      <UsersListItem key={user.id} className={styles.user} user={user} />
    ));

    return (
      <>
        {adminTitleNode}
        {adminUsersNodes}
        {otherTitleNode}
        {otherUsersNodes}
      </>
    );
  }, [
    users,
    adminUsers,
    otherUsers,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

CompanyUsersList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(MetaUserModel.propTypes).isRequired,
};

CompanyUsersList.defaultProps = {
  className: '',
};

export default CompanyUsersList;
