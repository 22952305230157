export const RIGHTS = {
  ADMIN_ALL: 'admin.*',
  ADMIN_API_LOGS_VIEW: 'admin.api.logs.view',
  ADMIN_COMPANIES_CREATE: 'admin.companies.create',
  ADMIN_COMPANIES_FEATURES_UPDATE: 'admin.companies.update.features',
  ADMIN_COMPANIES_CONNECTORS_UPDATE: 'admin.companies.update.connectors',
  ADMIN_COMPANIES_TRIAL_UPDATE: 'admin.companies.update.trial',
  ADMIN_COMPANIES_BILLING_UPDATE: 'admin.companies.update.billing',
  ADMIN_COMPANIES_PAYROLL_PROVIDERS_UPDATE: 'admin.companies.update.payroll.providers',
  ADMIN_COMPANIES_TECH_DETAILS_VIEW: 'admin.companies.tech.details.view',
  ADMIN_LOCAL_CONNECT_AS: 'admin.local.connect.as',
};
